<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="text-center">
                <h3 class="mb-0 text-capitalize">Our
                    <span class="font-weight-bold text-primary">Services</span>
                </h3>
                <div class="main-title-border mx-auto"></div>
                <p class="text-muted sec_subtitle mx-auto mt-2">
                    We are a network of qualified and experienced radiologists licensed to practise in various settings.
                </p>
            </div>
        </div>
    </div>

    <div class="row mt-4 pt-4 vertical-content">
        <div class="col-lg-6">
            <div class="mt-3">
                <div>
                    <h2 class="features-heading">What we do?</h2>
                    <div class="main-title-border"></div>
                </div>
                <div class="features">
                    <div class="features-icon features-left">
                        <i class="bi bi-check2"></i>
                    </div>
                    <div class="features-desc">
                        <h3 class="features-title font-weight-bold">Routine Radiology Reporting</h3>
                        <p class="features-sub-title pt-2 text-muted">
                            We offer daily full time coverage
                            providing
                            <b class="text-primary">X-ray</b>,
                            <b class="text-primary">Mammography</b>,
                            <b class="text-primary">Fluoroscopy</b>,
                            <b class="text-primary">CT</b> and
                            <b class="text-primary">MRI</b> reports
                            with a fast turn-around time. We also offer consultation services for
                            ultra-sound scans.
                        </p>
                    </div>
                </div>
                <div class="features">
                    <div class="features-icon features-left">
                        <i class="bi bi-check2"></i>
                    </div>
                    <div class="features-desc">
                        <h3 class="features-title font-weight-bold">After Work Hours Radiology Reporting</h3>
                        <p class="features-sub-title pt-2 text-muted">
                            We are available
                            <b class="text-primary">24 hours</b>
                            a day to back up your resident radiologist during off hours.
                        </p>
                    </div>
                </div>
                <div class="features">
                    <div class="features-icon features-left">
                        <i class="bi bi-check2"></i>
                    </div>
                    <div class="features-desc">
                        <h3 class="features-title font-weight-bold">Weekend Radiology Reporting</h3>
                        <p class="features-sub-title pt-2 text-muted">
                            We offer off-site full time
                            radiology reporting for facilities without weekend radiology
                            coverage.
                        </p>
                    </div>
                </div>
                <div class="features">
                    <div class="features-icon features-left">
                        <i class="bi bi-check2"></i>
                    </div>
                    <div class="features-desc">
                        <h3 class="features-title font-weight-bold">Overflow And Backlog Reporting</h3>
                        <p class="features-sub-title pt-2 text-muted">
                            We support your resident
                            radiology team on those busy days/seasons of the year and
                            improve your turn-around time. We also provide coverage.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 how-we-do">
            <div class="my-3" style="margin-left: 2rem; margin-right: 2rem;">
                <div class="text-center">
                    <h2 class="text-white">How we do it.</h2>
                    <div class="main-title-border mx-auto"></div>
                </div>

                <div class="features">
                    <div class="features-image features-left">
                        <img src="assets/images/website/bg/network.png" alt="">
                    </div>
                    <div class="features-how">
                        <h3 class="features-title text-primary font-weight-bold">Network of Radiologists</h3>
                        <p class="features-sub-title pt-2 text-white">
                            We have a network of qualified and experienced
                            radiologists licensed to practice in various settings.
                        </p>
                    </div>
                </div>
                <div class="features">
                    <div class="features-image features-left">
                        <img src="assets/images/website/bg/online.png" alt="">
                    </div>
                    <div class="features-how">
                        <h3 class="features-title text-primary font-weight-bold">Online Platform</h3>
                        <p class="features-sub-title pt-2 text-white">
                            Simply partner with us, upload your medical images
                            onto our platform and you will have a report in an
                            instant.
                        </p>
                    </div>
                </div>
                <div class="features">
                    <div class="features-image features-left">
                        <img src="assets/images/website/bg/accessible.png" alt="">
                    </div>
                    <div class="features-how">
                        <h3 class="features-title text-primary font-weight-bold">Accessible Anywhere</h3>
                        <p class="features-sub-title pt-2 text-white">
                            Advanced technology makes us accessible to you
                            and your patient.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>