import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';

@Injectable({
  providedIn: 'root'
})
export class BillableReportService {

  constructor(
    public apiConnect: ApiConnectionService,
    public alertService: AlertService
  ) { }

  getMedicalCenters(onGet: (res: any, status: boolean) => void): void {
    this.apiConnect.callAPI('GET', 'MedicalCenter', undefined, undefined, undefined)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
        },
        (err: any) => {
          onGet(err, true);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message
          });
        }
      )
  }

  getBillableReport(data: any, onGet: (res: any, status: boolean) => void): void {
    this.apiConnect.callAPI('GET', 'BillableReport', undefined, data, undefined)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
        },
        (err: any) => {
          onGet(err, true);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message
          });
        }
      )
  }

}
