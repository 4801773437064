<!-- image slider -->
<section class="bg-home-slider" id="home" data-aos="fade-down" data-aos-duration="1500">
    <div class="container-fluid">
        <div class="row">
            <app-image-slider class="w-100"></app-image-slider>
        </div>
    </div>
</section>
<!-- image slider -->

<!-- services -->
<section class="section" id="services" data-aos="fade-up" data-aos-duration="1500">
    <app-our-services></app-our-services>
</section>
<!-- services -->

<!-- about us -->
<section class="section bg-light" id="about" data-aos="fade-up" data-aos-duration="1500">
    <app-about-us></app-about-us>
</section>
<!-- about us -->

<!-- contact us -->
<section class="section" id="contact" data-aos="fade-up" data-aos-duration="1500">
    <app-contact-us></app-contact-us>
</section>
<!-- contact us -->