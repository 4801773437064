import { Injectable } from '@angular/core';
import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';
import { NotificationMessage } from './notification.interface';

export interface NotificationState
  extends EntityState<NotificationMessage, number> {
  updating: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notification-store' })
export class NotificationStore extends EntityStore<NotificationState> {
  constructor() {
    super({ updating: false });
  }
}
