import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { BaseFormComponent } from '../form/base-form/base-form.component';
import { applyMixins } from '../mixin/mixin';
import { FormControl } from '@angular/forms';
const readXlsxFile = require('read-excel-file');

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-import-xlsx',
  templateUrl: './import-xlsx.component.html',
  styleUrls: ['./import-xlsx.component.scss']
})
export class ImportXlsxComponent extends BaseClass implements OnInit, DoCheck {

  @Input() fieldId!: string | null;
  @Input() control!: FormControl;
  @Input() columns: Array<string> | null = null;

  data!: Array<any>;
  fileName: string | null = null;
  file: any;

  constructor() {
    super();
  }

  ngOnInit(): void { }

  onFileSelect($event: any): void {
    this.file = $event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      if (this.checkFileFormat(this.fileName)) {
        readXlsxFile(this.file).then((rows: any) => {
          this.control.setValue(this.formatXlsxToJson(rows));
        });
      } else {
        this.control.markAsTouched();
        this.control.setErrors({ 'incorrect-file-xlsx': true });
      }
    }
  }

  checkFileFormat(type: string | null): boolean {
    if (type) {
      return type.includes('xlsx');
    } else {
      return false;
    }
  }

  formatXlsxToJson(sheet: Array<any>): Array<any> {
    const formatedXlsx: Array<any> = [];
    let Headers: Array<any> = [];

    sheet.forEach((row: Array<any>, index) => {
      if (index !== 0) {
        const dataRow: any = new Object();
        Headers.forEach((data, i) => {
          if (i === 5 || i === 9) {
            dataRow[data] = new Date(row[i]).getTime();
          } else {
            dataRow[data] = row[i];
          }
        });
        formatedXlsx.push(dataRow);
      } else {
        if (this.columns) {
          Headers = this.columns;
        } else {
          Headers = row.map(name => name.toString().toLowerCase());
        }
      }
    });
    return formatedXlsx;
  }

}
