import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-placeholder',
  templateUrl: './error-placeholder.component.html',
  styleUrls: ['./error-placeholder.component.scss']
})
export class ErrorPlaceholderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
