import { Component, OnInit } from '@angular/core';
import { SummaryCardsService } from './summary-cards.service';

@Component({
  selector: 'app-summary-cards',
  templateUrl: './summary-cards.component.html',
  styleUrls: ['./summary-cards.component.scss'],
})
export class SummaryCardsComponent implements OnInit {

  cardSummary?: any;

  constructor(
    private summaryService: SummaryCardsService
  ) { }

  ngOnInit(): void {
    this.getSummaryCards();
  }

  getSummaryCards(): void {
    this.summaryService.getSummaryCards((res, status) => {
      if (status) {
        this.cardSummary = res;
      }
    });
  }
}
