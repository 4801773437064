import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { StudiesService } from 'src/app/pages/doc/studies/studies.service';
import { environment } from 'src/environments/environment.prod';
import {Title} from "@angular/platform-browser";
import * as jspdf from 'jspdf';
import { FormControl } from '@angular/forms';

class BaseClass {}
interface BaseClass extends ModalComponent {}
applyMixins(BaseClass, [ModalComponent]);

@Component({
  selector: 'app-medical-study-report',
  templateUrl: './study-report.component.html',
  styleUrls: ['./study-report.component.scss'],
})
export class StudyReportComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('pdfReport') pdfPrintable!: ElementRef;

  report: any = null;
  studyId!: number;
  studyDetails: any;
  viewableAttachment: any | null;
  assetsRoot = environment.imageUrl;
  studyReportForm!: FormControl;

  downloading = false;
  getting = false;
  printFunc: any;

  constructor(
    public route: ActivatedRoute,
    public modalService: BsModalService,
    public reportService: StudiesService,
    private titleService:Title
  ) {
    super();

    this.studyReportForm = new FormControl();
  }

  ngOnInit(): void {
    this.route.queryParams.forEach((params: any) => {
      this.studyId = +params.studyId;
      this.getReport(this.studyId);
    });
  }

  getReport(id: any): void {
    this.getting = true;
    this.reportService.getStudyReport(id, (res, status) => {
      if (status) {
        this.getting = false;
        if (res !== null) {
          this.studyDetails = res?.study;
          this.report = res?.report;
          this.showReport(this.report?.description);
          this.studyReportForm.setValue(this.report?.description);
        }
      }
    });
  }

  onRenderEdit(print: any): void {
    this.titleService.setTitle(`${this.studyDetails.patientName}-${this.studyDetails.studyDate}`)
    this.printFunc = print;
  }

  ngOnDestroy(): void {
    this.titleService.setTitle('Access Teleradiology')
  }

  showReport(report: string): void {
    setTimeout(() => {
      const renderTemlplate: HTMLElement | null = document.getElementById(
        'render-signed-report'
      );
      if (renderTemlplate) {
        renderTemlplate.innerHTML = report;
      }
    });
  }

  exportPDF(): void {
    // create potrait A4 jspdf from html
    this.downloading = true;
    let doc = new jspdf.jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });
    doc.html(this.pdfPrintable.nativeElement, {
      callback: (doc) => {
        this.downloading = false;
        doc.save(
          `${this.studyDetails.patientName}-${this.studyDetails.studyDate}.pdf`
        );
      },
      x: 15,
      y: 15,
      width: 170,
      windowWidth: 650,
      margin: [0, 10, 10, 5]
    });
  }
}
