import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IConnection } from './iconnection.interface';
import { ConnectionService } from './connection.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
})
export class WebSocketModule {
  static forRoot(config: IConnection): ModuleWithProviders<WebSocketModule> {
    return {
      ngModule: WebSocketModule,
      providers: [
        { provide: 'ConnectionConfig', useValue: config },
        ConnectionService,
      ],
    };
  }
}
