export const settingsMenu = [
    {
        text: 'Scan Types',
        icon: 'bi bi-phone-landscape',
        link: '/app/settings/scan-types',
        portal: 'DOCTOR',
    },
    {
        text: 'Users',
        icon: 'bi bi-tags',
        link: '/app/settings/user-permission',
        portal: 'OPEN',
        role: 'Users|View'
    },
    {
        text: 'Client Settings',
        icon: 'bi bi-sliders',
        link: '/app/settings/client-settings',
        portal: 'MEDICAL_CENTER'
    }
];
