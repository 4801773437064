import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LABEL_LIST } from '../../labels/form-labels';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss']
})
export class BaseFormComponent implements OnInit, DoCheck {
  label!: string;
  fieldId!: string | null;
  control!: FormControl;
  validationErrors!: any;

  constructor() { }

  ngOnInit(): void {
    if (this.fieldId) {
      this.label = LABEL_LIST[this.fieldId] ? LABEL_LIST[this.fieldId] : '';
    } else {
      this.label = '';
    }
  }

  ngDoCheck(): void {
    if (this.control) {
      this.validationErrors = this.control.touched && this.control.invalid ? this.control.errors : null;
    }
  }
}
