<div class="d-flex justify-content-between align-items-center">
  <!-- search -->
  <form action="" class="search-bar">
    <div class="position-relative">
      <input name="search" type="text" class="form-control form form-control-light" [(ngModel)]="searchFilter"
        placeholder="Search ..." />
      <span class="bi bi-search"></span>
    </div>
  </form>
  <!-- search -->

  <!-- actions -->
  <div class="">
    <button (click)="openModal(saveScanTypeForm)" type="submit" class="btn btn-sm btn-primary">
      <i class="bi bi-plus-lg"></i>
    </button>
  </div>
  <!-- actions -->
</div>

<div class="mt-3">
  <h6 class="mb-3 text-muted">Scan type list</h6>
  <div class="table-responsive">
    <table class="table table-centered table-nowrap mb-0">
      <thead class="table-light">
        <tr>
          <th class="border-0">Name</th>
          <th class="border-0">Created At</th>
          <th class="border-0">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let scan of activePageList">
          <td style="vertical-align: middle">
            <i class="bi bi-upc-scan" style="color: #5d6778; font-size: 20px"></i>
            <a href="javascript:void(0)" class="ml-3 font-weight-bold" style="color: #5d6778; text-decoration: none">
              {{ scan.name }}
            </a>
          </td>
          <td>{{ scan.createdAt | customdateformat:'slashDate' }}</td>
          <td>
            <div class="btn-group dropdown">
              <a href="javascript:void(0)" class="table-action-btn dropdown-toggle arrow-none btn btn-light btn-xs"
                data-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-three-dots"></i>
              </a>
              <div class="dropdown-menu" data-popper-placement="bottom-end">
                <a (click)="editScanType(scan, editScanTypeForm)" class="dropdown-item" href="javascript:void(0);"
                  title="Edit" data-toggle="modal">
                  <i class="bi bi-pencil mr-2 text-muted"></i> Edit
                </a>
                <a (click)="deleteScanTypes(scan.id)" class="dropdown-item" href="javascript:void(0);" title="Delete"
                  data-toggle="modal">
                  <i class="bi bi-trash mr-2 text-muted"></i> Delete
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex w-100">
    <app-pagination [pagesList]="
        scanTypeList
          | filter: filterList:searchFilter:false
          | paginator: elementsPerPage
      " (onPageChange)="paginatorEvent($event)">
    </app-pagination>
  </div>
</div>

<!-- save scan type -->
<ng-template #saveScanTypeForm>
  <app-modal [size]="'small'" [loading]="saving" [modalTitle]="'Create Scan Type'" [modalActionButton]="saveDataBtn">
    <form action="" [formGroup]="newScanTypeForm">
      <div class="form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <app-input [fieldId]="'name'" [control]="customFormControl('name', newScanTypeForm)"> </app-input>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- save scan type -->

<!-- edit scan type -->
<ng-template #editScanTypeForm>
  <app-modal [size]="'small'" [loading]="updating" [modalTitle]="'Edit Scan Type'" [modalActionButton]="updateDataBtn">
    <form action="" [formGroup]="updateScanTypeForm">
      <div class="form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <app-input [fieldId]="'name'" [control]="customFormControl('name', updateScanTypeForm)">
          </app-input>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- edit scan type -->