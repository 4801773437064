import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Alert } from '../../entities/global/alert.interface';
import { AlertModalComponent } from '../../components/alert-modal/alert-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public alertObservable = new BehaviorSubject<any>({});
  public alertMessage = this.alertObservable.asObservable();
  public bsModalRef?: BsModalRef;

  constructor(private modalService: BsModalService) { }



  /**
   * Sets alert
   * @desc sets and emitts current alert message
   * @param alert [alert message to be rendered]
   */
  setAlert(alert: Alert | null): void {
    this.alertObservable.next(alert);
  }

  /**
   * Sets dialog alert
   * @desc sets and emitts current alert message
   * @actionTitle method name
   * @action custom callable
   */
  openAlertModal(
    alert: Alert,
    actionTitle: string,
    action: (close: () => void) => void
  ): void {
    this.bsModalRef = this.modalService.show(AlertModalComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        type: alert.type,
        message: alert.message,
        actionBtn: action,
        actionTitle: actionTitle,
      },
      animated: true,
    });
    this.bsModalRef.content.closeBtnName = 'Close';

  }
}