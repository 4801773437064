import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { Observable } from "rxjs";
import { ChatStore, ChatState } from "./chat.store";

@Injectable({ providedIn: 'root' })
export class ChatQuery extends QueryEntity<ChatState>{
    constructor(
        protected chatStore: ChatStore
    ) {
        super(chatStore);
    }

    getSendingMessageStatus(): Observable<boolean> {
        return this.chatStore._select(state => state.sendingMessage);
    }
}