import { Injectable } from "@angular/core";
import { EntityStore, EntityState, StoreConfig } from "@datorama/akita";
import { ChatMessage } from "src/app/entities/global/chat-message.interface";

export interface ChatState extends EntityState<ChatMessage, number> {
    sendingMessage: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'chat-store' })
export class ChatStore extends EntityStore<ChatState>{
    constructor() {
        super({ sendingMessage: false });
    }
}