<div class="modal-header">
  <h4 class="modal-title pull-left">
    <span class="text-primary mr-1"><em class="bi bi-lock"></em></span>
    {{ title }}
  </h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="form-group">
      <label for="exampleInputEmail1">Enter Your Password</label>
      <input
        type="password"
        class="form-control"
        [formControl]="passwordControl"
      />
      <small id="validateuser" class="form-text text-muted"
        >We'll your password to authorize your action</small
      >
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()">
    Close
  </button>
  <button type="button" class="btn btn-primary ml-2" (click)="submit()">
    <span
      *ngIf="loading"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    {{ loading ? "Loading..." : "Validate" }}
  </button>
</div>
