import { Injectable } from '@angular/core';
import { RoleItem } from 'src/app/entities/global/role-item.interface';
import { Permission } from '../../entities/global/permission.interface';
import { SessionStorageService } from '../session/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  userPermissions!: Array<any> | undefined;

  constructor(
    private sessionService: SessionStorageService
  ) {
    this.userPermissions = this.sessionService.getUserPermissions();
  }

  isGranted(permissionQuery: string): boolean {
    if (this.userPermissions) {
      return this.processQuery(this.userPermissions, permissionQuery);
    } else {
      return false;
    }
  }

  processQuery(permissions: Array<Permission>, queryString: string): any {
    const query: Array<string> = queryString.split('|');
    const permissionGroup: Permission | undefined = permissions.find((group: Permission) => group.name === query[0]);

    if (permissionGroup) {
      const item: any = permissionGroup.items.find((item: RoleItem) => item.name === query[1]);
      return item.access;
    } else {
      return false;
    }
  }
}
