import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { ReportTemplateService } from './report-template.service';

class BaseClass { }
interface BaseClass extends PaginationComponent, FilterComponent { }
applyMixins(BaseClass, [PaginationComponent, FilterComponent]);

@Component({
  selector: 'app-report-template',
  templateUrl: './report-template.component.html',
  styleUrls: ['./report-template.component.scss']
})
export class ReportTemplateComponent extends BaseClass implements OnInit {
  loading = false;
  reportTemplates: Array<any> = [];

  elementsPerPage = 10;
  activePageList!: any[];

  showEntries!: FormControl;
  searchFilter!: string;

  constructor(
    public router: Router,
    public location: Location,
    public reportService: ReportTemplateService
  ) {

    super();

    this.showEntries = new FormControl(10);
    this.filterList = ['scanType'];
  }

  ngOnInit(): void {
    this.getReportTemplates();
  }

  gotoCreate(): void {
    this.router.navigate(['/app/doc/report-template/create']);
  }

  getReportTemplates(): void {
    this.loading = true;
    this.reportService.getReportTemplate((res, status) => {
      this.loading = false;
      if (status) {
        this.reportTemplates = res;
      }
    });
  }

  editNormal(id: number): void {
    this.router.navigate(['/app/doc/report-template/edit'], { queryParams: { id } });
  }

  deleteNormals(id: number): void {
    this.reportService.deleteReportTemplate(id, (res, status) => {
      if (status) {
        this.getReportTemplates();
      }
    })
  }

  paginatorEvent(change: any): void {
    setTimeout(() => {
      this.activePageList = change.currentChunk;
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['scanType'];
    }
  }

  back(): void {
    this.location.back();
  }
}
