import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DistributionService } from './distribution.service';

@Component({
    selector: 'app-distrbution-cards',
    templateUrl: './distribution.component.html',
    styleUrls: ['./distribution.component.scss']
})
export class DistributionComponent implements OnInit {

    priorityData!: BehaviorSubject<any>;
    weeklyData!: BehaviorSubject<any>;
    weeklyLabels!: BehaviorSubject<any>;

    constructor(
        public distributionService: DistributionService
    ) {
        this.priorityData = new BehaviorSubject(null);
        this.weeklyData = new BehaviorSubject([]);
        this.weeklyLabels = new BehaviorSubject([]);
    }

    ngOnInit(): void {
        this.getDistribution();
        this.getWeeklyCases();
    }

    getDistribution(): void {
        this.distributionService.getDistribution((res, status) => {
            if (status) {
                this.priorityData.next({
                    series: res.data,
                    labels: res.labels
                })
            }
        })
    }

    getWeeklyCases(): void {
        this.distributionService.getWeeklyCases((res, status) => {
            if (status) {
                this.weeklyData.next([{
                    name: 'Study Cases',
                    data: res.data
                }]);
                this.weeklyLabels.next(res.labels);
            }
        })
    }
}
