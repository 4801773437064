import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PermissionService } from 'src/app/services/permissions/permission.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(
    private permission: PermissionService,
    public location: Location,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.hasRequiredPermission(route.data.roleQuery)) {
      return true;
    } else {
      this.location.back();
      return true;
    }
  }

  hasRequiredPermission(queryString: string): boolean {
    if (queryString) {
      return this.permission.isGranted(queryString);
    } else {
      return false;
    }
  }
}
