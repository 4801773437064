 <div class="col-12">
   <div colspan="7" class="text-center mb-3">
     <div style="margin: 3rem;">
       <img src="assets/images/background/connection.svg" alt="" height="250px" width="250px">
       <p>
         <span>Oops, something went wrong.</span>
       </p>
     </div>
   </div>
 </div>
