import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ConnectionState {
  isConnected: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'connection-store' })
export class ConnectionStore extends Store<ConnectionState> {
  constructor() {
    super({ isConnected: false });
  }
}
