<div
  *ngIf="adaptiveList.length > 1"
  class="d-flex align-items-center justify-content-center paginator-nav"
>
  <span class="pag-btn" (click)="onClickPage(activePage - 1)">
    <em class="fas fa-angle-left text-primary"></em>
  </span>

  <!-- adaptive paginator display -->
  <ng-container *ngFor="let page of adaptiveList; let pageIndex = index">
    <!-- page number -->
    <span
      *ngIf="page.type === 'page'"
      (click)="onClickPage(page.pageNo)"
      class="page"
      [ngClass]="{ active: page.pageNo === activePage }"
    >
      {{ page.pageNo }}
    </span>
    <!-- page number -->

    <!-- hidden pages -->
    <div *ngIf="page.type === 'more'" class="d-inline-block">
      <span
        [attr.id]="'pageTarget' + pageIndex"
        app-popper
        [placement]="'top'"
        [popperType]="'popper'"
        [showEvents]="['click']"
        [hideEvents]="['mouseleave']"
        [targetElement]="'pageTarget' + pageIndex"
        [popperElement]="'pagePopper' + pageIndex"
        [popperOffset]="[0, 8]"
        class="page more"
      >
        <em class="bi bi-three-dots"></em>
      </span>

      <div
        [attr.id]="'pagePopper' + pageIndex"
        class="custom-popper"
        style="max-width: 200px"
      >
        <div class="row m-0">
          <div *ngFor="let hid of page.hiddenPages" class="col-4 text-center">
            <span class="page" (click)="onClickPage(hid.pageNo)">{{
              hid.pageNo
            }}</span>
          </div>
        </div>
        <div id="arrow" data-popper-arrow></div>
      </div>
    </div>
    <!-- hidden pages -->
  </ng-container>
  <!-- adaptive paginator display -->

  <span class="pag-btn" (click)="onClickPage(activePage + 1)">
    <em class="fas fa-angle-right text-primary"></em>
  </span>
</div>
