import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from '../base-form/base-form.component';
import { applyMixins } from '../../mixin/mixin';
import { FormControl } from '@angular/forms';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent extends BaseClass implements OnInit, DoCheck {

  iteratableOptions: Array<{ ngValue: any, display: string }> = [];
  selectionList: FormControl = new FormControl();

  constructor() {
    super();
  }

  @Input() fieldId: string | null = null;
  @Input() control!: FormControl;
  @Input() required = false;

  @Input() options: Array<any> = [];
  @Input() predicate: any;

  @Input() multiSelected: Array<any> = [];
  @Input() single = true;

  ngOnInit(): void {
    super.ngOnInit();
    this.normalizeOptions();

    this.selectionList.valueChanges.subscribe((val: any) => {
      if ((this._checkIfItemExists(val) < 0) && val !== 'select') {
        this.multiSelected.push(val);
        this.control.setValue(this.multiSelected);
      }
    });
  }

  ngDoCheck(): void {
    super.ngDoCheck();
    this.normalizeOptions();
  }

  _checkIfItemExists(item: any): any {
    return this.multiSelected.findIndex((val: any) => this.isEquivalent(val, item));
  }

  _removeItem(item: any): void {
    const index = this._checkIfItemExists(item);
    if (index >= 0) {
      this.multiSelected.splice(index, 1);
      this.control.setValue(this.multiSelected);
    }
  }

  _findDisplayName(item: any): string {
    const value = this.iteratableOptions.find((val: any) => this.isEquivalent(val.ngValue, item));
    if (value) {
      return value.display;
    } else {
      return '';
    }
  }

  isEquivalent(a: { [x: string]: any; }, b: { [x: string]: any; }): boolean {
    if (typeof a === 'object' && typeof b === 'object') {
      const aProps = Object.getOwnPropertyNames(a);
      const bProps = Object.getOwnPropertyNames(b);
      if (aProps.length !== bProps.length) {
        return false;
      }
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
      return true;
    } else {
      return a === b;
    }
  }

  normalizeOptions(): void {
    this.iteratableOptions = [];
    this.options.forEach((val: any) => {
      this.iteratableOptions.push({
        ngValue: this.predicate.method1(val),
        display: this.predicate.method2(val)
      });
    });
  }
}
