import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Endpoints } from 'src/app/constants/endpoints/endpoints';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ApiLinkService {
  constructor(private http: HttpClient) {}

  dataUplink(
    httpMethod: string,
    link: string,
    data?: any,
    params?: any,
    id?: any,
    dataTransform?: any
  ): any {
    const dataFunc = (value: any) => {
      if (dataTransform) {
        return dataTransform(value);
      } else {
        return value;
      }
    };

    switch (httpMethod) {
      case 'GET':
        const getOptions = Object.assign({ params } || {}, httpOptions);
        return this.http
          .get(
            id ? `${this.getURL(link)}/${id}` : this.getURL(link),
            getOptions
          )
          .pipe(catchError(this.handleError));

      case 'POST':
        const postOptions = Object.assign({ params } || {}, httpOptions);
        return this.http
          .post(
            id ? `${this.getURL(link)}/${id}` : this.getURL(link),
            data,
            postOptions
          )
          .pipe(map(dataFunc), catchError(this.handleError));

      case 'PUT':
        const putOptions = Object.assign({ params } || {}, httpOptions);
        return this.http
          .put(
            id ? `${this.getURL(link)}/${id}` : this.getURL(link),
            data,
            putOptions
          )
          .pipe(catchError(this.handleError));

      case 'PATCH':
        const patchOptions = Object.assign({ params } || {}, httpOptions);
        return this.http
          .patch(`${this.getURL(link)}/${id}`, data, patchOptions)
          .pipe(catchError(this.handleError));

      case 'DELETE':
        const deleteOptions = Object.assign({ params } || {}, httpOptions);
        return this.http
          .delete(`${this.getURL(link)}/${id}`, deleteOptions)
          .pipe(catchError(this.handleError));

      default:
        break;
    }
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      return throwError('There was an error!! ' + error.error.message);
    } else {
      return throwError(new ApiException(error));
    }
  }

  getURL(urlKey: string): string {
    let url = environment.apiUrl + Endpoints[urlKey];
    return url;
  }
}

export class ApiException {
  statusText: string;
  message: string;
  status: number;
  ok: boolean;

  constructor(error: any) {
    this.statusText = error.statusText;
    this.message = error.error.message;
    this.status = error.status;
    this.ok = error.ok;
  }
}
