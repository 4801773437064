
import { EMAIL_FIELD_PATTERN_MESSAGE, NUMBER_PATTERN_MESSAGE, MONEY_PATTERN_MESSAGE, COUNTING_NUMBER_PATTERN_MESSAGE } from '../validations-messages/list';

export const EMAIL_PATTERN = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}$';
export const NUMBER_PATTERN = '^[0-9]+(\.?[0-9]+)?$';
export const COUNTING_NUMBER_PATTERN = '^[1-9]+(\.?[0-9]+)?$';
export const MONEY_PATTERN = '^[1-9]+(\.?[0-9]+)?$';

export const PATTERNS_LIST: Array<{ PATTERN: string, MESSAGE: string }> = [
  { PATTERN: EMAIL_PATTERN, MESSAGE: EMAIL_FIELD_PATTERN_MESSAGE },
  { PATTERN: NUMBER_PATTERN, MESSAGE: NUMBER_PATTERN_MESSAGE },
  { PATTERN: MONEY_PATTERN, MESSAGE: MONEY_PATTERN_MESSAGE },
  { PATTERN: COUNTING_NUMBER_PATTERN, MESSAGE: COUNTING_NUMBER_PATTERN_MESSAGE }
];
