import { Component, OnInit } from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    // tslint:disable-next-line: typedef
    $('.sidebar-dropdown > a').on('click', function(this: any) {
      $('.sidebar-submenu').slideUp(200);
      if (
        $(this)
          .parent()
          .hasClass('active')
      ) {
        $('.sidebar-dropdown').removeClass('active');
        $(this)
          .parent()
          .removeClass('active');
      } else {
        $('.sidebar-dropdown').removeClass('active');
        $(this)
          .next('.sidebar-submenu')
          .slideDown(200);
        $(this)
          .parent()
          .addClass('active');
      }
    });

    $('#close-sidebar').on('click', () => {
      $('.page-wrapper').toggleClass('toggled');
    });
    $('#show-sidebar').on('click', () => {
      $('.page-wrapper').toggleClass('toggled');
    });
  }
}
