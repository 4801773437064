import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ChatNotification } from './notification.interface';

export interface ChatNotificationState extends EntityState<ChatNotification, number> {

}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'chat-notification' })
export class ChatNotificationStore extends EntityStore<ChatNotificationState> {
    constructor() {
        super()
    }
}