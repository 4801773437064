export const GENDER = [
  { name: 'Male', id: 'Male' },
  { name: 'Female', id: 'Female' }
];

export const LEVELS = [
  { name: 'Senior', id: 'Senior' },
  { name: 'Junior', id: 'Junior' }
];

export const SALUTATION = [
  { name: 'Sir', id: 'Sir' },
  { name: 'Madam', id: 'Madam' },
  { name: 'Mr', id: 'Mr' },
  { name: 'Mrs', id: 'Mrs' },
  { name: 'Miss', id: 'Miss' }
];

export const RELATIONSHIP = [
  { name: 'Son', id: 'son' },
  { name: 'Daughter', id: 'daughter' },
  { name: 'Father', id: 'father' },
  { name: 'Mother', id: 'mother' },
  { name: 'Brother', id: 'brother' },
  { name: 'Sister', id: 'sister' },
  { name: 'Others', id: 'others' }
];

export const MARITALSTATUS = [
  { name: 'Married', id: 'Married' },
  { name: 'Single', id: 'Single' },
  { name: 'Widowed', id: 'Widowed' },
  { name: 'Divorced', id: 'Divorced' },
  { name: 'Others', id: 'Others' }
];

export const MONTH = [
  'January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

export const DAYS = [
  {
    name: 'sunday',
    nameSM: 'Sun'
  },
  {
    name: 'monday',
    nameSM: 'Mon'
  },
  {
    name: 'tuesday',
    nameSM: 'Tue'
  },
  {
    name: 'wednesday',
    nameSM: 'Wed'
  },
  {
    name: 'thursday',
    nameSM: 'Thu'
  },
  {
    name: 'friday',
    nameSM: 'Fri'
  },
  {
    name: 'saturday',
    nameSM: 'Sat'
  }
];
