import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { BaseFormComponent } from '../base-form/base-form.component';
import { applyMixins } from '../../mixin/mixin';
import { FormControl } from '@angular/forms';
const readXlsxFile = require('read-excel-file');

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-import',
  templateUrl: './input-import.component.html',
  styleUrls: ['./input-import.component.scss']
})
export class InputImportComponent extends BaseClass implements OnInit, DoCheck {

  file: any;
  fileName: string | null = null;

  @Input() fieldId!: string | null;
  @Input() control!: FormControl;
  @Input() columns!: Array<string>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngDoCheck(): void {
    super.ngDoCheck();
  }

  onFileSelect($event: any): void {
    this.file = $event.target.files[0];
    this.fileName = this.file.name;
    if (this._checkFileFormat(this.file.name)) {
      readXlsxFile(this.file).then((rows: any) => {
        this.control.setValue({
          fileName: this.file.name,
          type: 'xlsx',
          content: this._convertXlsxToJson(rows)
        });
      });

    } else {
      this.control.markAsTouched();
      this.control.setErrors({ 'incorrect-file-upload-type': true });
    }
  }

  _checkFileFormat(type: string): boolean {
    return type.includes('xlsx');
  }

  _convertXlsxToJson(sheetArray: Array<any>): Array<any> {
    const formatedData: Array<any> = [];
    sheetArray.forEach((row: Array<any>, index1: number) => {
      if (index1 !== 0) {
        const dataRow = Object.assign({});
        this.columns.forEach((column: string, index2: number) => {
          dataRow[column] = row[index2];
        });
        formatedData.push(dataRow);
      }
    });
    return formatedData;
  }
}
