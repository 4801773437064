<div class="row mx-0">
  <div class="app-card app-card-orders-table shadow-sm mb-4 w-100">
    <div class="app-card-header">
      <h6 class="text-muted font-weight-bold mb-0">Search Study</h6>
    </div>
    <div class="app-card-body">
      <form action="" [formGroup]="queryForm">
        <div class="row">
          <div class="col-3">
            <input
              type="text"
              [formControl]="customQueryGroup('studyId')"
              class="form-control form-control-sm border-primary"
              name=""
              placeholder="Study ID"
            />
          </div>
          <div class="col-3">
            <input
              type="text"
              [formControl]="customQueryGroup('firstName')"
              class="form-control form-control-sm border-primary"
              name=""
              placeholder="First name"
            />
          </div>
          <div class="col-3">
            <input
              type="text"
              [formControl]="customQueryGroup('lastName')"
              class="form-control form-control-sm border-primary"
              name=""
              placeholder="Last name"
            />
          </div>
          <div class="col-3">
            <button (click)="searchStudy()" class="btn btn-primary w-100">
              <div
                *ngIf="searching"
                class="spinner-border spinner-border-sm text-white"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <span *ngIf="!searching">
                <i class="bi bi-search"></i>
                search
              </span>
            </button>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-lg-12 d-flex my-3">
          <!-- patient list -->
          <h6 class="mb-1 text-muted mt-4 mr-auto">Search Results:</h6>
          <!-- search -->
          <form action="" class="search-bar">
            <div class="position-relative">
              <input
                name="search"
                type="text"
                class="form-control form form-control-light"
                [(ngModel)]="searchFilter"
                placeholder="filter by name, id ..."
              />
              <span class="bi bi-search"></span>
            </div>
          </form>
          <span class="ml-1">
            <select
              [formControl]="showEntries"
              class="form-control custom-select custom-select-sm"
              name="number-select"
            >
              <option [ngValue]="5">5</option>
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
            </select>
          </span>
        </div>
        <div class="col-lg-12">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="text-primary">
                <th>#</th>
                <th>Patient Name</th>
                <th>Gender</th>
                <th>D.O.B</th>
                <th>Patient Id</th>
                <th>Series/Images</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let study of activePageList; let i = index"
                  class="p-2"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ study.patientName }}</td>
                  <td>{{ study.patientSex }}</td>
                  <td>
                    {{ study.patientDob | customdateformat : "slashDate" }}
                  </td>
                  <td>{{ study.patientID }}</td>
                  <td>{{ study.series + "/" + study.images }}</td>
                  <td>
                    <button
                      (click)="onSelectStudy(study)"
                      class="btn btn-secondary btn-sm"
                    >
                      select
                    </button>
                  </td>
                </tr>

                <tr *ngIf="studiesList.length === 0">
                  <td colspan="7" class="text-center">
                    <p class="text-danger">No studies found</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-12 mt-3">
            <app-pagination
              [pagesList]="
                studiesList
                  | filter : filterList : searchFilter : false
                  | paginator : elementsPerPage
              "
              (onPageChange)="paginatorEvent($event)"
            >
            </app-pagination>
          </div>
          <!-- patient list -->
        </div>
      </div>
    </div>
  </div>
</div>
