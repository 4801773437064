<div class="row g-3 mb-4 align-items-center justify-content-between">
  <div class="col-auto">
    <h1 class="app-page-title mb-0 text-muted">Radiologists</h1>
  </div>

  <div class="col-auto">
    <div class="page-utilities">
      <div class="
          row
          g-2
          justify-content-start justify-content-md-end
          align-items-center
        ">
        <div class="col-auto">
          <div class="table-search-form row gx-1 align-items-center">
            <div class="col-auto">
              <button (click)="back()" type="submit" class="btn btn-primary">
                <i class="bi bi-arrow-left"></i>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mx-0">
  <div class="d-flex w-100">
    <div class="app-card app-card-orders-table shadow-sm mb-3 w-100">
      <div class="app-card-body">
        <div class="d-flex justify-content-between align-items-center">
          <!-- search -->
          <form action="" class="search-bar">
            <div class="position-relative">
              <input name="search" type="text" class="form-control form form-control-light" [(ngModel)]="searchFilter"
                placeholder="Search ..." />
              <span class="bi bi-search"></span>
            </div>
          </form>
          <!-- search -->

          <!-- actions -->
          <div class="">
            <button (click)="openModal(newRadiologistModal)" type="submit" class="btn btn-sm btn-primary">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
          <!-- actions -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div *ngIf="!getting" class="col-lg-12">
    <div *ngIf="radiologists.length > 0" class="table-card shadow-sm">
      <div class="table-card-body">
        <div class="table-responsive mt-3">
          <table class="table themed-table table-sticky-column themed-table-plain">
            <thead class="">
              <tr>
                <th class="cell">
                  <div class="cell-border">
                    <span>Name</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Email</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Phone</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Address</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Status</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Created At</span>
                  </div>
                </th>
                <th class="cell text-center">
                  <div class="">
                    <span>Action</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let rad of activePageList">
                <td class="cell">
                  <a *ngIf="rad.user.userImage !== null" href="javascript:void(0);" class="avatar"
                    style="text-transform: upperCase">
                    <img [src]="assetsRoot + rad.user.userImage.url" height="38" alt="" />
                  </a>
                  <a *ngIf="rad.user.userImage === null" href="javascript:void(0);" class="avatar"
                    style="text-transform: upperCase">
                    {{ rad.user.firstName[0] }}
                  </a>
                  <h2>
                    <a href="javascript:void(0);">{{
                      [rad.user.firstName, rad.user.lastName].join(" ")
                      }}</a>
                  </h2>
                </td>
                <td class="cell">{{ rad.user.email }}</td>
                <td class="cell">{{ rad.user.phoneNumber }}</td>
                <td class="cell">{{ rad.address }}</td>
                <td class="cell text-center">
                  <div class="dropdown action-label">
                    <a class="btn btn-white btn-sm rounded dropdown-toggle" href="#" data-toggle="dropdown" [ngClass]="{
                        'text-success': rad.status === 'ACTIVE',
                        'text-info': rad.status === 'CREATED',
                        'text-danger': rad.status === 'IN-ACTIVE'
                      }" aria-expanded="false" style="text-transform: lowercase">
                      <i class="bi bi-record-circle"></i>
                      {{ rad.status }}
                      <i class="caret"></i>
                    </a>
                    <ul class="dropdown-menu pull-right">
                      <li>
                        <a href="javascript:void(0);" (click)="activateRad(rad.id)">
                          <i class="bi bi-record-circle text-success"></i>
                          Activate
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" (click)="deActivateRad(rad.id)">
                          <i class="bi bi-record-circle text-danger"></i>
                          De-activate
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
                <td class="cell">
                  {{ rad.createdAt | customdateformat: "toDateTime" }}
                </td>
                <td class="cell text-center">
                  <div class="btn-group dropdown">
                    <a href="javascript:void(0)" class="
                        table-action-btn
                        dropdown-toggle
                        arrow-none
                        btn btn-light btn-xs
                      " data-toggle="dropdown" aria-expanded="false">
                      <i class="bi bi-three-dots"></i>
                    </a>
                    <div class="dropdown-menu" data-popper-placement="bottom-end">
                      <a (click)="editRadiologist(rad, editRadiologistModal)" class="dropdown-item"
                        href="javascript:void(0);" title="Edit" data-toggle="modal">
                        <i class="bi bi-pencil mr-2 text-muted"></i> Edit
                      </a>
                      <a (click)="deleteRad(rad.id)" class="dropdown-item" href="javascript:void(0);" title="Delete"
                        data-toggle="modal">
                        <i class="bi bi-trash mr-2 text-muted"></i> Delete
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="radiologists.length === 0">
                <td colspan="7">
                  <div class="d-block text-center" style="margin: 4rem">
                    <img src="assets/images/background/empty.svg" alt="" width="250px" />
                  </div>
                  <span class="d-block text-center">Empty List</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="radiologists.length === 0">
      <app-empty-placeholder></app-empty-placeholder>
    </div>
  </div>

  <div *ngIf="getting" class="col-12 col-lg-12 text-center">
    <app-loading-placeholder></app-loading-placeholder>
  </div>

  <div class="col-lg-12 mt-3">
    <app-pagination [pagesList]="
        radiologists
          | filter: filterList:searchFilter:false
          | paginator: elementsPerPage
      " (onPageChange)="paginatorEvent($event)">
    </app-pagination>
  </div>
</div>

<!-- new radiologist -->
<ng-template #newRadiologistModal>
  <app-modal [modalActionButton]="saveRadioBtn" [modalTitle]="'Create Radiologist'" [condition]="newRadiologist.invalid"
    [loading]="saving">
    <form action="" [formGroup]="newRadiologist">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'firstName'" [required]="true"
            [control]="customFormControl('firstName', newRadiologist)"></app-input>
        </div>
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'lastName'" [required]="true" [control]="customFormControl('lastName', newRadiologist)">
          </app-input>
        </div>

        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'email'" [required]="true" [control]="customFormControl('email',newRadiologist)">
          </app-input>
        </div>
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'phone'" [control]="customFormControl('phoneNumber',newRadiologist)"></app-input>
        </div>

        <div class="col-lg-12 col-md-12">
          <app-input [fieldId]="'address'" [control]="customFormControl('location',newRadiologist)"></app-input>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- new radiologist -->

<!-- edit radiologist -->
<ng-template #editRadiologistModal>
  <app-modal [modalActionButton]="updateRadioBtn" [modalTitle]="'Update Radiologist'"
    [condition]="editRadiologistForm.invalid" [loading]="updating">
    <form action="" [formGroup]="editRadiologistForm">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'firstName'" [required]="true"
            [control]="customFormControl('firstName',editRadiologistForm)"></app-input>
        </div>
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'lastName'" [required]="true"
            [control]="customFormControl('lastName',editRadiologistForm)"></app-input>
        </div>

        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'email'" [required]="true" [control]="customFormControl('email',editRadiologistForm)">
          </app-input>
        </div>
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'phone'" [control]="customFormControl('phoneNumber', editRadiologistForm)"></app-input>
        </div>

        <div class="col-lg-12 col-md-12">
          <app-input [fieldId]="'address'" [control]="customFormControl('location', editRadiologistForm)">
          </app-input>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- edit radiologist -->