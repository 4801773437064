import { Component, DoCheck, OnInit, Input } from '@angular/core';
import { BaseFormComponent } from '../base-form/base-form.component';
import { applyMixins } from '../../mixin/mixin';
import { FormControl } from '@angular/forms';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent extends BaseClass implements OnInit, DoCheck {

  @Input() fieldId!: string | null;
  @Input() control!: FormControl;
  @Input() fileDesciption: string | null = null;
  @Input() defaultFileName = '';
  @Input() required = false;

  fileInput!: HTMLElement;
  fileName: string | null = null;
  fileType: string | null = null;
  file: any;
  validTypes: Array<string> = ['pdf', 'jpeg', 'png', 'webp'];

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngDoCheck(): void {
    super.ngDoCheck();
  }

  onFileSelect($event: any): void {
    this.file = $event.target.files[0];
    if (this.file) {
      const originalType = this.file.type.split('/')[1];

      // validating selected image file
      if (!this.checkForFileType(originalType)) {
        this.control.markAsTouched();
        this.control.setErrors({ 'incorrect-file-upload-type': true });
      } else {
        this.fileName = this.file.name;
        this.fileType = this.file.type.split('/')[1];

        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.control.setValue({
            name: this.defaultFileName !== '' ? this.defaultFileName : this.fileName,
            fileType: this.fileType,
            data: event.target.result.split(',')[1],
            description: (this.fileDesciption === null) ? '' : this.fileDesciption
          });
        };
        reader.readAsDataURL(this.file);
      }
    }
  }

  checkForFileType(type: string): boolean {
    return this.validTypes.find(val => val === type) ? true : false;
  }
}
