import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings/settings.service';

@Component({
    selector: 'app-web-footer',
    templateUrl: './web-footer.component.html',
    styleUrls: ['./web-footer.component.scss']
})
export class WebFooterComponent implements OnInit {

    constructor(
        public settingService: SettingsService
    ) { }

    ngOnInit(): void { }
}
