<div class="row g-3 mb-4 align-items-center justify-content-between">
  <div class="col-auto">
    <h1 class="app-page-title mb-0 text-muted">Studies</h1>
  </div>
  <div class="col-auto">
    <div class="page-utilities">
      <div class="row g-2 justify-content-start justify-content-md-end align-items-center">
        <div class="col-auto">
          <form class="table-search-form row gx-1 align-items-center">
            <div class="col-auto">
              <button (click)="back()" type="submit" class="btn btn-primary">
                <i class="bi bi-arrow-left"></i>
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mx-0">
  <div class="d-flex w-100">
    <div class="app-card app-card-orders-table shadow-sm mb-3 w-100">
      <div class="app-card-body">
        <div class="d-flex justify-content-between align-items-center">
          <!-- search -->
          <form action="" class="search-bar">
            <div class="position-relative">
              <input name="search" type="text" class="form-control form form-control-light" [(ngModel)]="searchFilter"
                placeholder="Search ..." />
              <span class="bi bi-search"></span>
            </div>
          </form>
          <span class="ml-1">
            <select [formControl]="showEntries" class="form-control custom-select custom-select-sm"
              name="number-select">
              <option [ngValue]="5">5</option>
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
            </select>
          </span>
          <!-- search -->

          <!-- loader -->
          <div *ngIf="gettingStudies" class="ml-2 text-primary">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </div>
          <!-- loader -->

          <!-- actions -->
          <div class="ml-auto">
            <button *ngIf="openFilter" (click)="clearFilters()" type="submit"
              class="btn btn-sm btn-white text-muted mr-2">
              <i class="bi bi-x text-danger mr-1"></i>
              clear
            </button>

            <button *ngIf="!openFilter" (click)="openBackendFilter()" type="submit" class="btn btn-sm btn-primary">
              <i class="bi bi-sliders"></i>
            </button>

            <button *ngIf="openFilter" (click)="clearBackendFilter()" type="submit" class="btn btn-sm btn-primary">
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
          <!-- actions -->
        </div>

        <div id="backend-filter" class="row align-items-end d-none mt-3">
          <div class="col-3">
            <div class="form-group">
              <label for="level">Level</label>
              <select class="form-control custom-select-sm border-primary" [formControl]="customFormControl('level')">
                <option [ngValue]="null">Select</option>
                <option [ngValue]="'normal'">Normal</option>
                <option [ngValue]="'priority'">Priority</option>
                <option [ngValue]="'stat'">Stat</option>
              </select>
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <label for="level">Status</label>
              <select class="form-control custom-select-sm border-primary" [formControl]="customFormControl('status')">
                <option [ngValue]="null">Select</option>
                <option [ngValue]="'all'">All</option>
                <option [ngValue]="'NEW'">Pending</option>
                <option [ngValue]="'OPENED'">Open</option>
                <option [ngValue]="'FLAGGED'">Flagged</option>
                <option [ngValue]="'REPORTED'">Reported</option>
              </select>
            </div>
          </div>

          <div class="col-3">
            <app-input-date [borderClass]="'border-primary'" [singleDate]="false" [fieldId]="'studyDate'"
              [control]="customFormControl('studyDate')"></app-input-date>
          </div>

          <div class="col-3">
            <app-input-select-search *ngIf="radiologists.length > 0" [borderClass]="'border-primary'"
              [uniqueClass]="'radio'" [options]="radiologists" [predicate]="radioPredicate" [fieldId]="'radiologist'"
              [control]="customFormControl('radiologist')">
            </app-input-select-search>
          </div>

          <div class="col-3">
            <app-input-select-search *ngIf="clients.length > 0" [borderClass]="'border-primary'"
              [uniqueClass]="'client'" [options]="clients" [predicate]="clientsPredicate" [fieldId]="'facility'"
              [control]="customFormControl('facility')">
            </app-input-select-search>
          </div>

          <div class="col-3 ml-auto">
            <div class="input-group mb-3">
              <input [formControl]="searchByStudyIdForm" type="text" class="form-control form-control-sm border-primary"
                placeholder="Study ID ..." />
              <div class="input-group-append">
                <button [disabled]="searchByStudyIdForm.invalid" (click)="searchStudyById()"
                  class="btn btn-primary btn-sm" type="button">
                  search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div *ngIf="studies.length > 0" class="table-card shadow-sm">
      <div class="table-card-body">
        <div class="table-responsive mt-3">
          <table class="table themed-table table-sticky-column themed-table-plain">
            <thead>
              <tr>
                <th class="cell stick">
                  <div class="cell-border">
                    <span>Patient Name</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Study Type</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Status</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Category</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Medical Center</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Study Date</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Series/Images</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Study ID</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Reported At</span>
                  </div>
                </th>
                <th class="cell text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let study of activePageList" [ngClass]="{
                  stat: study.level === 'Stat',
                  proirity: study.level === 'Priority'
                }">
                <td class="cell stick">
                  <span (click)="viewStudy(study.id, study.studyUID)" style="cursor: pointer"
                    class="truncate font-weight-bold">
                    {{ study.patientName }}
                  </span>
                </td>
                <td class="cell">
                  <span class="studyId">{{ study.studyDecription }}</span>
                </td>
                <td class="cell text-center">
                  <span class="badge badge-danger" [ngClass]="{
                      'badge-danger': study.status === 'NEW',
                      'badge-warning': study.status === 'OPENED',
                      'badge-info':
                        study.status === 'UNASSIGNED' ||
                        study.status === 'FLAGGED',
                      'badge-primary':
                        study.status === 'ASSIGNED' ||
                        study.status === 'UNFLAGGED',
                      'badge-muted': study.status === 'DELAYED',
                      'badge-success': study.status === 'REPORTED',
                      'badge-flagged': study.status === 'FLAGGED'
                    }">
                    {{ study.status }}
                  </span>
                </td>
                <td class="cell text-center">
                  <span class="badge badge-primary text-uppercase" [ngClass]="{
                      'badge-info': isAssignedOrReviewer(study?.studyReviewers, study?.assigned_radioligist) === 'assignee',
                      'badge-primary': isAssignedOrReviewer(study?.studyReviewers, study?.assigned_radioligist) === 'reviewer'
                    }">
                    {{ isAssignedOrReviewer(study?.studyReviewers, study?.assigned_radioligist) }}
                  </span>
                </td>
                <td class="cell">
                  <span class="studyId">{{ study.medicalCenter.name }}</span>
                </td>
                <td class="cell">
                  {{ study.studyDate | customdateformat : "localDateTime" }}
                </td>
                <td class="cell text-center">
                  {{ study.series || "-" }}/{{ study.images || "-" }}
                </td>
                <td class="cell">{{ study.studyUID }}</td>
                <td class="cell">
                  <span *ngIf="study.signedAt">
                    {{ study.signedAt | customdateformat : "localDateTime" }}
                  </span>
                </td>
                <td class="cell text-center">
                  <a href="javascript:void(0)" class="table-action-btn dropdown-toggle arrow-none btn btn-light btn-xs"
                    data-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </a>
                  <div class="dropdown-menu" data-popper-placement="bottom-end">
                    <a class="dropdown-item" href="javascript:void(0);" (click)="viewStudy(study.id, study.studyUD)">
                      <i class="bi bi-eye mr-2 text-primary"></i> Study
                      Details
                    </a>
                    <a class="dropdown-item" href="javascript:void(0);" (click)="openInDicomViewer(study.studyUID)">
                      <i class="bi bi-arrow-up-right-circle mr-2 text-muted"></i>
                      Open with OHIF viewer
                    </a>
                    <a class="dropdown-item" href="javascript:void(0);" (click)="
                        openWithWeasisViewer(study.studyUID, study.parentID)
                      ">
                      <i class="bi bi-arrow-up-right-circle mr-2 text-muted"></i>
                      Open with Weasis viewer
                    </a>
                    <a class="dropdown-item" href="javascript:void(0);"
                      (click)="openFlagStudy(flagStudyModal, study.id)">
                      <i class="bi bi-flag mr-2 text-danger"></i>
                      Flag Study
                    </a>
                    <a *ngIf="study.status !== 'REPORTED'" (click)="returnToPool(study.id)" class="dropdown-item"
                      href="javascript:void(0);">
                      <i class="bi bi-arrow-90deg-left mr-2 text-muted"></i>
                      Return to Pool
                    </a>
                    <a *ngIf="study.status === 'REPORTED'" class="dropdown-item" href="javascript:void(0);"
                      (click)="editStudy(study.id)">
                      <i class="bi bi-pencil mr-2 text-primary"></i>
                      Edit Study
                    </a>
                  </div>
                </td>
              </tr>
              <tr *ngIf="studies.length === 0">
                <td colspan="7">
                  <div class="d-block text-center" style="margin: 4rem">
                    <img src="assets/images/background/empty.svg" alt="" width="250px" />
                  </div>
                  <span class="d-block text-center">Empty List</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12 mt-3">
    <app-pagination [pagesList]="
        studies
          | filter : filterList : searchFilter : false
          | paginator : elementsPerPage
      " (onPageChange)="paginatorEvent($event)">
    </app-pagination>
  </div>
</div>

<ng-template #flagStudyModal>
  <app-modal [loading]="flagging" [modalTitle]="'Flag Study'" [modalActionButton]="flagStudyBtn">
    <form action="" [formGroup]="flagStudyForm">
      <div class="form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <app-input-textarea [fieldId]="'reason'" [control]="customForm('reason', flagStudyForm)">
          </app-input-textarea>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>