import { Component, OnInit, TemplateRef } from '@angular/core';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { ScanTypeService } from './scan-types.service';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass
  extends ModalComponent,
  PaginationComponent,
  FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginationComponent, FilterComponent]);

@Component({
  selector: 'app-scan-types',
  templateUrl: './scan-types.component.html',
  styleUrls: ['./scan-types.component.scss'],
})
export class ScanTypesComponent extends BaseClass implements OnInit {
  newScanTypeForm!: FormGroup;
  updateScanTypeForm!: FormGroup;
  showEntries!: FormControl;
  scanTypeList!: Array<any>;
  searchFilter!: string;

  saving = false;
  deleting = false;
  updating = false;
  getting = false;

  saveDataBtn!: object;
  updateDataBtn!: object;

  elementsPerPage = 10;
  activePageList!: any[];

  constructor(
    public modalService: BsModalService,
    public scanTypeService: ScanTypeService
  ) {
    super();

    this.scanTypeList = [];

    this.newScanTypeForm = new FormGroup({
      name: new FormControl(),
    });

    this.updateScanTypeForm = new FormGroup({
      name: new FormControl(),
    });

    this.showEntries = new FormControl(10);

    this.filterList = ['name'];

    this.saveDataBtn = {
      method: () => {
        this.saveScanTypes();
      },
      text: 'save',
    };

    this.updateDataBtn = {
      method: () => {
        this.updateScanTypes();
      },
      text: 'update',
    };
  }

  ngOnInit(): void {
    this.getScanTypes();
  }

  getScanTypes(): void {
    this.getting = true;
    this.scanTypeService.getScanType((res, status) => {
      this.getting = false;
      if (status) {
        this.scanTypeList = res;
        (res);
      }
    });
  }

  saveScanTypes(): void {
    this.saving = true;
    this.scanTypeService.saveScanType(this.newScanTypeForm.value, (res, status) => {
      this.saving = false;
      if (status) {
        this.getScanTypes();
        this.closeModal();
      }
    });
  }

  deleteScanTypes(id: number): void {
    this.deleting = true;
    this.scanTypeService.deleteScanType(id, (res, status) => {
      this.deleting = false;
      if (status) {
        this.getScanTypes();
      }
    });
  }


  editScanType(data: any, target: TemplateRef<any>): void {
    this.updateScanTypeForm = new FormGroup({
      name: new FormControl(data.name),
      id: new FormControl(data.id),
    });
    this.openModal(target);
  }

  updateScanTypes(): void {
    this.updating = true;
    this.scanTypeService.updateScanType(this.updateScanTypeForm.value, (res, status) => {
      this.updating = false;
      if (status) {
        this.closeModal();
        this.getScanTypes();
      }
    });
  }

  paginatorEvent(change: any): void {
    setTimeout(() => {
      this.activePageList = change.currentChunk;
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['name'];
    }
  }

  customFormControl(name: string, group: FormGroup): FormControl{
    return group.get(name) as FormControl;
  }
}
