import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment.prod';
import { RadiologistService } from './radiologist.service';

class BaseClass { }
interface BaseClass
  extends ModalComponent,
  PaginationComponent,
  FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginationComponent, FilterComponent]);

@Component({
  selector: 'app-radiologist',
  templateUrl: './radiologist.component.html',
  styleUrls: ['./radiologist.component.scss'],
})
export class RadiologistComponent extends BaseClass implements OnInit {
  elementsPerPage = 10;
  activePageList!: any[];
  assetsRoot = environment.imageUrl;

  showEntries!: FormControl;
  searchFilter!: string;
  radiologists: Array<any> = [];

  newRadiologist!: FormGroup;
  editRadiologistForm!: FormGroup;
  saving = false;
  updating = false;
  getting = false;

  saveRadioBtn: object = {
    method: () => this.saveRadiologist(),
    text: 'save',
  };

  updateRadioBtn: object = {
    method: () => this.updateRadiologist(),
    text: 'update',
  };

  constructor(
    public location: Location,
    public modalService: BsModalService,
    public radiologistService: RadiologistService
  ) {
    super();

    this.showEntries = new FormControl(10);
    this.filterList = ['user|firstName', 'user|lastName'];

    this.newRadiologist = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      location: new FormControl(),
      phoneNumber: new FormControl(),
    });

    this.editRadiologistForm = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      location: new FormControl(),
      phoneNumber: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.getRadiologist();
  }

  getRadiologist(): void {
    this.getting = true;
    this.radiologistService.getRadiologist((res, status) => {
      this.getting = false;
      if (status) {
        this.radiologists = res;
      }
    });
  }

  saveRadiologist(): void {
    this.saving = true;
    this.radiologistService.saveRadiologist(this.newRadiologist.value, (res, status) => {
      this.saving = false;
      if (status) {
        this.getRadiologist();
        this.closeModal();
      }
    });
  }

  editRadiologist(data: any, template: TemplateRef<any>): void {
    this.editRadiologistForm = new FormGroup({
      id: new FormControl(data.id),
      firstName: new FormControl(data.user.firstName, Validators.required),
      lastName: new FormControl(data.user.lastName, Validators.required),
      email: new FormControl(data.user.email, Validators.required),
      location: new FormControl(data.address),
      phoneNumber: new FormControl(data.user.phoneNumber),
      role: new FormControl(data.user.role),
    });

    this.modalService.show(template);
  }

  updateRadiologist(): void {
    this.updating = true;
    this.radiologistService.updateRadiologist(this.editRadiologistForm.value, (res, status) => {
      this.updating = false;
      if (status) {
        this.closeModal();
        this.getRadiologist();
      }
    });
  }

  activateRad(id: number): void {
    this.radiologistService.activateRadiologist(id, (res, status) => {
      if (status) {
        this.getRadiologist();
      }
    });
  }

  deActivateRad(id: number): void {
    this.radiologistService.deactivateRadiologist(id, (res, status) => {
      if (status) {
        this.getRadiologist();
      }
    });
  }

  deleteRad(id: number): void {
    this.radiologistService.deleteRadiologist(id, (res, status) => {
      if (status) {
        this.getRadiologist();
      }
    });
  }

  paginatorEvent(change: any): void {
    setTimeout(() => {
      this.activePageList = change.currentChunk;
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['user|firstName', 'user|lastName'];
    }
  }

  back(): void {
    this.location.back();
  }

  customFormControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }

}
