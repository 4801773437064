import { Routes } from '@angular/router';
import { PermissionGuard } from 'src/app/services/guards/permission.guard';
import { ClientsComponent } from './clients/clients.component';
import { DocDashboardComponent } from './home/doc-dashboard.component';
import { NewTemplateComponent } from './report-template/new-template/new-template.component';
import { EditTemplateComponent } from './report-template/edit-template/edit-template.component';
import { ReportTemplateComponent } from './report-template/report-template.component';
import { PoolComponent } from './pool/pool.component';
import { RadiologistComponent } from './radiologist/radiologist.component';
import { StudiesComponent } from './studies/studies.component';
import { SingleStudyComponent } from './single-study/single-study.component';
import { StudyReportComponent } from './single-study/study-report/study-report.component';
import { StudyDetailsComponent } from './single-study/study-details/study-details.component';
import { ArchiveStudiesComponent } from './archive/archive-studies.component';

export const routes: Routes = [
  { path: 'dashboard', component: DocDashboardComponent },
  {
    path: 'studies',
    component: StudiesComponent,
    canActivate: [PermissionGuard],
    data: { roleQuery: 'Study|View' },
  },
  {
    path: 'studies/single',
    component: SingleStudyComponent,
    children: [
      {
        path: 'study-details',
        component: StudyDetailsComponent,
      },
      {
        path: 'study-report',
        component: StudyReportComponent,
      },
    ],
  },
  {
    path: 'radiologists',
    component: RadiologistComponent,
    canActivate: [PermissionGuard],
    data: { roleQuery: 'Radiologists|View' },
  },
  {
    path: 'manage-clients',
    component: ClientsComponent,
    canActivate: [PermissionGuard],
    data: { roleQuery: 'Clients|View' },
  },
  {
    path: 'manage-pool',
    component: PoolComponent,
    canActivate: [PermissionGuard],
    data: { roleQuery: 'Pool|View' },
  },
  {
    path: 'report-template',
    component: ReportTemplateComponent,
    canActivate: [PermissionGuard],
    data: { roleQuery: 'Normals|View' },
  },
  {
    path: 'report-template/create',
    component: NewTemplateComponent,
    canActivate: [PermissionGuard],
    data: { roleQuery: 'Normals|Create' },
  },
  {
    path: 'report-template/edit',
    component: EditTemplateComponent,
    canActivate: [PermissionGuard],
    data: { roleQuery: 'Normals|Edit' },
  },
  {
    path: 'archive-studies',
    component: ArchiveStudiesComponent,
    canActivate: [PermissionGuard],
    data: { roleQuery: 'Archive|View' },
  },
];
