<div *ngIf="singleDate" class="form-group" [ngClass]="marginClass">
  <label *ngIf="fieldId" [for]="fieldId" class="label text-muted">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>
  <input [readOnly]="readOnly" [ngClass]="[borderClass]" [formControl]="fakeControl" #datepickerYMD="bsDatepicker"
    bsDatepicker (bsValueChange)="onPickDate($event)"
    [bsConfig]="{ minDate: minDate, isAnimated: true, containerClass: 'theme-dark-blue' }" autocomplete="off"
    [attr.placeholder]="placeHolder" class="form-control form-control-sm" [placement]="placement" />
  <input type="text" [formControl]="control" hidden>
  <app-form-validation [validationErrors]="validationErrors"></app-form-validation>
</div>

<div *ngIf="!singleDate" class="form-group" [ngClass]="marginClass">
  <label *ngIf="fieldId" [for]="fieldId" class="label text-muted">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>
  <input [readOnly]="readOnly" [ngClass]="[borderClass]" [formControl]="fakeControl" #datepickerYMD="bsDaterangepicker"
    bsDaterangepicker (bsValueChange)="onPickDate($event)"
    [bsConfig]="{isAnimated: true, containerClass: 'theme-dark-blue' }" autocomplete="off"
    [attr.placeholder]="placeHolder" class="form-control form-control-sm" [placement]="placement" />
  <input type="text" [formControl]="control" hidden>
  <app-form-validation [validationErrors]="validationErrors"></app-form-validation>
</div>
