import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from 'src/app/components/components.module';
import { routes } from './routes';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { WebsiteModule } from '../pages/website/website.module';
import { DocModule } from '../pages/doc/doc.module';
import { HosModule } from '../pages/hos/hos.module';
import { SettingsModule } from '../pages/settings/settings.module';

import { LayoutModule } from 'src/app/layout/layout.module';
import { LoginComponent } from '../pages/auth/login/login.component';
import { jwtInterceptorProviders } from '../services/interceptor/Jwt.interceptor';
import { AccountComponent } from '../pages/account/account.component';
import { RecoverPasswordComponent } from '../pages/auth/recover-password/recover-password.component';

@NgModule({
  declarations: [
    LoginComponent,
    AccountComponent,
    RecoverPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    LayoutModule,
    ComponentsModule,
    NgxExtendedPdfViewerModule,
    WebsiteModule,
    DocModule,
    HosModule,
    SettingsModule
  ],
  exports: [
    RouterModule,
    LayoutModule,
    RouterModule,
    ComponentsModule
  ],
  providers: [jwtInterceptorProviders]
})
export class RoutesModule { }
