<nav id="topnav" class="navbar navbar-expand-lg fixed-top custom-nav sticky">
    <div class="container">
        <a class="logo navbar-brand" href="index.html">
            <img src="assets/images/access-white.png" alt="" class="img-fluid logo-dark">
            <img src="assets/images/access.png" alt="" class="img-fluid logo-light">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i class="bi bi-list"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li class="nav-item active">
                    <a href="#home" class="nav-link">Home</a>
                </li>
                <li class="nav-item">
                    <a href="#services" class="nav-link">Services</a>
                </li>
                <li class="nav-item">
                    <a href="#about" class="nav-link">About Us</a>
                </li>
                <li class="nav-item">
                    <a href="#contact" class="nav-link">Contact</a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void" [routerLink]="[ '/auth/login']" class="nav-link">Portal</a>
                </li>
            </ul>
        </div>
    </div>
</nav>