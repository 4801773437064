<div class="row" *ngIf="!gettingDetails">
  <div class="col-lg-6 col-md-6 col-sm-12 mt-3 flex-fill">
    <div class="row">
      <!-- patient -->
      <div class="col-lg-12 col-md-12 col-sm-12 mb-3 flex-fill">
        <div class="group h-100">
          <label for="" class="group-label">Patient Info</label>
          <div class="row">
            <div class="col-12">
              <ul class="personal-info">
                <li>
                  <div class="title text-muted">Patient Name:</div>
                  <div class="text">
                    {{ studyDetails?.patientName }}
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Gender:</div>
                  <div class="text">
                    {{ studyDetails?.patientSex }}
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Date of Birth:</div>
                  <div class="text">
                    {{ studyDetails?.patientDob | customdateformat : "slashDate" }}
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Patient Age:</div>
                  <div class="text">
                    {{
                    studyDetails?.patientAge || getAge(studyDetails?.patientDob)
                    }}
                    yrs
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Location:</div>
                  <div class="text">
                    {{ studyDetails?.location }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- patient -->

      <!-- clinical History -->
      <div class="col-lg-12 col-md-12 col-sm-12 mt-3 mb-3 flex-fill">
        <div class="group h-100">
          <label for="" class="group-label">Clinical History</label>
          <div class="row">
            <div class="col-12">
              <p>
                {{ studyDetails?.clinicalHistory }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- clinical History -->

      <!-- study info -->
      <div class="col-lg-12 col-md-12 col-sm-12 mt-3 mb-3 flex-fill">
        <div class="h-100 group">
          <label for="" class="group-label">Study Info</label>
          <div class="row">
            <div class="col-12">
              <ul class="personal-info">
                <li>
                  <div class="title text-muted">Scan Type:</div>
                  <div class="text">
                    {{ studyDetails?.scanType?.name }}
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Study Date:</div>
                  <div class="text">
                    {{ studyDetails?.studyDate | customdateformat : "slashDate" }}
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Medical Center:</div>
                  <div class="text">
                    {{ studyDetails?.medicalCenter?.name }}
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Referring Physician:</div>
                  <div class="text">
                    {{ studyDetails?.referringPhysician }}
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Level:</div>
                  <div class="text">
                    {{ studyDetails?.level }}
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Modality:</div>
                  <div class="text">
                    {{ studyDetails?.modality }}
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Patient PhoneNo:</div>
                  <div class="text">
                    {{ studyDetails?.patientPhone }}
                  </div>
                </li>
                <li>
                  <div class="title text-muted">Study ID:</div>
                  <div class="text">
                    {{ studyDetails?.studyUID }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- attachments -->
      <div class="col-lg-12 col-md-12 col-sm-12 mt-3 flex-fill">
        <div class="group">
          <label for="" class="group-label">Attachments</label>
          <div class="row">
            <div class="w-100">
              <table class="table table-centered table-nowrap mb-0 files">
                <tbody>
                  <tr *ngIf="!(studyDetails?.studyAttachments?.length > 0)" class="text-center">
                    <td colspan="3" class="text-center text-muted">
                      <span class="d-block">no attached files</span>
                    </td>
                  </tr>
                  <tr *ngFor="let file of studyDetails?.studyAttachments">
                    <td>
                      <i *ngIf="file.type === 'pdf'" class="bi bi-file-earmark-pdf mr-2" style="font-size: 20px">
                      </i>
                      <i *ngIf="file.type !== 'pdf'" class="bi bi-image mr-2" style="font-size: 20px">
                      </i>
                      <span>{{ file.name }}</span>
                    </td>
                    <td>
                      {{ file.type }}
                    </td>
                    <td>
                      <a class="btn-sm app-btn-secondary" (click)="openAttachment(file, studyAttachmentModal)"
                        href="javascript:void(0);">Open</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- attachments -->
    </div>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12 mt-3 flex-fill">
    <div class="row">
      <!-- reviewer list -->
      <div class="col-lg-12 col-md-12 col-sm-12 mb-3 flex-fill">
        <div class="group h-100">
          <label for="" class="group-label">Study Reviewers</label>
          <div class="row">
            <div class="col-12">
              <ul class="personal-info list-group">
                <li *ngFor="let reviewer of studyDetails?.studyReviewers"
                  class="list-group-item d-flex align-items-center justify-content-between mb-0">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="d-flex align-items-center">
                      <a *ngIf="reviewer.user.userImage !== null" href="javascript:void(0);" class="avatar"
                        style="text-transform: upperCase">
                        <img [src]="
                      assetsRoot + reviewer.user.userImage.url
                    " height="38" alt="" />
                      </a>
                      <a *ngIf="reviewer.user.userImage === null" href="javascript:void(0);" class="avatar"
                        style="text-transform: upperCase">
                        {{ reviewer.user.firstName[0] }}
                      </a>
                      <h6 class="mb-0">
                        <a href="javascript:void(0);">
                          {{ [reviewer.user.firstName,reviewer.user.lastName].join(" ") }}
                        </a>
                      </h6>
                    </div>

                    <span (click)="removeReviewer(reviewer.id)" class="btn btn-light btn-sm ms-auto">
                      <em class="bi bi-trash text-danger"></em>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- reviewer list -->

      <!-- reviews -->
      <div class="col-lg-12 col-md-12 col-sm-12 mt-3 mb-3 flex-fill">
        <div class="group h-100">
          <label for="" class="group-label">Study Reviews</label>
          <div class="row">
            <div class="col-12 review-container">
              <div *ngFor="let review of studyReviews" class="d-flex flex-column w-100 review-item mb-3">
                <div class="d-flex align-items-center gap-2 justify-content-between w-100">
                  <div class="d-flex align-items-center">
                    <a *ngIf="review?.radiologist?.user?.userImage !== null" href="javascript:void(0);" class="avatar"
                      style="text-transform: upperCase">
                      <img [src]="assetsRoot + review?.radiologist?.user?.userImage?.url" height="38" alt="" />
                    </a>
                    <a href="javascript:void(0);" class="avatar" style="text-transform: upperCase">
                      {{ review?.radiologist?.user?.firstName[0] }}
                    </a>
                    <h6 class="mb-0">
                      <a href="javascript:void(0);">
                        {{ [review?.radiologist?.user?.firstName,review?.radiologist?.user?.lastName].join(" ") }}
                      </a>
                    </h6>
                  </div>

                  <small class="ms-auto">
                    {{ review?.createdAt | customdateformat: "dateToTime" }}
                  </small>
                </div>
                <div class="review-comment">
                  <p>{{review?.comment}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- reviews -->
    </div>
  </div>
</div>
<!-- study details -->

<div class="row mx-0" *ngIf="gettingDetails">
  <div class="col-12">
    <app-loading-placeholder [bgColor]="'text-primary'"></app-loading-placeholder>
  </div>
</div>

<!-- open Attachment Modal -->
<ng-template #studyAttachmentModal>
  <app-modal [size]="'xlong'" [isModalBtn]="false" [modalTitle]="'Study Attachment'">
    <div class="image-file" *ngIf="viewableAttachment.type !== 'pdf'">
      <img class="img-fluid" alt="Responsive image" *ngIf="viewableAttachment" [src]="
          assetsRoot + viewableAttachment.url + '/' + viewableAttachment.mime
        " alt="" />
    </div>

    <div class="pdf-file" *ngIf="viewableAttachment.type === 'pdf'">
      <ngx-extended-pdf-viewer [src]="
          assetsRoot + viewableAttachment.url + '/' + viewableAttachment.mime
        " height="80vh">
      </ngx-extended-pdf-viewer>
    </div>
  </app-modal>
</ng-template>
<!-- open Attachment Modal -->