<div class="row g-3 mb-4 align-items-center justify-content-between">
  <div class="col-auto">
    <h1 class="app-page-title mb-0 text-muted">Report Templates</h1>
  </div>

  <div class="col-auto">
    <div class="page-utilities">
      <div class="
          row
          g-2
          justify-content-start justify-content-md-end
          align-items-center
        ">
        <div class="col-auto">
          <div class="table-search-form row gx-1 align-items-center">
            <div class="col-auto">
              <button (click)="back()" type="submit" class="btn btn-primary">
                <i class="bi bi-arrow-left"></i>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mx-0">
  <div class="d-flex w-100">
    <div class="app-card app-card-orders-table shadow-sm mb-3 w-100">
      <div class="app-card-body">
        <div class="d-flex justify-content-between align-items-center">
          <!-- search -->
          <form action="" class="search-bar">
            <div class="position-relative">
              <input name="search" type="text" class="form-control form form-control-light" [(ngModel)]="searchFilter"
                placeholder="Search ..." />
              <span class="bi bi-search"></span>
            </div>
          </form>
          <!-- search -->

          <!-- actions -->
          <div class="">
            <button (click)="gotoCreate()" type="submit" class="btn btn-sm btn-primary">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
          <!-- actions -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div *ngIf="!loading" class="col-lg-12">
    <div *ngIf="reportTemplates.length > 0" class="table-card shadow-sm">
      <div class="table-card-body">
        <div class="table-responsive mt-3">
          <table class="table themed-table table-sticky-column themed-table-plain">
            <thead class="table-light">
              <tr>
                <th class="cell" style="max-width: 20px !important;">
                  <div class="cell-border">
                    <span>#</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Description</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Created At</span>
                  </div>
                </th>
                <th class="cell text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let normal of activePageList; let i = index">
                <td class="cell">
                  {{ i + 1 }}
                </td>
                <td class="cell">{{ normal.description }}</td>
                <td class="cell">
                  {{ normal.createdAt | customdateformat: "toDateTime" }}
                </td>
                <td class="cell text-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <span (click)="editNormal(normal.id)" class="mr-4 action-btn">
                      <i class="bi bi-eye text-primary"></i>
                    </span>
                    <span (click)="deleteNormals(normal.id)" class="action-btn">
                      <i class="bi bi-trash text-danger"></i>
                    </span>
                  </div>
                </td>
              </tr>
              <tr *ngIf="reportTemplates.length === 0">
                <td colspan="7">
                  <div class="d-block text-center" style="margin: 4rem">
                    <img src="assets/images/background/empty.svg" alt="" width="250px" />
                  </div>
                  <span class="d-block text-center">Empty List</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="reportTemplates.length === 0">
      <app-empty-placeholder></app-empty-placeholder>
    </div>
  </div>

  <div *ngIf="loading" class="col-12 col-lg-12 text-center">
    <app-loading-placeholder></app-loading-placeholder>
  </div>

  <div class="col-lg-12 mt-3">
    <app-pagination [pagesList]="
        reportTemplates
          | filter: filterList:searchFilter:false
          | paginator: elementsPerPage
      " (onPageChange)="paginatorEvent($event)">
    </app-pagination>
  </div>
</div>