<div [ngClass]="{'d-none': !toggleDisplay, 'd-flex': toggleDisplay}"
  class="alert custom-alert-plus alert-primary d-flex w-100 align-items-center justify-content-center mb-0" role="alert">
  <div class="loadingio-spinner-double-ring-et8w8v0s9an">
    <div class="ldio-p10lyfp1lwt">
      <div></div>
      <div></div>
      <div>
        <div></div>
      </div>
      <div>
        <div></div>
      </div>
    </div>
  </div>
  <strong style="font-size: 12px;" class="text-truncate">{{ progressNote }}</strong>
</div>