<h1 class="app-page-title text-muted">Dashboard</h1>

<!-- new dashboard -->
<div class="row">
  <div class="col-xl-12 dashboard-card">
    <!-- card summary -->
    <app-summary-cards></app-summary-cards>
    <!-- card summary -->

    <!-- distribution cards -->
    <app-distrbution-cards></app-distrbution-cards>
    <!-- distribution cards -->

    <!-- medical center studies -->
    <app-inprogress></app-inprogress>
    <!-- medical center studies -->
  </div>
</div>