import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from '../services/directives/directive.module';

import { UserLayoutComponent } from './user-layout/user-layout.component';
import { UserHeaderComponent } from './user-layout/header/user-header.component';
import { UserSidebarComponent } from './user-layout/sidebar/user-sidebar.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AdminSidebarComponent } from './admin-layout/sidebar/admin-sidebar.component';
import { AdminHeaderComponent } from './admin-layout/header/admin-header.component';
import { AlertComponent } from '../components/alerts/alert.component';
import { ChatNotificationComponent } from './admin-layout/header/chat-notification/chat-notification.component';
import { SystemNotificationComponent } from './admin-layout/header/system-notification/system-notification.component';

import { WebsiteLayoutComponent } from './website-layout/website-layout.component';
import { WebHeaderComponent } from './website-layout/header/web-header.component';
import { WebFooterComponent } from './website-layout/footer/web-footer.component';
import { OffcanvasComponent } from './admin-layout/offcanvas/offcanvas.component';
import { DocNotificationComponent } from '../pages/doc/doc-notification/doc-notification.component';
import { HosNotificationComponent } from '../pages/hos/hos-notifications/hos-notification.component';
import { BillableReportComponent } from './admin-layout/billable-report/billable-report.component';
import { ComponentsModule } from '../components/components.module';
import { BillableReportService } from './admin-layout/billable-report/billable-report.service';
@NgModule({
  declarations: [
    UserHeaderComponent,
    UserSidebarComponent,
    UserLayoutComponent,
    ChatNotificationComponent,
    SystemNotificationComponent,
    BillableReportComponent,

    AdminLayoutComponent,
    AdminSidebarComponent,
    AdminHeaderComponent,
    OffcanvasComponent,
    AlertComponent,

    WebsiteLayoutComponent,
    WebHeaderComponent,
    WebFooterComponent,

    DocNotificationComponent,
    HosNotificationComponent,
  ],
  imports: [CommonModule, RouterModule, DirectiveModule, ComponentsModule],
  exports: [DirectiveModule],
  providers: [],
})
export class LayoutModule { }
