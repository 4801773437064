import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';

@Injectable({
    providedIn: 'root'
})
export class DistributionService {

    constructor(
        public apiConnect: ApiConnectionService,
        public alertService: AlertService
    ) { }

    getDistribution(onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('GET', 'DashboardPriorityDistribution', undefined, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(err, true);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    getWeeklyCases(onGet: (res: any, status: boolean) => void): void{
        this.apiConnect.callAPI('GET', 'DashboardWeeklyDistribution', undefined, undefined, undefined)
        .subscribe(
            (res: any) => {
                onGet(res.data, true);
            },
            (err: any) => {
                onGet(err, true);
                this.alertService.setAlert({
                    type: 'danger',
                    title: 'Error',
                    message: err.message
                });
            }
        )
    }
}