<div class="app app-login p-0">
    <div class="row g-0 app-auth-wrapper">
        <div class="col-12 col-md-7 col-lg-6 auth-main-col text-center p-5 position-relative">

            <a [routerLink]="['/']" href="javascript:void(0);" class="text-primary position-absolute back-home"
                style="right: 10px; top: 10px">
                <em class="bi bi-house"></em>
            </a>

            <div class="d-flex flex-column align-content-end">
                <!-- recovery form -->
                <div *ngIf="!pageMode" class="app-auth-body mx-auto">
                    <div class="app-auth-branding mb-4">
                        <a class="app-logo" href="index.html">
                            <img class="logo-icon" src="assets/images/access-center.png" alt="logo" /></a>
                    </div>

                    <h2 class="auth-heading text-center mb-5 text-muted">Password Reset</h2>

                    <div class="auth-intro mb-4 text-center">
                        Enter your email address below. We'll email you a link to a
                        page where you can easily create a new password.
                    </div>

                    <div class="auth-form-container text-left">
                        <form class="auth-form login-form" [formGroup]="recoverForm">
                            <div class="email mb-3">
                                <label class="sr-only" for="recovery-email">Email</label>
                                <input id="recovery-email" [formControl]="customFormControl('email', recoverForm)"
                                    name="recovery-email" type="email" class="form-control signin-email"
                                    placeholder="Recovery Email" required="required" />
                            </div>

                            <div class="text-center">
                                <button (click)="submitRecoveryEmail()" type="submit"
                                    class="btn app-btn-primary btn-block theme-btn mx-auto">
                                    <div class="text-center">
                                        <span *ngIf="!loading">Reset Password</span>
                                        <div *ngIf="loading" class="spinner-border spinner-border-sm text-white"
                                            role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </button>

                                <div class="mt-4">
                                    <span style="font-size: 18px;" *ngIf="recoverMessage"
                                        [ngClass]="{'text-warning': recoverStatus, 'text-danger': !recoverStatus}">
                                        {{ recoverMessage }}
                                    </span>
                                </div>
                            </div>
                        </form>

                        <div class="auth-option text-center" style="font-size: 16px;">
                            <a class="app-link" href="javascript:void(0);" [routerLink]="['/auth/login']">Log in</a>
                        </div>
                    </div>
                </div>
                <!-- recovery form -->

                <!-- new password form -->
                <div *ngIf="pageMode && linkStatus" class="app-auth-body mx-auto">
                    <div class="app-auth-branding mb-4">
                        <a class="app-logo" href="index.html">
                            <img class="logo-icon" src="assets/images/access-center.png" alt="logo" /></a>
                    </div>

                    <h2 class="auth-heading text-center mb-5 text-muted">Change Password </h2>

                    <div class="auth-intro mb-4 text-center">
                        Enter your new password, confirm it and finally submit.
                    </div>

                    <div class="auth-form-container text-left">
                        <form class="auth-form login-form" [formGroup]="updateForm">
                            <div class="email mb-3">
                                <app-input [fieldType]="'password'" [fieldId]="'newPassword'"
                                    [control]="customFormControl('newPassword', updateForm)">
                                </app-input>
                            </div>

                            <div class="email mb-3">
                                <app-input [fieldType]="'password'" [fieldId]="'confirmPassword'"
                                    [control]="customFormControl('confirmPassword', updateForm)">
                                </app-input>
                            </div>

                            <div class="text-center">
                                <button [disabled]="updateForm.invalid" (click)="submitNewPassword()" type="submit"
                                    class="btn app-btn-primary btn-block theme-btn mx-auto">
                                    <div class="text-center">
                                        <span *ngIf="!submitting">Submit</span>
                                        <div *ngIf="submitting" class="spinner-border spinner-border-sm text-white"
                                            role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </button>

                                <div class="mt-4">
                                    <span *ngIf="recoverMessage" class="text-warning">
                                        {{ recoverMessage }}
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div *ngIf="pageMode && !linkStatus">
                    <p style="font-size: 20px;" class="text-warning">{{ linkMessage }}</p>
                    <div class="mt-3 d-block text-center">
                        <button (click)="back()" class="btn btn-link">
                            <em class="bi bi-arrow-left ml-2"></em>
                            Get Another
                        </button>
                    </div>
                </div>
                <!-- new password form -->
            </div>
        </div>

        <div class="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
            <div class="auth-background-holder"></div>
            <div class="auth-background-mask"></div>
            <div class="auth-background-overlay p-3 p-lg-5">
                <div class="d-flex flex-column align-content-end h-100">
                    <div class="h-100"></div>
                    <!-- <div class="overlay-content p-3 p-lg-4 rounded">
                        <h5 class="mb-3 overlay-title text-danger">Development Mode</h5>
                        <div>
                            {{ settingService.getBusinessName() }} is currently under developement, coming soon ...
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
