import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from '../base-form/base-form.component';
import { applyMixins } from '../../mixin/mixin';
import { FormControl } from '@angular/forms';
import { SettingsService } from '../../../services/settings/settings.service';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent extends BaseClass implements OnInit, DoCheck {

  @Input() fieldId!: string | null;
  @Input() control!: FormControl;
  @Input() singleDate = true;
  @Input() readOnly: boolean = true;
  @Input() minDate: Date | undefined = undefined;
  @Input() placeHolder: string | null = null;
  @Input() required = false;
  @Input() placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';
  @Input() borderClass: string = 'none';
  @Input() marginClass: string = '';
  fakeControl: FormControl = new FormControl(null);

  constructor(public settings: SettingsService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.singleDate && this.control.value) {
      this.fakeControl.setValue(new Date(this.control.value), { emitEvent: false });
    }

    if (!this.singleDate && this.control.value.length > 0) {
      this.fakeControl.setValue([new Date(this.control.value[0]), new Date(this.control.value[1])], { emitEvent: false });
    }

    this.control.valueChanges.forEach(val => {

      // reset controls and form field
      if (Array.isArray(val)) {
        if (val.length <= 0) {
          this.fakeControl.setValue(null, { emitEvent: false })
        }
      } else {
        if (val === null) {
          this.fakeControl.setValue(null, { emitEvent: false })
        }
      }
    });

  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngDoCheck(): void {
    super.ngDoCheck();
    if (this.singleDate && this.control.value) {
      this.fakeControl.setValue(new Date(this.control.value), { emitEvent: false });
    }
  }

  onPickDate($event: any): void {
    if ($event !== null) {
      if (!Array.isArray($event)) {
        this.control.setValue(this.settings.formatDate($event));
        this.fakeControl.setValue(new Date(this.control.value));
      } else {
        this.control.setValue([this.settings.formatDate($event[0]), this.settings.formatDate($event[1])]);
      }
    }
  }
}
