<div class="d-flex align-items-center">
  <label *ngIf="fieldId" [for]="fieldId" class="label text-muted block">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>

  <div *ngIf="loading" class="spinner-border text-primary spinner-border-sm ml-auto" role="status"></div>
  <span *ngIf="loading" class="text-primary ml-1">Loading editor...</span>
</div>
<editor [formControl]="control" [init]="config"></editor>
<app-form-validation [validationErrors]="validationErrors"></app-form-validation>