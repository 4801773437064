<footer class="footer_detail">
    <div class="container">
        <div class="row pt-5 pb-5">
            <div class="col-lg-12">
                <div class="text-center">
                    <img src="assets/images/access-white.png" class="mx-auto img-fluid d-block footer_logo" alt="">
                </div>
                <div class="footer_menu">
                    <ul class="mb-0 list-inline text-center mt-4">
                        <li class="list-inline-item mr-0">
                            <a href="#contact" class="text-primary">
                                Contact Us: +256 760 200 955 / +256 200 925 718
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="fot_bor"></div>
        <div class="row pt-4 pb-4">
            <div class="col-lg-12">
                <div class="float-left float_none">
                    <p class="copy-rights text-primary mb-0">2021 © {{ settingService.getBusinessName() }} .</p>
                </div>
                <div class="float-right float_none">
                    <ul class="list-inline fot_social mb-0">
                        <li class="list-inline-item">
                            <a href="https://www.facebook.com/Access-Teleradiology-104801435330898" class="social-icon text-primary">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://twitter.com/AccessTelerad" class="social-icon text-primary">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://www.linkedin.com/in/access-teleradiology-3a47b8223" class="social-icon text-primary">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="mailto:info@accessteleradiology.com" class="social-icon text-primary">
                                <i class="fas fa-envelope"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>