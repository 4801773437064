import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { StudiesService } from '../../studies/studies.service';
import { environment } from 'src/environments/environment.prod';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

class BaseClass {}
interface BaseClass extends ModalComponent {}
applyMixins(BaseClass, [ModalComponent]);

@Component({
  selector: 'app-study-details',
  templateUrl: './study-details.component.html',
  styleUrls: ['./study-details.component.scss'],
})
export class StudyDetailsComponent extends BaseClass implements OnInit {
  studyId!: number;
  studyDetails: any;
  viewableAttachment: any;
  assetsRoot = environment.imageUrl;

  gettingDetails = false;
  removingReviewer = false;
  studyReviews: any[] = [];

  viewAttachmentSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  viewAttachmentObservabale: Observable<any> =
    this.viewAttachmentSubject.asObservable();

  constructor(
    public route: ActivatedRoute,
    public modalService: BsModalService,
    public studyService: StudiesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((val) => {
      if (val) {
        this.studyId = val.studyId;
        this.getStudyDetails();
        this.getStudyReviews();
      }
    });

    this.viewAttachmentObservabale.subscribe({
      next: (data) => {
        this.viewableAttachment = data;
        console.log(data);
      },
    });
  }

  getStudyDetails(): void {
    this.gettingDetails = true;
    this.studyService.getStudyDetails(this.studyId, (res, status) => {
      this.gettingDetails = false;
      if (status) {
        this.studyDetails = res;
      }
    });
  }

  getStudyReviews(): void {
    this.gettingDetails = true;
    this.studyService.getStudyReviews(this.studyId, (res, status) => {
      this.gettingDetails = false;
      if (status) {
        this.studyReviews = res;
      }
    });
  }

  getAge(date: number): number {
    return Math.round(
      (new Date().getTime() - date) / (365 * 24 * 60 * 60 * 1000)
    );
  }

  openAttachment(file: any, template: TemplateRef<any>): void {
    this.viewAttachmentSubject.next(file);
    this.modalService.show(template);
  }

  removeReviewer(id: number): void {
    this.removingReviewer = true;
    this.studyService.removeReviewer(
      { id: this.studyDetails?.id, reviewerId: id },
      (res, status) => {
        this.removingReviewer = false;
        if (status) {
          this.getStudyDetails();
        }
      }
    );
  }
}
