import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ExportXlsxComponent } from 'src/app/components/export-xlsx/export-xlsx.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { BillableReportService } from './billable-report.service';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass
  extends ModalComponent, ExportXlsxComponent { }
applyMixins(BaseClass, [ModalComponent, ExportXlsxComponent]);

@Component({
  selector: 'app-billable-report',
  templateUrl: './billable-report.component.html',
  styleUrls: ['./billable-report.component.scss']
})
export class BillableReportComponent extends BaseClass implements OnInit {

  clientList = [];
  downloading = false;
  reportForm!: FormGroup;

  clientsPredicate: object = {
    method1: (obj: any) => obj.id,
    method2: (obj: any) => [obj.name].join(' '),
  };

  constructor(
    public modalService: BsModalService,
    public billableReportService: BillableReportService
  ) {
    super();

    this.reportForm = new FormGroup({
      facility: new FormControl('', [Validators.required]),
      dateRange: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.getClients();
  }

  customFormControl(name: string): FormControl {
    return this.reportForm.get(name) as FormControl;
  }

  getClients(): void {
    this.billableReportService.getMedicalCenters((res, status) => {
      if (status) {
        this.clientList = res;
      }
    });
  }

  getBillableReport(): void {
    if (this.reportForm.valid) {
      this.downloading = true;
      const { dateRange, facility } = this.reportForm.value;
      this.billableReportService.getBillableReport({ facility, startDate: dateRange[0], endDate: dateRange[1] }, (res, status) => {
        this.downloading = false;
        if (status) {
          const blob = this.convertBase64xlsxToBlob(res.base64.split(',')[1]);
          this.exportXlsx(blob, res.fileName);
        }
      });
    }
  }

  closeModal(): void {
    super.closeModal();
    this.reportForm.reset();
  }

}
