import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';

@Injectable({
    providedIn: 'root'
})
export class RadiologistService {

    constructor(
        public apiConnect: ApiConnectionService,
        public alertService: AlertService
    ) { }

    getRadiologist(onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('GET', 'Radiologist', undefined, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    saveRadiologist(form: any, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('POST', 'Radiologist', form, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    updateRadiologist(form: any, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('PUT', 'Radiologist', form, undefined, form.id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    activateRadiologist(id: number, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('PUT', 'ActivateRadiologist', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    deactivateRadiologist(id: number, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('PUT', 'DeActivateRadiologist', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    deleteRadiologist(id: number, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('DELETE', 'Radiologist', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }
}