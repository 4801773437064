import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { StudyService } from '../../studies/study.service';
import { environment } from 'src/environments/environment.prod';
import { ActivatedRoute } from '@angular/router';

class BaseClass {}
interface BaseClass extends ModalComponent {}
applyMixins(BaseClass, [ModalComponent]);

@Component({
  selector: 'app-medical-study-attachment',
  templateUrl: './study-attachment.component.html',
  styleUrls: ['./study-attachment.component.scss'],
})
export class StudyAttachmentComponent extends BaseClass implements OnInit {
  studyDetails: any;
  attachmentForm!: FormGroup;
  updateAttachmentBtn: object;
  viewableAttachment: any | null;

  uploading = false;
  getting = false;
  assetsRoot = environment.imageUrl;

  constructor(
    public route: ActivatedRoute,
    public modalService: BsModalService,
    public studyService: StudyService
  ) {
    super();
    this.updateAttachmentBtn = {
      method: () => this.uploadAttachments(),
      text: 'upload',
    };

    this.attachmentForm = new FormGroup({
      files: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((val) => {
      if (val) {
        this.getStudyDetails(+val.studyId);
      }
    });
  }

  customFormControl(controlName: string): FormControl {
    return this.attachmentForm.get(controlName) as FormControl;
  }

  getStudyDetails(id: number): void {
    this.getting = true;
    this.studyService.getStudyDetails(id, (res, status) => {
      if (status) {
        this.getting = false;
        this.studyDetails = res;
      }
    });
  }

  uploadAttachments(): void {
    this.uploading = true;
    this.studyService.uploadAttachments(
      this.attachmentForm.value,
      this.studyDetails.id,
      (res, status) => {
        this.uploading = false;
        this.getStudyDetails(this.studyDetails.id);
        this.closeModal();
      }
    );
  }

  openUploadAttachment(form: TemplateRef<any>): void {
    this.modalService.show(form);
  }

  openAttachment(attachment: any, modal: TemplateRef<any>): void {
    this.viewableAttachment = attachment;
    this.modalService.show(modal);
  }

  deleteAttachments(id: number): void {
    this.studyService.removeAttachment(id, (res, status) => {
      if (status) {
        this.getStudyDetails(this.studyDetails.id);
      }
    });
  }
}
