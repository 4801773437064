import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OffcanvasService } from 'src/app/services/offcanvas/offcanvas.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
import { NotificationQuery } from 'src/app/store/notification/notification.query';

@Component({
  selector: 'app-system-notification',
  templateUrl: './system-notification.component.html',
  styleUrls: ['./system-notification.component.scss'],
})
export class SystemNotificationComponent implements OnInit {
  constructor(
    private offcanvasService: OffcanvasService,
    private sessionService: SessionStorageService,
    private notificationQuery: NotificationQuery
  ) {}

  ngOnInit(): void {}

  open(): void {
    if (this.sessionService.getUserApplicationType() === 'DOCTOR') {
      this.offcanvasService.open('DocNotification');
    } else {
      this.offcanvasService.open('HosNotification');
    }
  }

  getUnreadCount(): Observable<number> {
    return this.notificationQuery.unreadCount();
  }

  formatCount(count: number | null): string {
    if (count === null) return '0';
    if (count > 9) {
      return '9+';
    } else {
      return count.toString();
    }
  }
}
