import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { BaseFormComponent } from '../base-form/base-form.component';
import { applyMixins } from '../../mixin/mixin';
import { FormControl } from '@angular/forms';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends BaseClass implements OnInit, DoCheck {

  @Input() fieldId!: string | null;
  @Input() control!: FormControl;

  @Input() fieldType = '';
  @Input() disabled = false;
  @Input() required = false;
  @Input() readOnly = false;
  @Input() formControlSm = true;
  @Input() minNo: number | null = null;
  @Input() maxNo: number | null = null;
  @Input() placeHolder: string | null = null;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngDoCheck(): void {
    super.ngDoCheck();
  }
}
