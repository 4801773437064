import { Component, Input, OnInit } from '@angular/core';
import * as ApexCharts from 'apexcharts';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.scss']
})
export class ColumnChartComponent implements OnInit {

  @Input() strokeColor?: any[];
  @Input() chartId?: string;
  @Input() chartHeight: number = 250;
  @Input() chartGroup?: string;
  @Input() chartData: any[] = [];
  @Input() xaxisCategories?: any[];

  @Input() seriesDataSubject?: BehaviorSubject<[]> = new BehaviorSubject([]);
  @Input() xaxisSubject?: BehaviorSubject<[]> = new BehaviorSubject([]);

  chartOptions: any;
  chartPlaceHolder: any;

  constructor() { }

  ngOnInit(): void {
    this.setChartOptions();
    setTimeout(() => {
      this.chartPlaceHolder = new ApexCharts(document.querySelector('#' + this.chartId), this.chartOptions);
      this.chartPlaceHolder.render();

      // update data series
      this.seriesDataSubject?.subscribe(val => {
        this.chartPlaceHolder.updateSeries(val);
      });

      // update labels on xaxis
      this.xaxisSubject?.subscribe(val => {
        this.chartPlaceHolder.updateOptions({
          xaxis: {
            categories: val,
            labels: {
              show: true,
              rotate: -45,
              trim: true,
              maxHeight: 80,
              style: {
                fontSize: '12px',
                cssClass: 'cut-text'
              }
            }
          }
        })
      })
    });
  }

  setChartOptions(): void {
    this.chartOptions = {
      chart: {
        id: this.chartId,
        group: this.chartGroup,
        type: 'bar',
        height: this.chartHeight
      },
      series: this.chartData,
      plotOptions: {
        bar: {
          borderRadius: 10,
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        }
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: false,
        curve: 'smooth',
        width: 2
      },
      colors: [...this.strokeColor || ''],
      tooltip: {
        shared: true,
        intersect: false
      },
      yaxis: {
        labels: {
          minWidth: 5
        }
      },
      xaxis: {
        categories: this.xaxisCategories,
        labels: {
          show: true,
          rotateAlways: false,
          trim: true
        }
      }
    }
  }
}
