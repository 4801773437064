import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SessionStorageService } from '../session/session-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token!: string | null;

  constructor(
    private sessionService: SessionStorageService
  ) {
    this.token = this.sessionService.getToken();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    let authReq = req;

    if (!this.token) {
      this.token = sessionStorage.getItem('token');
    }

    if (this.token !== null) {
      authReq = req.clone({
        headers: req.headers.set('Authorization', this.token),
      });
    }
    return next.handle(authReq);
  }
}

export const jwtInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
];
