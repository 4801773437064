import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './webiste.routes';

import { LandingComponent } from './landing/landing.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ComponentsModule } from 'src/app/components/components.module';
import { ImageSliderComponent } from './landing/image-slider/image-slider.component';
import { ContactUsComponent } from './landing/contact-us/contact-us.component';
import { OurServicesComponent } from './landing/our-services/our-services.component';
import { AboutUsComponent } from './landing/about-us/about-us.component';

@NgModule({
    declarations: [
        LandingComponent,
        ImageSliderComponent,
        ContactUsComponent,
        OurServicesComponent,
        AboutUsComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ComponentsModule,
        NgxExtendedPdfViewerModule,
    ],
    exports: [
        RouterModule,
        RouterModule,
        ComponentsModule
    ],
    providers: []
})
export class WebsiteModule { }
