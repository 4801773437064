import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InprogressService } from './inprogress.service';

@Component({
    selector: 'app-inprogress',
    templateUrl: './inprogress.component.html',
    styleUrls: ['./inprogress.component.scss']
})
export class InprogressComponent implements OnInit {

    inProgressList?: any[];

    constructor(
        public router: Router,
        public inprogressService: InprogressService
    ) {
        this.inProgressList = [];
    }

    ngOnInit(): void {
        this.getInprogressStudies();
    }

    getInprogressStudies(): void {
        this.inprogressService.getInprogressStudies((res, status) => {
            if (status) {
                this.inProgressList = res;
            }
        });
    }

    openStudy(id: number): void {
        this.router.navigate(['/app/hos/study-details'], {
            queryParams: { page: 'study-details', studyId: id },
        });
    }
}
