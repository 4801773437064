import { Injectable } from '@angular/core';
import { CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/entities/global/user.interface';
import { SessionStorageService } from '../session/session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class CanLoadGuard implements CanLoad {

    userApplicationType: string | undefined = '';

    constructor(
        private sessionService: SessionStorageService
    ) {
        this.userApplicationType = this.sessionService.getUserApplicationType();
    }

    canLoad(
        route: Route
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (route.data?.routeType === this.userApplicationType) {
            return true;
        } else {
            return false;
        }
    }
}
