<div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
    <div class="carousel-inner">
        <div *ngFor="let img of imageList; let i = index" class="carousel-item h-100vh" [ngClass]="{'active': i === 0}"
            [ngStyle]="{'background': 'url(' + img.image +')'}">
            <div class="bg-overlay"></div>
            <div class="home-table">
                <div class="home-table-center">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <div class="text-white text-center">
                                    <h1 class="header_title mb-0 mt-4 text-white">
                                        Instant Reporting
                                    </h1>
                                    <p class="header_subtitle pt-4 text-white mx-auto">
                                        We are a network of qualified and
                                        experienced radiologists partnering with
                                        health facilities around Africa to deliver fast,
                                        accurate radiology reports within minutes
                                        of receiving a patient’s medical image.
                                    </p>
                                    <div class="header_btn">
                                        <a href="#contact" class="btn btn-custom btn-rounded mt-4">
                                            Make Inquiry <em class="bi bi-arrow-right ml-1"></em>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
        <em class="bi bi-chevron-left" style="font-size: 20px;" aria-hidden="true"></em>
        <span class="sr-only">Previous</span>
    </a>

    <a id="right-control" class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
        <em class="bi bi-chevron-right" style="font-size: 20px;" aria-hidden="true"></em>
        <span class="sr-only">Next</span>
    </a>
</div>