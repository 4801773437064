<div class="row g-3 mb-4 align-items-center justify-content-between">
  <div class="col-auto">
    <h1 class="app-page-title mb-0 text-muted">Clients</h1>
  </div>

  <div class="col-auto">
    <div class="page-utilities">
      <div class="
          row
          g-2
          justify-content-start justify-content-md-end
          align-items-center
        ">
        <div class="col-auto">
          <div class="table-search-form row gx-1 align-items-center">
            <div class="col-auto">
              <button (click)="back()" type="submit" class="btn btn-primary">
                <i class="bi bi-arrow-left"></i>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mx-0">
  <div class="d-flex w-100">
    <div class="app-card app-card-orders-table shadow-sm mb-3 w-100">
      <div class="app-card-body">
        <div class="d-flex justify-content-between align-items-center">
          <!-- search -->
          <form action="" class="search-bar">
            <div class="position-relative">
              <input name="search" type="text" class="form-control form form-control-light" [(ngModel)]="searchFilter"
                placeholder="Search ..." />
              <span class="bi bi-search"></span>
            </div>
          </form>
          <!-- search -->

          <!-- actions -->
          <div class="">
            <button (click)="openModal(newClientModal)" type="submit" class="btn btn-sm btn-primary">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
          <!-- actions -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div *ngIf="!gettingClients" class="col-lg-12">
    <div *ngIf="clients.length > 0" class="table-card shadow-sm">
      <div class="table-card-body">
        <div class="table-responsive mt-3">
          <table class="table themed-table table-sticky-column themed-table-plain">
            <thead class="table-light">
              <tr>
                <th class="cell">
                  <div class="cell-border">
                    <span>Name</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Email</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Phone</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Address</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Status</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Created At</span>
                  </div>
                </th>
                <th class="cell text-center">
                  <div class="">
                    <span>Actions</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let client of activePageList">
                <td class="cell">
                  <a href="javascript:void(0);" class="avatar">
                    <i class="bi bi-bank text-white"></i>
                  </a>
                  <h2>
                    <a href="javascript:void(0);">{{ client.name }}</a>
                  </h2>
                </td>
                <td class="cell">{{ client.email }}</td>
                <td class="cell">{{ client.phoneNumber }}</td>
                <td class="cell">{{ client.location }}</td>
                <td class="cell">
                  <div class="dropdown action-label">
                    <a class="btn btn-white btn-sm rounded dropdown-toggle" href="#" data-toggle="dropdown" [ngClass]="{
                        'text-success': client.status === 'ACTIVE',
                        'text-info': client.status === 'CREATED',
                        'text-danger': client.status === 'IN-ACTIVE'
                      }" aria-expanded="false" style="text-transform: lowercase">
                      <i class="bi bi-record-circle"></i>
                      {{ client.status }}
                      <i class="caret"></i>
                    </a>
                    <ul class="dropdown-menu pull-right">
                      <li>
                        <a href="javascript:void(0);" (click)="activateClient(client.id)">
                          <i class="bi bi-record-circle text-success"></i>
                          Activate
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" (click)="deActivateClient(client.id)">
                          <i class="bi bi-record-circle text-danger"></i>
                          De-activate
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
                <td class="cell">
                  {{ client.createdAt | customdateformat: "toDateTime" }}
                </td>
                <td class="cell text-center">
                  <div class="btn-group dropdown">
                    <a href="javascript:void(0)" class="
                        table-action-btn
                        dropdown-toggle
                        arrow-none
                        btn btn-light btn-xs
                      " data-toggle="dropdown" aria-expanded="false">
                      <i class="bi bi-three-dots"></i>
                    </a>
                    <div class="dropdown-menu" data-popper-placement="bottom-end">
                      <a (click)="editClient(client, editClientModal)" class="dropdown-item" href="javascript:void(0);"
                        title="Edit" data-toggle="modal">
                        <i class="bi bi-pencil mr-2 text-muted"></i> Edit
                      </a>
                      <a (click)="clientAETs(client, aetModal)" class="dropdown-item" href="javascript:void(0);"
                        title="Show AET" data-toggle="modal">
                        <i class="bi bi-dpad-fill mr-2 text-muted"></i> AETs
                      </a>
                      <a (click)="deleteClient(client.id)" class="dropdown-item" href="javascript:void(0);"
                        title="Delete" data-toggle="modal">
                        <i class="bi bi-trash mr-2 text-muted"></i> Delete
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="clients.length === 0">
                <td colspan="7">
                  <div class="d-block text-center" style="margin: 4rem">
                    <img src="assets/images/background/empty.svg" alt="" width="250px" />
                  </div>
                  <span class="d-block text-center">Empty List</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="clients.length === 0">
      <app-empty-placeholder></app-empty-placeholder>
    </div>
  </div>

  <div *ngIf="gettingClients" class="col-12 col-lg-12 text-center">
    <app-loading-placeholder></app-loading-placeholder>
  </div>

  <div class="col-lg-12 mt-3">
    <app-pagination [pagesList]="
        clients
          | filter: filterList:searchFilter:false
          | paginator: elementsPerPage
      " (onPageChange)="paginatorEvent($event)">
    </app-pagination>
  </div>
</div>

<!-- new client -->
<ng-template #newClientModal>
  <app-modal [modalActionButton]="saveClientBtn" [modalTitle]="'Create Radiologist'" [condition]="newClient.invalid"
    [loading]="saving">
    <form action="" [formGroup]="newClient">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <app-input [fieldId]="'name'" [required]="true" [control]="customFormControl('name', newClient)"></app-input>
        </div>

        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'email'" [required]="true" [control]="customFormControl('email', newClient)">
          </app-input>
        </div>
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'phone'" [control]="customFormControl('phoneNumber', newClient)"></app-input>
        </div>

        <div class="col-lg-12 col-md-12">
          <app-input [fieldId]="'address'" [control]="customFormControl('location', newClient)"></app-input>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- new client -->

<!-- edit client -->
<ng-template #editClientModal>
  <app-modal [modalActionButton]="updateClientBtn" [modalTitle]="'Update Client'" [condition]="editClientForm.invalid"
    [loading]="updating">
    <form action="" [formGroup]="editClientForm">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <app-input [fieldId]="'name'" [required]="true" [control]="customFormControl('name', editClientForm)">
          </app-input>
        </div>

        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'email'" [required]="true" [control]="customFormControl('email', editClientForm)">
          </app-input>
        </div>
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'phone'" [control]="customFormControl('phoneNumber', editClientForm)"></app-input>
        </div>

        <div class="col-lg-12 col-md-12">
          <app-input [fieldId]="'address'" [control]="customFormControl('location', editClientForm)"></app-input>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- edit client -->

<!-- aet client -->
<ng-template #aetModal>
  <app-modal [isModalBtn]="false" [modalActionButton]="" [modalTitle]="'Medical Center AET'">
    <form action="" [formGroup]="editClientForm">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="input-group">
            <input [formControl]="customFormControl('name', addAETForm)" type="text" placeholder="name"
              class="form-control">
            <input [formControl]="customFormControl('description', addAETForm)" type="text" placeholder="description"
              class="form-control">
            <div (click)="addAET()" [ngClass]="{'disabled': addAETForm.invalid}" class="input-group-append">
              <span class="input-group-text" id="">
                <span *ngIf="addingaet" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
                {{addingaet ? 'Loading...' : 'Add'}}
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 mt-2">
          <h6>List</h6>
          <ul class="list-group">
            <li *ngFor="let aet of clientAETList"
              class="list-group-item d-flex align-items-center justify-content-between">
              <span>{{ aet.name }}</span>
              <span (click)="removeAET(aet.id)" class="btn btn-light btn-sm">
                <em class="bi bi-trash text-danger"></em>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- aet client -->