import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { applyMixins } from '../../mixin/mixin';
import { BaseFormComponent } from '../base-form/base-form.component';
import { EditorComponent } from '@tinymce/tinymce-angular';

class BaseClass {}
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent {}
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-editor',
  templateUrl: './input-editor.component.html',
  styleUrls: ['./input-editor.component.scss'],
})
export class InputEditorComponent
  extends BaseClass
  implements OnInit, DoCheck, AfterViewInit
{
  @ViewChild(EditorComponent) editor!: EditorComponent;

  @Input() fieldId!: string | null;
  @Input() control!: FormControl;

  @Input() fieldType = '';
  @Input() editorHeight!: number;
  @Input() disabled = false;
  @Input() required = false;
  @Input() readOnly = false;
  @Input() formControlSm = true;
  @Input() minNo: number | null = null;
  @Input() maxNo: number | null = null;
  @Input() placeHolder: string | null = null;

  @Output() printEvent = new EventEmitter<any>();

  tinyMCE: any;
  loading = true;

  config!: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.config = {
      base_url: '/tinymce',
      suffix: '.min',
      height: this.editorHeight,
      menubar: 'file edit view insert format tools table help',
      plugins: [
        'print preview lists paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image code link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
      ],
      toolbar_sticky: true,
      toolbar:
        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | \
        outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | \
        insertfile image media template link anchor codesample | ltr rtl',
      image_title: true,
      automatic_uploads: true,
      file_picker_types: 'image',
      file_picker_callback: this.file_picker_callback.bind(this),
    };
  }

  ngDoCheck(): void {
    super.ngDoCheck();
  }

  ngAfterViewInit(): void {
    this.editor.onInit.subscribe((val: any) => {
      this.tinyMCE = val.editor;

      this.printEvent.emit(
        this.tinyMCE.ui.registry.getAll().buttons.print.onAction
      );

      if (this.readOnly) {
        this.tinyMCE.setMode('readonly');
      }

      this.loading = false;
    });
  }

  file_picker_callback(callback: any, value: any, meta: any): void {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.onchange = ($event: any) => {
      const file = $event.target.files[0];
      const reader: any = new FileReader();

      reader.onload = () => {
        const id = 'blobid' + new Date().getTime();
        const blobCache = this.tinyMCE.editorUpload.blobCache;
        const base64 = reader.result.split(',')[1];
        const blobInfo = blobCache.create(id, file, base64);

        blobCache.add(blobInfo);
        callback(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };

    input.click();
  }
}
