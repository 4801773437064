import { Routes } from "@angular/router";
import { PermissionGuard } from "src/app/services/guards/permission.guard";
import { ClientSettingsComponent } from "./client-settings/client-settings.component";
import { ScanTypesComponent } from "./scan-types/scan-types.component";
import { SettingsComponent } from "./settings.component";
import { UserComponent } from "./user-permission/user.component";

export const routes: Routes = [
    {
        path: '',
        component: SettingsComponent,
        children: [
            { path: '', redirectTo: 'notifications', pathMatch: 'full' },
            {
                path: 'scan-types',
                component: ScanTypesComponent
            },
            {
                path: 'user-permission',
                component: UserComponent,
                canActivate: [PermissionGuard],
                data: { roleQuery: 'Users|View' }
            },
            {
                path: 'client-settings',
                component: ClientSettingsComponent
            }
        ]
    }

];