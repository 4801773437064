<div class="modal-header">
  <h6 class="modal-title pull-left text-muted font-weight-bold">{{ modalTitle }}</h6>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>
<div class="modal-footer">
  <button (click)="closeModal()" class="btn btn-secondary btn-sm">Close</button>
  <button [hidden]="!isModalBtn" (click)="modalActionButton.method()" class="btn btn-primary btn-sm"
    [disabled]="condition">
    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {{loading ? 'Loading...' : modalActionButton.text}}
  </button>
</div>
