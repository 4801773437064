import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loading = false;
  loginForm!: FormGroup;
  loginMessage!: string | null;
  redirectUrlPath!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public settingService: SettingsService,
    private authService: AuthenticationService,
    private sessionService: SessionStorageService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl(),
      password: new FormControl(),
    });

    this.route.queryParams.subscribe((val) => {
      if (val.returnUrl) {
        this.redirectUrlPath = val.returnUrl;
      }
    });
  }

  ngOnInit(): void {}

  login(): void {
    this.loading = true;
    this.authService.login(this.loginForm.value, (res, status) => {
      this.loading = false;
      if (status) {
        this.sessionService.setToken(res.token);
        this.redirectUser(this.sessionService.getUserApplicationType());
      } else {
        this.loginMessage = res.message;
      }
    });
  }

  redirectUser(applicationType: string | undefined): void {
    if (applicationType === undefined) {
      this.loginMessage = 'Unknown Application Entity';
    }

    if (this.redirectUrlPath) {
      this.router.navigateByUrl(this.redirectUrlPath);
    } else {
      if (applicationType === 'MEDICAL_CENTER') {
        this.router.navigate(['/app/hos/dashboard']);
      } else {
        this.router.navigate(['/app/doc/dashboard']);
      }
    }
  }

  customFormControl(name: string): FormControl {
    return this.loginForm.get(name) as FormControl;
  }
}
