import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { StudyService } from './study.service';
import { StudiesService } from '../../doc/studies/studies.service';
import { ConnectionService } from 'src/app/services/websocket/connection.service';
import { SettingsService } from 'src/app/services/settings/settings.service';

class BaseClass {}
interface BaseClass
  extends ModalComponent,
    PaginationComponent,
    FilterComponent {}
applyMixins(BaseClass, [ModalComponent, PaginationComponent, FilterComponent]);

@Component({
  selector: 'app-hos-studies',
  templateUrl: './studies.component.html',
  styleUrls: ['./studies.component.scss'],
})
export class StudyArchiveComponent
  extends BaseClass
  implements OnInit, AfterViewInit, OnDestroy
{
  elementsPerPage = 10;
  activePageList!: any[];
  gettingStudies = false;

  showEntries!: FormControl;
  searchFilter!: string;
  assetsRoot = environment.imageUrl;

  studies: Array<any> = [];
  radiologists: Array<any> = [];
  openFilter = false;
  queryForm!: FormGroup;
  subscription!: Subscription;
  currentParams: any;
  searchByStudyIdForm!: FormControl;

  studiesSubscription!: Subscription;

  unFlagging = false;
  unFlagBtn = {
    method: () => this.unFlagStudy(),
    text: 'unflag',
  };

  flaggedStudyId!: number;
  flagReason: any;

  radioPredicate: object = {
    method1: (obj: any) => obj.id,
    method2: (obj: any) => [obj.user.firstName, obj.user.lastName].join(' '),
  };

  constructor(
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public studyService: StudyService,
    public modalService: BsModalService,
    public studiesService: StudiesService,
    public connectionService: ConnectionService,
    public settingService: SettingsService
  ) {
    super();
    const dates = this.settingService.getCurrentWeekDates();
    this.queryForm = new FormGroup({
      status: new FormControl('all'),
      level: new FormControl(null),
      studyDate: new FormControl([dates.first, dates.last]),
      radiologist: new FormControl(null),
      readType: new FormControl(null),
    });

    this.showEntries = new FormControl(10);
    this.filterList = ['patientName', 'studyUID', 'studyType|name', 'status'];

    this.searchByStudyIdForm = new FormControl(null, Validators.required);
  }

  ngOnInit(): void {
    this.route.queryParams.forEach((params: any) => {
      if (this.isEmpty(params)) {
        this.router.navigate(['/app/hos/studies'], {
          queryParams: { status: 'all' },
        });
      } else {
        this.prefillForm(params);
        this.getStudies(params);
      }
    });

    this.queryBuilder();

    this.showEntries.valueChanges.subscribe((val) => {
      this.elementsPerPage = val;
    });

    this.updateStudies();
  }

  updateStudies(): void {
    this.connectionService.subcribeToTopic('studies-update');

    this.studiesSubscription = this.connectionService.onMessageSub(
      this.connectionService.tranfromTopic('studies-update'),
      (data: any) => {
        this.getStudies(this.currentParams);
      }
    );
  }

  ngAfterViewInit(): void {
    const statusBtn: NodeListOf<Element> =
      document.querySelectorAll('.status-btn');
    statusBtn.forEach((btn: Element, index: number) => {
      if (
        btn.getAttribute('status-data') ===
        this.customFormControl('status').value
      ) {
        btn.classList.add('active');
      }

      btn.addEventListener('click', (ev) => {
        statusBtn.forEach((ele: Element) => {
          ele.classList.remove('active');
        });

        btn.classList.add('active');
        if (btn.classList.contains('active')) {
          this.queryForm
            .get('status')
            ?.setValue(btn.getAttribute('status-data'));
        }
      });
    });
  }

  prefillForm(param: any): void {
    for (const key in param) {
      if (Object.prototype.hasOwnProperty.call(param, key)) {
        if (this.queryForm.contains(key)) {
          if (key === 'studyDate') {
            this.queryForm
              .get(key)
              ?.setValue(
                [
                  new Date(+param['startDate']).getTime(),
                  new Date(+param['endDate']).getTime(),
                ],
                { emitEvent: false }
              );
          } else {
            this.queryForm.get(key)?.setValue(param[key], { emitEvent: false });
          }
        }
      }
    }
  }

  queryBuilder(): void {
    this.queryForm.valueChanges.forEach((form: any) => {
      let param: any = {};
      for (const key in form) {
        if (Object.prototype.hasOwnProperty.call(form, key)) {
          if (form[key] !== null) {
            if (key === 'studyDate') {
              if (form[key].length > 0) {
                param['startDate'] = form[key][0];
                param['endDate'] = form[key][1];
                param[key] = form[key];
              }
            } else {
              param[key] = form[key];
            }
          }
        }
      }

      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.router.navigate(['/app/hos/studies'], { queryParams: param });
    });
  }

  clearFilters(): void {
    this.queryForm.reset({
      status: 'all',
      studyDate: [],
      radiologist: null,
      level: null,
      readType: null,
    });
  }

  customFormControl(name: string): FormControl {
    return this.queryForm.get(name) as FormControl;
  }

  isEmpty(obj: any): boolean {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  paginatorEvent(change: any): void {
    setTimeout(() => {
      this.activePageList = change.currentChunk;
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['patientName'];
    }
  }

  openBackendFilter() {
    const filter: HTMLElement | null =
      document.getElementById('backend-filter');
    if (filter) {
      filter.classList.remove('d-none');
    }
    this.openFilter = true;
  }

  clearBackendFilter() {
    const filter: HTMLElement | null =
      document.getElementById('backend-filter');
    if (filter) {
      filter.classList.add('d-none');
    }
    this.openFilter = false;
  }

  getStudies(params: any): void {
    this.currentParams = params;
    this.gettingStudies = true;
    this.studyService.getStudies(params, (res, status) => {
      this.gettingStudies = false;
      if (status) {
        this.studies = res;
      }
    });
  }

  deleteStudy(id: number): void {
    this.studyService.deleteStudy(id, (res, status) => {
      if (status) {
        this.getStudies(this.currentParams);
      }
    });
  }

  openStudy(id: number): void {
    this.router.navigate(['/app/hos/study-details'], {
      queryParams: { page: 'study-details', studyId: id },
    });
  }

  searchStudyById(): void {
    this.router.navigate(['/app/hos/studies'], {
      queryParams: { status: 'all', studyId: this.searchByStudyIdForm.value },
    });
  }

  redictToNewStudy(): void {
    this.router.navigate(['/app/hos/create-study']);
  }

  back(): void {
    this.location.back();
  }

  openFlagMessage(template: TemplateRef<any>, study: any): void {
    this.flaggedStudyId = study.id;
    this.flagReason = study?.flagReason;
    this.modalService.show(template);
  }

  unFlagStudy(): void {
    this.unFlagging = true;
    this.studiesService.unflagStudy(this.flaggedStudyId, (res, status) => {
      this.unFlagging = false;
      if (status) {
        this.getStudies(this.currentParams);
      }
    });
  }

  ngOnDestroy(): void {
    this.studiesSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
  }
}
