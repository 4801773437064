import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-study',
  templateUrl: './single-study.component.html',
  styleUrls: ['./single-study.component.scss'],
})
export class SingleStudyComponent implements OnInit {
  studyId!: number;
  studyUID!: string;

  constructor(
    public router: Router,
    public location: Location,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.forEach((params: any) => {
      this.studyId = +params.studyId;
      this.studyUID = params.studyUID;
      this.router.navigate(['/app/doc/studies/single/study-details'], {
        queryParams: { studyId: this.studyId, studyUID: this.studyUID },
      });
    });
  }

  openWithWeasisViewer() {
    if (this.studyUID) {
      window.location.href =
        'weasis://' +
        encodeURIComponent(
          `$dicom:rs --url "${environment.weasisWebUrl}" -r ` +
            'studyUID=' +
            this.studyUID
        );
      return;
    }
  }

  back(): void {
    this.location.back();
  }
}
