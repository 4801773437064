import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { UserService } from '../user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';

import 'ace-builds';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/mode-scss';

class BaseClass {}
interface BaseClass extends ModalComponent {}
applyMixins(BaseClass, [ModalComponent]);

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent extends BaseClass implements OnInit {
  @Input() user: BehaviorSubject<any> = new BehaviorSubject(null);
  userId!: number;
  userJsonPermissions!: any;
  userPermissions!: Array<any>;
  userPermissionsForm!: FormArray;
  userPermissionGroups!: Array<any>;
  update = false;
  userDetails!: any;

  selectAllFormControl!: FormControl;
  loadingPermissions = false;

  saving = false;
  updating = false;
  borderValidationClass = 'border-success';

  savePermissionBtn: any = {
    method: () => {
      this.updateJsonPermissions(this.userJsonPermissions);
    },
    text: 'update',
  };

  constructor(
    public modalService: BsModalService,
    public apiConnect: ApiConnectionService,
    public userService: UserService
  ) {
    super();
    this.selectAllFormControl = new FormControl();
  }

  ngOnInit(): void {
    this.user.asObservable().subscribe((val) => {
      if (val) {
        this.userId = val;
        this.getUserPermissions();
      }
    });

    this.onSelectAll();
  }

  getUserPermissions(): void {
    this.loadingPermissions = true;
    this.userService.getUserDetails(this.userId, (res, status) => {
      this.loadingPermissions = false;
      if (status) {
        this.userDetails = res;
        this.userPermissions = res.permissions;
        this.setForm();
      }
    });
  }

  setForm(): void {
    this.userPermissionsForm = new FormArray([]);
    this.userPermissionGroups = [];
    this.userPermissions.forEach((group: any, index: number) => {
      this.userPermissionsForm.push(
        new FormGroup({
          all: new FormControl(group.all),
          name: new FormControl(group.name),
          description: new FormControl(group.description),
          items: new FormArray([]),
        })
      );

      this.userPermissionGroups.push(group.name);

      group.items.forEach((item: any) => {
        const temp = this.userPermissionsForm.controls[index].get(
          'items'
        ) as FormArray;
        temp.push(
          new FormGroup({
            name: new FormControl(item.name),
            access: new FormControl(item.access),
            description: new FormControl(item.description),
          })
        );
      });
    });

    this.userPermissionsForm.valueChanges.subscribe((val) => {
      if (val) {
        this.update = true;
      }
      this.updateAllGroupStatus();
    });

    this.onSetAllInGroup();
  }

  getNestForm(index: number): FormArray {
    return (this.userPermissionsForm.controls[index] as FormGroup).controls
      .items as FormArray;
  }

  getGroupControl(index: number): FormControl {
    return (this.userPermissionsForm.controls[index] as FormGroup).controls
      .all as FormControl;
  }

  getItemAccessControl(
    index1: number,
    index2: number,
    name: string
  ): FormControl {
    return (
      (
        (this.userPermissionsForm.controls[index1] as FormGroup).controls
          .items as FormArray
      ).controls[index2] as FormGroup
    ).controls[name] as FormControl;
  }

  onSelectAll(): void {
    this.selectAllFormControl.valueChanges.subscribe((val) => {
      (this.userPermissionsForm.controls as [FormGroup]).forEach(
        (group: FormGroup) => {
          group.controls['all'].setValue(val);
          ((group.controls.items as FormArray).controls as [FormGroup]).forEach(
            (item: FormGroup) => {
              item.controls['access'].setValue(val);
            }
          );
        }
      );
    });
  }

  onSetAllInGroup(): void {
    (this.userPermissionsForm.controls as [FormGroup]).forEach(
      (group: FormGroup) => {
        group.controls['all'].valueChanges.subscribe((val) => {
          ((group.controls.items as FormArray).controls as [FormGroup]).forEach(
            (item: FormGroup) => {
              item.controls['access'].setValue(val);
            }
          );
        });
      }
    );
  }

  updateAllGroupStatus(): void {
    (this.userPermissionsForm.controls as [FormGroup]).forEach(
      (group: FormGroup) => {
        const temp = (group.controls.items as FormArray).controls as [
          FormGroup
        ];
        const temp1 = temp.filter(
          (item: FormGroup) => item.controls['access'].value
        );

        if (temp.length === temp1.length) {
          group.controls['all'].setValue(true, { emitEvent: false });
        } else {
          group.controls['all'].setValue(false, { emitEvent: false });
        }
      }
    );
  }

  updatePermissions(): void {
    this.userService.updateUserPermission(
      this.userId,
      { permissions: this.userPermissionsForm.value },
      (res, status) => {
        if (status) {
          this.getUserPermissions();
          this.update = false;
          this.closeModal();
        }
      }
    );
  }

  updateJsonPermissions(jsonData: any): void {
    this.updating = true;
    this.userService.updateUserPermission(
      this.userId,
      { permissions: jsonData },
      (res, status) => {
        this.updating = false;
        if (status) {
          this.getUserPermissions();
          this.update = false;
          this.closeModal();
        }
      }
    );
  }

  cancelUpdate(): void {
    this.update = false;
    this.getUserPermissions();
  }

  stringfiyJson(data: any[]): string {
    const newData = data.map((item) => {
      const temp = Object.assign({}, item);
      return temp;
    });

    return JSON.stringify(newData, null, 2);
  }

  onRawTextChange(event: any): void {
    let temp: any;
    try {
      temp = JSON.parse(event);
      this.borderValidationClass = 'border-success';
    } catch (error) {
      temp = null;
      this.borderValidationClass = 'border-danger';
    }
    this.userJsonPermissions = temp;
  }
}
