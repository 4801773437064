import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OffcanvasService {
  state!: BehaviorSubject<{ isOpen: boolean | any; type: string }>;

  constructor() {
    this.state = new BehaviorSubject({ isOpen: false, type: '' });
  }

  open(type: string) {
    this.state.next({ isOpen: true, type });
  }

  close() {
    this.state.next({ isOpen: false, type: '' });
  }
}
