import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-web-header',
    templateUrl: './web-header.component.html',
    styleUrls: ['./web-header.component.scss']
})
export class WebHeaderComponent implements OnInit, AfterViewInit {
    constructor() { }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        this.onPageScroll();
    }

    onPageScroll(): void {
        document.addEventListener('scroll', function (event) {
            let scrollLength = window.scrollY;
            const topNav = document.getElementById('topnav');
            const light: HTMLElement | null = document.querySelector('.logo-light');
            const dark: HTMLElement | null = document.querySelector('.logo-dark');

            if (scrollLength > 50) {
                if (topNav && light && dark) {
                    topNav.classList.add('stickyadd');
                }
            } else {
                const topNav = document.getElementById('topnav');
                if (topNav && light && dark) {
                    topNav.classList.remove('stickyadd');
                }
            }
        });
    }
}
