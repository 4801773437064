<div class="chat-body">
  <div class="chat-bg">
    <ul
      class="conversation-list"
      data-simplebar="init"
      style="max-height: 300px"
    >
      <div class="simplebar-wrapper" style="margin: 0px -15px">
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer"></div>
        </div>
        <div class="simplebar-mask">
          <div
            class="simplebar-offset"
            [ngClass]="{
              'd-flex justify-content-center align-items-center':
                messagesList.length === 0
            }"
            style="right: 0px; bottom: 0px"
          >
            <div
              *ngIf="messagesList.length === 0"
              class="message-content empty"
            >
              <div class="no-messages text-center">
                <div class="mx-auto">
                  <i class="bi bi-chat-square-dots"></i>
                </div>
                <p class="text-center">
                  Seems people are shy to start the chat. Break the ice send the
                  first message.
                </p>
              </div>
            </div>

            <div
              *ngIf="messagesList.length > 0"
              id="simplebar-content"
              class="simplebar-content-wrapper"
              style="height: auto; overflow: hidden scroll; padding-top: 20px"
            >
              <div class="simplebar-content" style="padding: 0px 15px">
                <li
                  *ngFor="let message of messagesList; let i = index"
                  class="clearfix chat-message"
                  [attr.id]="'messageId' + i"
                  [ngClass]="{ odd: message.mine }"
                >
                  <div *ngIf="message.user.userImage" class="chat-avatar">
                    <img
                      [src]="
                        message.user.userImage !== null
                          ? assetsRoot + message.user.userImage.url
                          : 'assets/images/default.png'
                      "
                      class="rounded"
                    />
                    <i style="font-size: 9px">
                      {{ message.createdAt | customdateformat: "dateToTime" }}
                    </i>
                  </div>
                  <div *ngIf="message.user" class="conversation-text">
                    <div class="ctext-wrap">
                      <i
                        [ngClass]="{
                          'text-info': message.mine,
                          'text-primary': !message.mine
                        }"
                      >
                        ~
                        {{
                          [message.user.firstName, message.user.lastName].join(
                            " "
                          )
                        }}
                      </i>
                      <p>
                        {{ message.content }}
                      </p>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div
          class="simplebar-placeholder"
          style="width: auto; height: 853px"
        ></div>
      </div>
      <div
        class="simplebar-track simplebar-horizontal"
        style="visibility: hidden"
      >
        <div
          class="simplebar-scrollbar"
          style="width: 0px; display: none"
        ></div>
      </div>
      <div
        class="simplebar-track simplebar-vertical"
        style="visibility: visible"
      >
        <div
          class="simplebar-scrollbar"
          style="
            height: 248px;
            transform: translate3d(0px, 0px, 0px);
            display: block;
          "
        ></div>
      </div>
    </ul>

    <div class="row">
      <div class="col">
        <div class="mt-2 bg-light p-3 rounded chat-input">
          <form
            [formGroup]="newMessage"
            class="needs-validation"
            novalidate=""
            name="chat-form"
            id="chat-form"
          >
            <div class="row">
              <div class="col mb-2 mb-sm-0">
                <input
                  [formControl]="customFormControl('content')"
                  type="text"
                  class="form-control border-0"
                  placeholder="Enter your text"
                  required=""
                />
                <div class="invalid-feedback">Please enter your messsage</div>
              </div>
              <div class="col-sm-auto">
                <div class="btn-group">
                  <button
                    [disabled]="newMessage.invalid"
                    (click)="sendMessage()"
                    type="submit"
                    class="btn btn-success chat-send w-100"
                  >
                    <i *ngIf="!sending" class="bi bi-cursor"></i>
                    <span
                      *ngIf="sending"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="conversation-actions dropdown">
    <button class="btn btn-sm btn-link" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="bi bi-three-dots-vertical font-16"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end">
        <a class="dropdown-item" href="#">Reply</a>
        <a class="dropdown-item" href="#">Edit</a>
        <a class="dropdown-item" href="#">Delete</a>
    </div>
</div> -->
