import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './settings.routes';

import { ClientSettingsComponent } from "./client-settings/client-settings.component";
import { ScanTypesComponent } from "./scan-types/scan-types.component";
import { UserComponent } from "./user-permission/user.component";
import { SettingsComponent } from "./settings.component";
import { PermissionsComponent } from '../settings/user-permission/permissions/permissions.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { DirectiveModule } from 'src/app/services/directives/directive.module';
import { JsonEditorComponent } from './user-permission/json-editor/json-editor.component';

@NgModule({
    declarations: [
        SettingsComponent,
        ClientSettingsComponent,
        ScanTypesComponent,
        UserComponent,
        PermissionsComponent,
        JsonEditorComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ComponentsModule,
        DirectiveModule
    ],
    exports: [
        RouterModule,
        RouterModule,
        ComponentsModule,
        DirectiveModule
    ],
    providers: [],
})
export class SettingsModule { }
