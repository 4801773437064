<div class="row" *ngIf="!getting">
  <div class="col-12 col-lg-12">
    <div
      class="d-flex align-items-center"
      *ngIf="studyDetails?.status !== 'REPORTED'"
    >
      <button
        *ngIf="!editMode"
        (click)="edit()"
        class="btn btn-primary ml-auto"
      >
        <i class="bi bi-pencil"></i>
        Edit
      </button>
      <button
        *ngIf="editMode"
        (click)="cancel()"
        class="btn btn-secondary btn-sm ml-auto"
      >
        cancel
      </button>
    </div>
    <div *ngIf="studyDetails.status === 'FLAGGED'" class="mt-3">
      <div class="alert alert-info" role="alert">
        <strong class="mr-2">Status</strong>Study has been Flagged:
        <strong class="mr-2">Reason:</strong>
        {{ studyDetails.flagReason }}
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12">
    <form action="" [formGroup]="studyRequestForm">
      <div class="row mt-4">
        <!-- patient -->
        <div class="col-lg-12 col-md-12 col-sm-12 flex-fill">
          <div class="group h-100">
            <label for="" class="group-label">Patient Info</label>
            <div class="row">
              <div class="col-3">
                <app-input
                  [required]="true"
                  [fieldId]="'patientName'"
                  [control]="customFormControl('patientName')"
                >
                </app-input>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for=""
                    >Gender <span class="text-danger">*</span></label
                  >
                  <select
                    [formControl]="customFormControl('patientGender')"
                    class="form-control"
                  >
                    <option [ngValue]="g" *ngFor="let g of gender">
                      {{ g }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-3">
                <app-input-date
                  [fieldId]="'dob'"
                  [control]="customFormControl('patientDob')"
                >
                </app-input-date>
              </div>
              <div class="col-3">
                <app-input
                  [fieldId]="'age'"
                  [control]="customFormControl('age')"
                ></app-input>
              </div>
            </div>
          </div>
        </div>
        <!-- patient -->
      </div>

      <div class="row mt-4">
        <!-- study -->
        <div class="col-lg-12 col-md-12 col-sm-12 flex-fill">
          <div class="group h-100">
            <label for="" class="group-label">Study Details</label>
            <div class="row">
              <div class="col-3">
                <app-input-date
                  [required]="true"
                  [fieldId]="'studyDate'"
                  [control]="customFormControl('studyDate')"
                >
                </app-input-date>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">Level <span class="text-danger">*</span></label>
                  <select
                    [formControl]="customFormControl('level')"
                    class="form-control"
                  >
                    <option [ngValue]="level" *ngFor="let level of levels">
                      {{ level }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for=""
                    >Scan Type <span class="text-danger">*</span></label
                  >
                  <select
                    [formControl]="customFormControl('scanType')"
                    class="form-control"
                  >
                    <option
                      [ngValue]="scan.id"
                      *ngFor="let scan of scanTypeList"
                    >
                      {{ scan.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-3">
                <app-input
                  [fieldId]="'referringPhysician'"
                  [control]="customFormControl('referringPhysician')"
                >
                </app-input>
              </div>
              <div class="col-3">
                <app-input
                  [fieldId]="'modality'"
                  [control]="customFormControl('modality')"
                ></app-input>
              </div>
              <div class="col-3">
                <app-input
                  [fieldId]="'location'"
                  [control]="customFormControl('location')"
                ></app-input>
              </div>
              <div class="col-3">
                <app-input
                  [fieldId]="'patientPhone'"
                  [control]="customFormControl('patientPhone')"
                >
                </app-input>
              </div>
              <div class="col-3">
                <app-input
                  [fieldId]="'patientId'"
                  [control]="customFormControl('patientId')"
                ></app-input>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="col-6">
                    <app-input-textarea
                      [rows]="6"
                      [fieldId]="'descriptionType'"
                      [control]="customFormControl('description')"
                    >
                    </app-input-textarea>
                  </div>
                  <div class="col-6">
                    <app-input-textarea
                      [rows]="6"
                      [fieldId]="'clinicalHistory'"
                      [control]="customFormControl('clinicalHistory')"
                    >
                    </app-input-textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- study -->
      </div>
    </form>
  </div>

  <!-- study dicom file -->
  <div class="col-lg-12 col-md-12 col-sm-12 mt-4 d-none">
    <form action="" [formGroup]="studyRequestForm">
      <div class="row">
        <div class="col-6">
          <app-input-uploader
            [control]="customFormControl('studyFile')"
            [fieldId]="'updateFile'"
          >
          </app-input-uploader>
        </div>
      </div>
    </form>
  </div>
  <!-- study dicom file -->

  <div class="col-lg-12 col-md-12 col-sm-12 mt-3" *ngIf="editMode">
    <div class="d-flex align-items-center">
      <button (click)="updateStudy()" class="btn btn-primary btn-sm ml-auto">
        <span
          *ngIf="updating"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ updating ? "Updating..." : "update" }}
      </button>
    </div>
  </div>
</div>

<div class="row mx-0" *ngIf="getting">
  <div class="col-12">
    <app-loading-placeholder
      [bgColor]="'text-primary'"
    ></app-loading-placeholder>
  </div>
</div>
