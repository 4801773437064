import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ScanTypeService } from 'src/app/pages/settings/scan-types/scan-types.service';
import { StudyService } from '../../studies/study.service';

@Component({
  selector: 'app-medical-study-edit',
  templateUrl: './study-edit.component.html',
  styleUrls: ['./study-edit.component.scss'],
})
export class StudyEditComponent implements OnInit {
  studyRequestForm!: FormGroup;
  levels: Array<string> = ['Normal', 'Priority', 'Stat'];
  gender: Array<string> = ['Male', 'Female'];
  scanTypeList!: Array<any>;

  editMode = false;
  updating = false;
  getting = false;
  currentParams: any;
  studyDetails: any;

  constructor(
    public route: ActivatedRoute,
    public studyService: StudyService,
    public scanTypeService: ScanTypeService
  ) {
    this.scanTypeList = [];

    this.studyRequestForm = new FormGroup({
      location: new FormControl(null),
      patientName: new FormControl(null, Validators.required),
      patientGender: new FormControl(null, Validators.required),
      studyDate: new FormControl(null, Validators.required),
      level: new FormControl(null, Validators.required),
      patientDob: new FormControl(null),
      patientId: new FormControl(null),
      referringPhysician: new FormControl(null),
      description: new FormControl(null),
      modality: new FormControl(null),
      scanType: new FormControl(null),
      clinicalHistory: new FormControl(null),
      age: new FormControl(null),
      patientPhone: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.route.queryParams.forEach((params: any) => {
      this.currentParams = params;
      this.getStudyDetails(params.studyId);
    });
    this.getScanTypes();
  }

  getScanTypes(): void {
    this.scanTypeService.getScanType((res, status) => {
      if (status) {
        this.scanTypeList = res;
      }
    });
  }

  customFormControl(controlName: string): FormControl {
    return this.studyRequestForm.get(controlName) as FormControl;
  }

  getStudyDetails(id: number): void {
    this.getting = true;
    this.studyService.getStudyDetails(id, (res, status) => {
      if (status) {
        this.getting = false;
        this.studyDetails = res;
        this.fillForm(res);
      }
    });
  }

  fillForm(study: any): void {
    this.studyRequestForm = new FormGroup({
      location: new FormControl(study.location),
      patientName: new FormControl(study.patientName, Validators.required),
      patientGender: new FormControl(study.patientSex, Validators.required),
      studyDate: new FormControl(+study.studyDate, Validators.required),
      level: new FormControl(study.level, Validators.required),
      patientDob: new FormControl(+study.patientDob),
      patientId: new FormControl(study.patientHospitalID),
      referringPhysician: new FormControl(study.referringPhysician),
      description: new FormControl(study.studyDecription),
      modality: new FormControl(study.modality),
      clinicalHistory: new FormControl(study.clinicalHistory),
      age: new FormControl(study.patientAge),
      scanType: new FormControl(study?.scanType?.id),
      patientPhone: new FormControl(study.patientPhone),
    });
    this.studyRequestForm.disable();
  }

  edit(): void {
    this.editMode = true;
    this.studyRequestForm.enable();
  }

  cancel(): void {
    this.editMode = false;
    this.getStudyDetails(this.currentParams.studyId);
    this.studyRequestForm.disable();
  }

  updateStudy(): void {
    this.updating = true;
    this.studyService.updateStudy(
      this.studyRequestForm.value,
      this.studyDetails.id,
      (res, status) => {
        this.updating = false;
        if (status) {
          this.getStudyDetails(this.studyDetails.id);
          this.editMode = false;
          this.studyRequestForm.disable();
        }
      }
    );
  }
}
