import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { BaseFormComponent } from '../base-form/base-form.component';
import { applyMixins } from '../../mixin/mixin';
import { FormControl } from '@angular/forms';

class BaseClass {}
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent {}
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
})
export class InputTextAreaComponent
  extends BaseClass
  implements OnInit, DoCheck
{
  @Input() fieldId!: string | null;
  @Input() control!: FormControl;
  @Input() cols!: number;
  @Input() rows!: number;
  @Input() required = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngDoCheck(): void {
    super.ngDoCheck();
  }
}
