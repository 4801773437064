import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './hos.routes';
import { EditStudyComponent } from "./study-details/study-details.component";
import { ClientDashboardComponent } from "./home/client-dashboard.component";
import { NewStudyComponent } from "./new-study/new-study.component";
import { StudyArchiveComponent } from "./studies/studies.component";
import { SearchPatientComponent } from './new-study/search-patient/search-patient.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ComponentsModule } from 'src/app/components/components.module';
import { SummaryCardsComponent } from './home/summary-cards/summary-cards.component';
import { DistributionComponent } from './home/distribution-cards/distribution.component';
import { InprogressComponent } from './home/inprogress-studies/inprogress.component';
import { StudyAttachmentComponent } from './study-details/study-attachment/study-attachment.component';
import { StudyReportComponent } from './study-details/study-report/study-report.component';
import { StudyEditComponent } from './study-details/study-edit/study-edit.component';


@NgModule({
    declarations: [
        EditStudyComponent,
        ClientDashboardComponent,
        NewStudyComponent,
        StudyArchiveComponent,
        SearchPatientComponent,
        DistributionComponent,
        SummaryCardsComponent,
        InprogressComponent,
        StudyAttachmentComponent,
        StudyReportComponent,
        StudyEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ComponentsModule,
        NgxExtendedPdfViewerModule,
    ],
    exports: [
        RouterModule,
        RouterModule,
        ComponentsModule
    ],
    providers: [],
})
export class HosModule { }
