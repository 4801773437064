<div class="row g-3 mb-4 align-items-center justify-content-between">
  <div class="col-auto">
    <h1 class="app-page-title mb-0 text-muted">New Study Request</h1>
  </div>

  <div class="col-auto">
    <div class="page-utilities">
      <div
        class="row g-2 justify-content-start justify-content-md-end align-items-center"
      >
        <div class="col-auto">
          <div class="table-search-form row gx-1 align-items-center">
            <div class="col-auto">
              <button (click)="back()" type="submit" class="btn btn-primary">
                <i class="bi bi-arrow-left"></i>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-search-patient (studyDetails)="getDicomStudy($event)"></app-search-patient>

<div class="row mx-0">
  <div class="d-flex w-100">
    <div class="app-card app-card-orders-table shadow-sm mb-5 w-100">
      <div class="app-card-body">
        <form action="" [formGroup]="studyRequestForm">
          <div class="row mx-0" [ngClass]="{ 'form-disabled': formDisabled }">
            <!-- patient -->
            <div class="col-12 mt-4 group">
              <label for="" class="group-label">Patient Info</label>
              <div class="row">
                <div class="col-3">
                  <app-input
                    [required]="true"
                    [fieldId]="'patientName'"
                    [control]="customFormControl('patientName')"
                  >
                  </app-input>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for=""
                      >Gender <span class="text-danger">*</span></label
                    >
                    <select
                      [formControl]="customFormControl('patientGender')"
                      class="form-control"
                    >
                      <option [ngValue]="g" *ngFor="let g of gender">
                        {{ g }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-3">
                  <app-input-date
                    [fieldId]="'dob'"
                    [control]="customFormControl('patientDob')"
                  >
                  </app-input-date>
                </div>
                <div class="col-3">
                  <app-input
                    [fieldId]="'age'"
                    [control]="customFormControl('age')"
                  ></app-input>
                </div>
              </div>
            </div>
            <!-- patient -->

            <!-- study -->
            <div class="col-12 mt-5 group">
              <label for="" class="group-label">Study Details</label>
              <div class="row">
                <div class="col-3">
                  <app-input-date
                    [required]="true"
                    [fieldId]="'studyDate'"
                    [control]="customFormControl('studyDate')"
                  >
                  </app-input-date>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for=""
                      >Level <span class="text-danger">*</span></label
                    >
                    <select
                      [formControl]="customFormControl('level')"
                      class="form-control"
                    >
                      <option [ngValue]="level" *ngFor="let level of levels">
                        {{ level }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for=""
                      >Scan Type<span class="text-danger">*</span></label
                    >
                    <select
                      [formControl]="customFormControl('scanType')"
                      class="form-control"
                    >
                      <option
                        [ngValue]="scan.id"
                        *ngFor="let scan of scanTypeList"
                      >
                        {{ scan.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-3">
                  <app-input
                    [fieldId]="'referringPhysician'"
                    [control]="customFormControl('referringPhysician')"
                  >
                  </app-input>
                </div>
                <div class="col-3">
                  <app-input
                    [fieldId]="'modality'"
                    [control]="customFormControl('modality')"
                  ></app-input>
                </div>
                <div class="col-3">
                  <app-input
                    [fieldId]="'location'"
                    [control]="customFormControl('location')"
                  ></app-input>
                </div>
                <div class="col-3">
                  <app-input
                    [fieldId]="'patientPhone'"
                    [control]="customFormControl('patientPhone')"
                  ></app-input>
                </div>
                <div class="col-3">
                  <app-input
                    [fieldId]="'patientId'"
                    [control]="customFormControl('patientId')"
                  ></app-input>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <app-input-textarea
                        [rows]="6"
                        [fieldId]="'descriptionType'"
                        [control]="customFormControl('description')"
                      >
                      </app-input-textarea>
                    </div>
                    <div class="col-6">
                      <app-input-textarea
                        [rows]="6"
                        [fieldId]="'clinicalHistory'"
                        [control]="customFormControl('clinicalHistory')"
                      >
                      </app-input-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- study -->

            <button
              (click)="saveStudy()"
              class="btn btn-primary ml-auto mt-3"
              [disabled]="studyRequestForm.invalid"
            >
              <span
                *ngIf="saving"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {{ saving ? "saving..." : "save" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
