<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="text-center">
                <h3 class="mb-0 text-capitalize">Get In 
                    <span class="font-weight-bold text-primary">Touch</span>
                </h3>
                <div class="main-title-border mx-auto"></div>
                <p class="text-muted sec_subtitle mx-auto mt-2">
                    We're open for inquiries and any suggestions.
                </p>
            </div>
        </div>
    </div>
    <div class="row mt-4 pt-4">
        <div class="col-lg-4">
            <div class="text-center mt-3">
                <div>
                    <i class="bi bi-telephone text-primary h2"></i>
                </div>
                <div class="mt-2">
                    <p class="mb-0 font-weight-bold">Call Us On</p>
                    <p class="text-muted mb-1">+256 200 925 718</p>
                    <p class="text-muted">+256 760 200 955</p>
                </div>
            </div>
            <div class="text-center mt-3">
                <div>
                    <i class="bi bi-envelope text-primary h2"></i>
                </div>
                <div class="mt-2">
                    <p class="mb-0 font-weight-bold">Email Us At</p>
                    <p class="text-muted">info@accessteleradiology.com</p>
                </div>
            </div>
        </div>
        <div class="col-lg-8">
            <div class="custom-form">
                <form>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group mt-2">
                                <input name="name" id="name" type="text" class="form-control" placeholder="Your Name*"
                                    required="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group mt-2">
                                <input name="email" id="email" type="email" class="form-control"
                                    placeholder="Your Email*" required="">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group mt-2">
                                <input type="text" class="form-control" name="subject" id="subject"
                                    placeholder="Your Subject.." required="">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group mt-2">
                                <textarea name="comments" id="comments" rows="6" class="form-control"
                                    placeholder="Your message..."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <input type="submit" id="submit" name="send" class="btn btn-custom" value="Send Message">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>