import { Component, OnInit } from '@angular/core';
import { ProgressAlertService } from 'src/app/services/alert/progress-alert.service';

@Component({
  selector: 'app-progress-alert',
  templateUrl: './progress-alert.component.html',
  styleUrls: ['./progress-alert.component.scss']
})
export class ProgressAlertComponent implements OnInit {

  toggleDisplay = false;
  progressNote!: string | null;

  constructor(public progress: ProgressAlertService) { }

  ngOnInit(): void {
    this.progress.progressObservable.forEach((val: any) => {
      if (val.show) {
        this.toggleDisplay = true;
        this.progressNote = val.note;
      } else {
        this.toggleDisplay = false;
        this.progressNote = null;
      }
    });
  }
}
