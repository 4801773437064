import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './doc.routes';

import { ClientsComponent } from "./clients/clients.component";
import { DocDashboardComponent } from "./home/doc-dashboard.component";
import { NewTemplateComponent } from "./report-template/new-template/new-template.component";
import { EditTemplateComponent } from "./report-template/edit-template/edit-template.component";
import { ReportTemplateComponent } from "./report-template/report-template.component";
import { PoolComponent } from "./pool/pool.component";
import { RadiologistComponent } from "./radiologist/radiologist.component";
import { StudiesComponent } from "./studies/studies.component";
import { SingleStudyComponent } from "./single-study/single-study.component";
import { SummaryCardsComponent } from './home/summary-cards/summary-cards.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ComponentsModule } from 'src/app/components/components.module';
import { DistributionComponent } from './home/distribution-cards/distribution.component';
import { MedicalCenterComponent } from './home/medical-center-studies/medical-center.component';
import { StudyReportComponent } from './single-study/study-report/study-report.component';
import { StudyDetailsComponent } from './single-study/study-details/study-details.component';
import { ArchiveStudiesComponent } from './archive/archive-studies.component';


@NgModule({
    declarations: [
        ClientsComponent,
        DocDashboardComponent,
        NewTemplateComponent,
        EditTemplateComponent,
        ReportTemplateComponent,
        PoolComponent,
        RadiologistComponent,
        StudiesComponent,
        SingleStudyComponent,
        SummaryCardsComponent,
        DistributionComponent,
        MedicalCenterComponent,
        StudyDetailsComponent,
        StudyReportComponent,
        ArchiveStudiesComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ComponentsModule,
        NgxExtendedPdfViewerModule
    ],
    exports: [
        RouterModule,
        RouterModule,
        ComponentsModule
    ],
    providers: [],
})
export class DocModule { }
