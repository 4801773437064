import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { PermissionService } from '../permissions/permission.service';

@Directive({
    selector: '[app-disable-link]',
})
export class DisableLinkDirective implements OnInit, AfterViewInit {
    @Input() roleQuery!: string;
    @Input() addClass!: string;

    constructor(
        private el: ElementRef,
        public renderer: Renderer2,
        public permissions: PermissionService
    ) { }

    ngOnInit(): void {
        this.checkRole();
    }

    ngAfterViewInit(): void {
        this.checkRole();
    }

    checkRole(): void {
        if (this.roleQuery) {
            if (!this.permissions.isGranted(this.roleQuery)) {
                this.renderer.addClass(this.el.nativeElement, this.addClass);
            }
        }
    }
}