import { BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup } from '@angular/forms';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { environment } from 'src/environments/environment.prod';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { UserService } from '../settings/user-permission/user.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
import { DomSanitizer } from '@angular/platform-browser';

class BaseClass { }
interface BaseClass extends ModalComponent { }
applyMixins(BaseClass, [ModalComponent]);

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends BaseClass implements OnInit {

  assetsRoot = environment.imageUrl;
  userImageForm: FormControl;
  userSignature: FormControl;
  userDetailsForm: FormGroup;
  userPasswordForm: FormGroup;

  userSignatureData: any = null;

  uploading = false;
  updatingDetails = false;
  updatingPassword = false;
  uploadingSignature = false;
  signatureSource!: any;

  user!: any;
  updateImageBtn: object = {
    method: () => {
      this.updateProfileImage();
    },
    text: 'upload'
  };

  saveSignature: object = {
    method: () => {
      this.saveUserSignature();
    },
    text: 'save'
  };

  updateDetailsBtn: object = {
    method: () => {
      this.updateUserDetails();
    },
    text: 'update'
  };

  updatePasswordBtn: object = {
    method: () => {
      this.updateUserPassword();
    },
    text: 'update'
  };

  constructor(
    public modalService: BsModalService,
    public userService: UserService,
    private sanitizer: DomSanitizer,
    public sessionService: SessionStorageService
  ) {
    super();

    this.userDetailsForm = new FormGroup({
      userName: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      email: new FormControl()
    });

    this.userPasswordForm = new FormGroup({
      oldPassword: new FormControl(),
      newPassword: new FormControl()
    });

    this.userImageForm = new FormControl();
    this.userSignature = new FormControl();
  }

  ngOnInit(): void {
    this.getUserDetails(this.sessionService.getUserId());
    this.getUserSignature();
  }

  getUserDetails(id: number): void {
    this.userService.getUserDetails(id, (res, status) => {
      if (status) {
        this.user = res;
      }
    });
  }

  getUserSignature(): void {
    this.userService.getUserSign((res, status) => {
      if (status) {
        this.userSignatureData = res;
        if (res !== null) {
          this.getImageResource(res);
        }
      }
    });
  }

  getImageResource(data: any): any {
    this.signatureSource = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/${data.fileType};base64, ${data.data}`);
  }

  updateProfileImage(): void {
    this.userService.updateUserImage(
      this.sessionService.getUserId(),
      this.userImageForm.value,
      (res, status) => {
        if (status) {
          this.getUserDetails(this.sessionService.getUserId());
          this.closeModal();
        }
      }
    )
  }

  updateUserDetails(): void {
    this.updatingDetails = true;
    this.userService.updateUser(
      this.sessionService.getUserId(),
      this.userDetailsForm.value,
      (res, status) => {
        this.updatingDetails = false;
        if (status) {
          this.getUserDetails(this.sessionService.getUserId());
          this.closeModal();
        }
      });
  }

  updateUserPassword(): void {
    this.updatingPassword = true;
    this.userService.changeUserPassword(
      this.sessionService.getUserId(),
      this.userPasswordForm.value,
      (res, status) => {
        this.updatingPassword = false;
        this.closeModal();
      }
    )
  }

  saveUserSignature(): void {
    this.uploadingSignature = true;
    this.userService.updateUserSign(this.userSignature.value, (res, status) => {
      this.uploadingSignature = false;
      if (status) {
        this.getUserSignature();
      }
    });
  }

  editUserDetails(temp: TemplateRef<any>): void {
    this.userDetailsForm = new FormGroup({
      id: new FormControl(this.user.id),
      firstName: new FormControl(this.user.firstName),
      lastName: new FormControl(this.user.lastName),
      userName: new FormControl(this.user.username),
      email: new FormControl(this.user.email)
    });

    this.modalService.show(temp);
  }

  editUserPassword(temp: TemplateRef<any>): void {
    this.userPasswordForm = new FormGroup({
      id: new FormControl(this.user.id),
      oldPassword: new FormControl(),
      newPassword: new FormControl()
    });

    this.modalService.show(temp);
  }

  customFormControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl
  }
}
