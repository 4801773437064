import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatMessage } from 'src/app/entities/global/chat-message.interface';
import { environment } from 'src/environments/environment';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { ChatQuery } from 'src/app/store/chat/chat.query';
import { ChatService } from 'src/app/store/chat/chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  @Input() conversationId!: number;
  messagesList!: Array<ChatMessage>;
  newMessage!: FormGroup;
  assetsRoot = environment.imageUrl;
  sending!: boolean;

  constructor(
    private chatQuery: ChatQuery,
    private chatService: ChatService,
    public apiConnect: ApiConnectionService
  ) {
    this.messagesList = [];

    this.newMessage = new FormGroup({
      content: new FormControl(null, Validators.required),
      isReply: new FormControl(false),
      repliedMessageId: new FormControl(null),
    });
  }

  customFormControl(name: string): FormControl {
    return this.newMessage.get(name) as FormControl;
  }

  ngOnInit(): void {
    this.chatQuery.selectAll().subscribe((messages: Array<ChatMessage>) => {
      this.messagesList = messages;

      setTimeout(() => {
        this.updateScroll();
      });
    });

    this.getMessages();

    this.chatQuery.getSendingMessageStatus().subscribe((res: boolean) => {
      this.sending = res;
    });
  }

  updateScroll(): void {
    const divScrollList = document.getElementById('simplebar-content');
    if (divScrollList) {
      divScrollList.scrollTop = divScrollList.scrollHeight;
    }
  }

  getMessages(): void {
    this.chatService.loadMessages(this.conversationId);
  }

  sendMessage(): void {
    this.chatService.sendMessage(this.newMessage.value, this.conversationId);
  }
}
