import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RoutesModule } from './routes/routes.module';
import { AlertService } from './services/alert/alert.service';
import { ApiLinkService } from './services/api/apilink.service';
import { FilterService } from './services/filter/filter.service';
import { SettingsService } from './services/settings/settings.service';
import { WebSocketModule } from './services/websocket/websocket.module';
import { ApiConnectionService } from './services/api/apiconnection.service';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { SessionStorageService } from './services/session/session-storage.service';
import { ProgressAlertService } from 'src/app/services/alert/progress-alert.service';
import { AuthenticationService } from './services/authentication/authentication.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OffcanvasService } from './services/offcanvas/offcanvas.service';
import { StateManagerService } from './services/state/state-manager.service';
import { NotificationService } from './store/notification/notification.service';
import { ServiceWorkerModule } from '@angular/service-worker';
@NgModule({
  declarations: [AppComponent, PreloaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    RoutesModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    WebSocketModule.forRoot({
      url: environment.wsUrl,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.mode === 'production' ? true : false,
    }),
  ],
  providers: [
    AuthenticationService,
    ApiLinkService,
    ApiConnectionService,
    SessionStorageService,
    ProgressAlertService,
    SettingsService,
    AlertService,
    FilterService,
    HttpClient,
    BsModalService,
    OffcanvasService,
    StateManagerService,
    NotificationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
