import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  loading = false;
  pageMode = false;
  submitting = false;

  recoverMessage!: string;
  recoverStatus!: boolean;

  linkMessage!: string | undefined;
  linkStatus!: boolean;

  recoverForm!: FormGroup;
  updateForm!: FormGroup;
  tempUserId!: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public settingService: SettingsService,
    public authService: AuthenticationService
  ) {

    this.recoverForm = new FormGroup({
      email: new FormControl()
    });

    this.updateForm = new FormGroup({
      id: new FormControl(),
      newPassword: new FormControl(null, Validators.required),
      confirmPassword: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(val => {
      if (!this.isEmpty(val)) {
        this.pageMode = true;
        this.validateUrlKey(val.urlkey);
      } else {
        this.pageMode = false;
      }
    });
    this.checkForPasswordMatch();
  }

  validateUrlKey(link: string): void {
    this.authService.validateLink(link, (res, status) => {
      this.linkStatus = status;
      if (status) {
        this.linkMessage = res.message;
        this.customFormControl('id', this.updateForm).setValue(res.userId);
      } else {
        this.linkMessage = res.message;
      }
    });
  }

  submitRecoveryEmail(): void {
    this.loading = true;
    this.authService.submitRecoveryEmail(this.recoverForm.value, (res, status) => {
      this.loading = false;
      this.recoverStatus = status;
      if (status) {
        this.recoverMessage = res.message;
      } else {
        this.recoverMessage = res.message;
      }
    })
  }

  submitNewPassword(): void {
    this.submitting = true;
    this.authService.changePassword(this.updateForm.value, (res, status) => {
      this.submitting = false;
      if (status) {
        this.router.navigate(['/auth/login']);
      }
    });
  }

  customFormControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }

  checkForPasswordMatch(): void {
    this.updateForm.valueChanges.subscribe((val: any) => {
      if (val.confirmPassword !== null) {
        if (val.confirmPassword !== val.newPassword) {
          this.customFormControl('confirmPassword', this.updateForm)
            .markAsTouched();
          this.customFormControl('confirmPassword', this.updateForm)
            .setErrors({ password_deos_not_match: true });
        }
      }
    });
  }

  isEmpty(obj: any): boolean {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  back(): void {
    this.router.navigate(['/auth/recover']);
  }
}
