import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PermissionService } from 'src/app/services/permissions/permission.service';
import { ArchiveStudiesService } from './archive-studies.service';
import { RadiologistService } from '../radiologist/radiologist.service';
import { ClientService } from '../clients/client.service';
import { ConnectionService } from 'src/app/services/websocket/connection.service';
import { SettingsService } from 'src/app/services/settings/settings.service';

class BaseClass {}
interface BaseClass
  extends ModalComponent,
    PaginationComponent,
    FilterComponent {}
applyMixins(BaseClass, [ModalComponent, PaginationComponent, FilterComponent]);
@Component({
  selector: 'app-archive-studies',
  templateUrl: './archive-studies.component.html',
  styleUrls: ['./archive-studies.component.scss'],
})
export class ArchiveStudiesComponent
  extends BaseClass
  implements OnInit, AfterViewInit, OnDestroy
{
  elementsPerPage = 20;
  activePageList!: any[];

  searchFilter!: string;
  showEntries!: FormControl;
  assetsRoot = environment.imageUrl;
  gettingStudies = false;
  archiving = false;

  studies: Array<any> = [];
  clients: Array<any> = [];
  openFilter = false;
  currentParams: any;
  queryForm!: FormGroup;
  subscription!: Subscription;
  assignmentList!: Array<any>;

  searchByStudyIdForm!: FormControl;
  assignRadFormControl!: FormControl;
  studiesSubscription!: Subscription;

  clientsPredicate: object = {
    method1: (obj: any) => obj.id,
    method2: (obj: any) => [obj.name].join(' '),
  };

  constructor(
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public archiveStudiesService: ArchiveStudiesService,
    public modalService: BsModalService,
    public clientService: ClientService,
    public permission: PermissionService,
    public settingService: SettingsService
  ) {
    super();
    const dates = this.settingService.getCurrentWeekDates();
    this.queryForm = new FormGroup({
      studyDate: new FormControl([dates.first, dates.last]),
      facility: new FormControl(null),
    });

    this.showEntries = new FormControl(20);
    this.filterList = ['patientName', 'studyUID', 'studyType|name', 'status'];

    this.assignmentList = [];
    this.searchByStudyIdForm = new FormControl(null, Validators.required);
    this.assignRadFormControl = new FormControl(null, Validators.required);
  }

  ngOnInit(): void {
    this.route.queryParams.forEach((params: any) => {
      if (this.isEmpty(params)) {
        this.router.navigate(['/app/doc/archive-studies'], {
          queryParams: { status: 'all' },
        });
      } else {
        this.prefillForm(params);
        this.getStudies(params);
      }
    });

    this.queryBuilder();
    this.getClients();

    this.showEntries.valueChanges.subscribe((val) => {
      this.elementsPerPage = val;
    });
  }

  ngAfterViewInit(): void {
    const statusBtn: NodeListOf<Element> =
      document.querySelectorAll('.status-btn');
    statusBtn.forEach((btn: Element, index: number) => {
      if (
        btn.getAttribute('status-data') ===
        this.customFormControl('status').value
      ) {
        btn.classList.add('active');
      }

      btn.addEventListener('click', (ev) => {
        statusBtn.forEach((ele: Element) => {
          ele.classList.remove('active');
        });

        btn.classList.add('active');
        if (btn.classList.contains('active')) {
          this.queryForm
            .get('status')
            ?.setValue(btn.getAttribute('status-data'));
        }
      });
    });
  }

  customFormControl(name: string): FormControl {
    return this.queryForm.get(name) as FormControl;
  }

  prefillForm(param: any): void {
    for (const key in param) {
      if (Object.prototype.hasOwnProperty.call(param, key)) {
        if (this.queryForm.contains(key)) {
          if (key === 'studyDate') {
            this.queryForm
              .get(key)
              ?.setValue(
                [
                  new Date(+param['startDate']).getTime(),
                  new Date(+param['endDate']).getTime(),
                ],
                { emitEvent: false }
              );
          } else {
            this.queryForm.get(key)?.setValue(param[key], { emitEvent: false });
          }
        }
      }
    }
  }

  queryBuilder(): void {
    this.queryForm.valueChanges.forEach((form: any) => {
      let param: any = {};
      for (const key in form) {
        if (Object.prototype.hasOwnProperty.call(form, key)) {
          if (form[key] !== null) {
            if (key === 'studyDate') {
              if (form[key].length > 0) {
                param['startDate'] = form[key][0];
                param['endDate'] = form[key][1];
                param[key] = form[key];
              }
            } else {
              param[key] = form[key];
            }
          }
        }
      }

      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.router.navigate(['/app/doc/archive-studies'], { queryParams: param });
    });
  }

  clearFilters(): void {
    this.queryForm.reset({
      studyDate: [],
      facility: null,
    });
  }

  isEmpty(obj: any): boolean {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  paginatorEvent(change: any): void {
    setTimeout(() => {
      this.activePageList = change.currentChunk;
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['patientName'];
    }
  }

  back(): void {
    this.location.back();
  }

  openBackendFilter() {
    const filter: HTMLElement | null =
      document.getElementById('backend-filter');
    if (filter) {
      filter.classList.remove('d-none');
    }
    this.openFilter = true;
  }

  clearBackendFilter() {
    const filter: HTMLElement | null =
      document.getElementById('backend-filter');
    if (filter) {
      filter.classList.add('d-none');
    }
    this.openFilter = false;
  }

  getStudies(params: any): void {
    this.assignmentList = [];
    this.currentParams = params;
    this.gettingStudies = true;
    this.archiveStudiesService.getPoolStudies(params, (res, status) => {
      this.gettingStudies = false;
      if (status) {
        this.studies = res;
      }
    });
  }

  getClients(): void {
    this.clientService.getMedicalCenters((res, status) => {
      if (status) {
        this.clients = res;
      }
    });
  }

  searchStudyById(): void {
    this.router.navigate(['/app/doc/archive-studies'], {
      queryParams: { studyId: this.searchByStudyIdForm.value },
    });
  }

  checkAll($event: any): void {
    if ($event.target.checked) {
      const studies: NodeListOf<any> =
        document.querySelectorAll('.study-entry');
      studies.forEach((tr: HTMLElement) => {
        const checkInput: HTMLInputElement | null =
          tr.querySelector('.form-check-input');

        if (checkInput !== null) {
          checkInput.checked = true;
          const value = checkInput.getAttribute('study-id');
          if (value) {
            this.addToAssignmentList(+value);
          }
          tr?.classList.add('checked-tr');
        }
      });
    } else {
      const studies: NodeListOf<any> =
        document.querySelectorAll('.study-entry');
      studies.forEach((tr: HTMLElement) => {
        const checkInput: any = tr.querySelector('.form-check-input');

        if (checkInput !== null) {
          checkInput.checked = false;
          const value = checkInput.getAttribute('study-id');
          if (value) {
            this.removeFromAssignment(+value);
          }
          tr?.classList.remove('checked-tr');
        }
      });
    }
  }

  checkSingle($event: any, studyId: number): void {
    if ($event.target.checked) {
      const tr = document.getElementById('tr' + studyId);
      tr?.classList.add('checked-tr');
      this.addToAssignmentList(studyId);
    } else {
      const tr = document.getElementById('tr' + studyId);
      tr?.classList.remove('checked-tr');
      this.removeFromAssignment(studyId);
    }
  }

  addToAssignmentList(id: number): void {
    if (!this.isInAssignmentList(id) && id) {
      this.assignmentList.push(id);
    }
  }

  removeFromAssignment(id: number): void {
    if (this.isInAssignmentList(id)) {
      const index = this.assignmentList.findIndex(
        (item: number) => item === id
      );
      this.assignmentList.splice(index, 1);
    }
  }

  isInAssignmentList(id: number): boolean {
    const ele = this.assignmentList.find((item: number) => item === id);
    return ele ? true : false;
  }

  archiveStudies(): void {
    this.archiving = true;
    this.archiveStudiesService.archiveStudy(
      { studies: this.assignmentList },
      (res, status) => {
        this.archiving = false;
        if (status) {
          this.getStudies(this.currentParams);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.studiesSubscription?.unsubscribe();
  }
}
