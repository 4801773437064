import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ClientService } from '../../clients/client.service';
import { ReportTemplateService } from '../report-template.service';
@Component({
  selector: 'app-new-template',
  templateUrl: './new-template.component.html',
  styleUrls: ['./new-template.component.scss'],
})
export class NewTemplateComponent implements OnInit {
  newTemplateForm!: FormGroup;
  templateVariables!: any[];
  saving = false;
  loading = false;
  showPreview = false;
  clients: any[] = [];

  constructor(
    public location: Location,
    public clientService: ClientService,
    public reportService: ReportTemplateService
  ) {
    this.newTemplateForm = new FormGroup({
      description: new FormControl(),
      report: new FormControl(),
      medicalCenterId: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.getTemplateVariables();
    this.getClients();
    this.customFormControl(
      'report',
      this.newTemplateForm
    ).valueChanges.subscribe((val) => {
      if (val) {
        this.processPreview(val);
      }
    });
  }

  getClients(): void {
    this.clientService.getMedicalCenters((res, status) => {
      if (status) {
        this.clients = res;
      }
    });
  }

  getTemplateVariables(): void {
    this.loading = true;
    this.reportService.getReportTemplateVariables((res, status) => {
      this.loading = false;
      if (status) {
        this.templateVariables = res;
      }
    });
  }

  processPreview(report: string): void {
    const renderTemlplate: HTMLElement | null =
      document.getElementById('render-template');
    if (renderTemlplate) {
      renderTemlplate.innerHTML = this.replaceVariables(report);
    }
  }

  replaceVariables(report: string): string {
    let finalString = report;
    for (let i = 0; i < this.templateVariables.length; i++) {
      let searchRegExp = new RegExp(this.templateVariables[i].tempString, 'g');
      let replaceExp = this.templateVariables[i].sample;
      finalString = finalString.replace(searchRegExp, replaceExp);
    }
    return finalString;
  }

  previewTemplate(): void {
    this.showPreview = true;
  }

  closePreview(): void {
    this.showPreview = false;
  }

  saveReportTemplate(): void {
    this.saving = true;
    this.reportService.saveReportTemplate(
      this.newTemplateForm.value,
      (res, status) => {
        this.saving = false;
        if (status) {
          this.newTemplateForm.reset();
        }
      }
    );
  }

  copyVariable(symbol: string, id: number): void {
    let input = document.createElement('input');
    input.value = symbol;
    input.select();
    document.execCommand('copy');
    navigator.clipboard.writeText(symbol);

    let icon = document.getElementById('vari' + id);
    icon?.classList.remove('bi-clipboard');
    icon?.classList.add('bi-clipboard-check');

    let btn = document.getElementById('var' + id);
    btn?.classList.remove('copy');
    btn?.classList.add('copied');

    setTimeout(() => {
      icon?.classList.remove('bi-clipboard-check');
      icon?.classList.add('bi-clipboard');

      btn?.classList.remove('copied');
      btn?.classList.add('copy');
    }, 3000);
  }

  customFormControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }

  back(): void {
    this.location.back();
  }
}
