<div class="d-flex justify-content-between align-items-center mb-4">
  <!-- search -->
  <form action="" class="search-bar">
    <div class="position-relative">
      <input name="search" type="text" class="form-control form form-control-light" placeholder="Search ..." />
      <span class="bi bi-search"></span>
    </div>
  </form>
  <!-- search -->

  <!-- actions -->
  <div class="">
    <button (click)="openModal(newUserModal)" class="btn btn-sm btn-primary">
      <i class="bi bi-plus-lg mr-1"></i>
      <span style="font-weight: normal">Invite a new user</span>
    </button>
  </div>
  <!-- actions -->
</div>

<div class="row">
  <div class="col-lg-5">
    <div class="table-responsive">
      <table class="table table-centered table-nowrap mb-0">
        <thead class="">
          <tr>
            <th class="border-0">Name</th>
            <th class="border-0">Role</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loadingUsers">
            <td colspan="2">
              <div class="spinner-border text-primary spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </td>
          </tr>
          <tr *ngFor="let user of users" class="select-user" [attr.id]="'user' + user.id"
            (click)="selectUser(user.id, 'user' + user.id)">
            <td class="cell">
              <a href="javascript:void(0);" class="avatar">
                <img [src]="
                    user.userImage
                      ? assetsRoot + user.userImage.url
                      : 'assets/images/default.png'
                  " width="38" alt="" />
              </a>
              <h2>
                <a href="javascript:void(0);">
                  {{ [user.firstName, user.lastName].join(" ") }}
                </a>
              </h2>
            </td>
            <td>
              {{ user.role }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-lg-7">
    <!-- user details -->
    <div class="d-flex justify-content-between">
      <div *ngIf="loadingDetails">
        <div class="spinner-border text-primary spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="userDetails && !loadingDetails" class="d-flex flex-column">
        <div class="user-image mb-2">
          <img class="user-image" [src]="
              userDetails.userImage
                ? assetsRoot + userDetails.userImage.url
                : 'assets/images/default.png'
            " alt="" />
        </div>
        <h6>{{ [userDetails?.firstName, userDetails?.lastName].join(" ") }}</h6>
        <div>{{ userDetails?.email }} | {{ userDetails?.phoneNumber }}</div>
        <div class="">
          {{ userDetails.role }}
          <i app-popper [targetElement]="'roleTarget'" [popperElement]="'rolePopup'" [popperOffset]="[0, 8]"
            [placement]="'right'" id="roleTarget" class="bi bi-question-circle text-primary ml-1">
          </i>
          <!-- tooltip -->
          <div id="rolePopup" class="custom-tooltip" role="rolePopup">
            user role
            <div id="arrow" data-popper-arrow></div>
          </div>
          <!-- tooltip -->
        </div>
      </div>

      <div class="ml-auto" *ngIf="!userDetails?.isAdmin">
        <button (click)="editUser(editUserModal)" class="btn btn-sm btn-white mr-1 text-primary font-weight-normal">
          <i class="bi bi-pencil mr-1"></i>
          Profile
        </button>
        <button class="btn btn-sm btn-light text-muted dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-three-dots-vertical"></i>
        </button>
        <div class="dropdown-menu" data-popper-placement="bottom-end">
          <a (click)="confirmReset()" class="dropdown-item" href="javascript:void(0);" title="Edit" data-toggle="modal">
            <i class="bi bi-bootstrap-reboot mr-2 text-muted"></i>
            Reset Password
          </a>
          <a (click)="confirmDelete()" class="dropdown-item" href="javascript:void(0);" title="Delete"
            data-toggle="modal">
            <i class="bi bi-trash mr-2 text-muted"></i>
            Delete User
          </a>
        </div>
      </div>
    </div>
    <!-- user details -->
    <hr/>
    <!-- permissions -->
    <app-permissions *ngIf="userActiveId" [user]="user"></app-permissions>
    <!-- permissions -->
  </div>
</div>

<!-- new user -->
<ng-template #newUserModal>
  <app-modal [modalActionButton]="saveUserBtn" [modalTitle]="'Create User'" [condition]="userNewForm.invalid"
    [loading]="saving">
    <form action="" [formGroup]="userNewForm">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'firstName'" [required]="true" [control]="customFormControl('firstName', userNewForm)">
          </app-input>
        </div>
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'lastName'" [required]="true" [control]="customFormControl('lastName', userNewForm)">
          </app-input>
        </div>

        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'email'" [required]="true" [control]="customFormControl('email', userNewForm)">
          </app-input>
        </div>
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'phone'" [control]="customFormControl('phoneNumber', userNewForm)"></app-input>
        </div>
        <div class="col-lg-12 col-md-12">
          <app-input [fieldId]="'role'" [control]="customFormControl('role', userNewForm)"></app-input>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- new user -->

<!-- edit user -->
<ng-template #editUserModal>
  <app-modal [modalActionButton]="updateUserBtn" [modalTitle]="'Edit User'" [condition]="userEditForm.invalid"
    [loading]="updating">
    <form action="" [formGroup]="userEditForm">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'firstName'" [required]="true" [control]="customFormControl('firstName', userEditForm)">
          </app-input>
        </div>
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'lastName'" [required]="true" [control]="customFormControl('lastName', userEditForm)">
          </app-input>
        </div>

        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'email'" [required]="true" [control]="customFormControl('email', userEditForm)">
          </app-input>
        </div>
        <div class="col-lg-6 col-md-6">
          <app-input [fieldId]="'phone'" [control]="customFormControl('phoneNumber', userEditForm)"></app-input>
        </div>
        <div class="col-lg-12 col-md-12">
          <app-input [fieldId]="'role'" [control]="customFormControl('role', userEditForm)"></app-input>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- edit user -->