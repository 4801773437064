<div class="row g-3 mb-4 align-items-center justify-content-between">
    <div class="col-auto">
        <h1 class="app-page-title mb-0 text-muted">Study Details</h1>
    </div>
    <div class="col-auto">
        <div class="page-utilities">
            <div class="row g-2 justify-content-start justify-content-md-end align-items-center">
                <div class="col-auto">
                    <form class="table-search-form row gx-1 align-items-center">
                        <div class="col-auto">
                            <button (click)="openWithWeasisViewer()" class="btn btn-primary mr-2">
                                Dicom Viewer
                                <i class="bi bi-arrow-up-right-circle-fill ml-2"></i>
                            </button>

                            <button (click)="back()" type="submit" class="btn btn-primary">
                                <i class="bi bi-arrow-left"></i>
                                Back
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row align-items-start w-100 mx-0">
    <nav id="orders-table-tab"
        class="orders-table-tab border-bottom app-nav-tabs w-100 nav shadow-sm flex-column flex-sm-row">
        <a class="text-sm-center nav-link status-btn" [routerLink]="'/app/doc/studies/single/study-details'"
            [routerLinkActive]="'active'" [queryParams]="{studyId: studyId, studyUID: studyUID}"
            href="javascript:void(0);">
            Study Details
        </a>
        <a class="text-sm-center nav-link status-btn" [routerLink]="'/app/doc/studies/single/study-report'"
            [routerLinkActive]="'active'" [queryParams]="{studyId: studyId, studyUID: studyUID}"
            href="javascript:void(0);">
            Study Report
        </a>
    </nav>
</div>

<div class="row mx-0">
    <div class="d-flex w-100">
        <div class="app-card app-card-orders-table shadow-sm mb-3 w-100">
            <div class="app-card-body">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>