import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {

  @Input()
  pagesList: Array<any> = [];

  @Input()
  activePage: number = 1;

  // to be removed
  @Input()
  chuckRecords: Array<any> = [];

  @Output()
  onPageChange: EventEmitter<any> = new EventEmitter();

  @Output()
  onPageChangeObservable: EventEmitter<Observable<any>> = new EventEmitter();

  // to be removed
  @Output()
  onPageChangev2: EventEmitter<Observable<any>> = new EventEmitter();

  adaptiveList: any[] = [];

  constructor() { }

  ngOnChanges(): void {
    this.activePage = 1;
    this.adaptiveList = this.createAdaptivePageList(this.pagesList, this.activePage);

    this.onPageChange?.emit({
      controlClicked: false,
      chunks: this.pagesList,
      currentPage: this.activePage - 1,
      currentChunk: this.pagesList[this.activePage - 1]
    });

    this.onPageChangeObservable?.emit(new BehaviorSubject({
      controlClicked: false,
      chunks: this.pagesList,
      currentPage: this.activePage - 1,
      currentChunk: this.pagesList[this.activePage - 1]
    }));
  }

  onClickPage(pageNumber: number): void {
    if (pageNumber < 1) { return; }
    if (pageNumber > this.pagesList.length) { return; }

    this.activePage = pageNumber;
    window.removeEventListener('click', () => {
    })
    this.adaptiveList = this.createAdaptivePageList(this.pagesList, this.activePage);
    this.onPageChange.emit({ chunks: this.pagesList, currentPage: this.activePage - 1, controlClicked: true, currentChunk: this.pagesList[this.activePage - 1] });
    this.onPageChangeObservable.emit(new BehaviorSubject({ chunks: this.pagesList, currentPage: this.activePage - 1, controlClicked: true, currentChunk: this.pagesList[this.activePage - 1] }));
  }

  createAdaptivePageList(pagesList: any[], activePage: number): any[] {

    if(pagesList.length <= 1) {return []}

    let adaptiveList: any[] = [
      { pageNo: 1, index: 0, type: 'page' },
      { pageNo: pagesList.length, index: pagesList.length - 1, type: 'page' }
    ];

    if(pagesList.length === 2){
      return adaptiveList;
    }

    // create adaptive on length greater than 3
    if (pagesList.length > 3) {
      let left = activePage - 1;
      let right = activePage + 1;

      // active page is first page 
      if ((activePage - 1) === 0) {
        let [first, ...rest] = adaptiveList;
        let hiddenPages = this.getHiddenPages((right + 1), pagesList.length)
        return [
          first,
          { pageNo: right, index: right - 1, type: 'page' },
          { pageNo: null, index: null, type: 'more', hiddenPages },
          ...rest
        ];
      }

      // active page is last page
      if (activePage === pagesList.length) {
        let [first, ...rest] = adaptiveList;
        let hiddenPages = this.getHiddenPages((left - 1), 1)
        return [
          first,
          { pageNo: null, index: null, type: 'more', hiddenPages },
          { pageNo: left, index: left - 1, type: 'page' },
          ...rest
        ];
      }

      // active page is in between
      let [first, ...rest] = adaptiveList;
      let hiddenPagesLeft = this.getHiddenPages((left - 1), 1);
      let hiddenPagesRight = this.getHiddenPages((right + 1), pagesList.length);

      if (hiddenPagesLeft === 'underflow') {

        if (hiddenPagesRight.length === 0) {
          return [
            first,
            { pageNo: activePage, index: activePage - 1, type: 'page' },
            { pageNo: right, index: right - 1, type: 'page' },
            ...rest
          ];
        }else{
          return [
            first,
            { pageNo: activePage, index: activePage - 1, type: 'page' },
            { pageNo: right, index: right - 1, type: 'page' },
            { pageNo: null, index: null, type: 'more', hiddenPages: hiddenPagesRight },
            ...rest
          ];
        }
      }

      if (hiddenPagesRight === 'overflow') {

        if (hiddenPagesLeft.length === 0) {
          return [
            first,
            { pageNo: left, index: left - 1, type: 'page' },
            { pageNo: activePage, index: activePage - 1, type: 'page' },
            ...rest
          ];
        }else{
          return [
            first,
            { pageNo: null, index: null, type: 'more', hiddenPages: hiddenPagesLeft },
            { pageNo: left, index: left - 1, type: 'page' },
            { pageNo: activePage, index: activePage - 1, type: 'page' },
            ...rest
          ];
        }
      }

      if (hiddenPagesLeft.length === 0 && hiddenPagesRight.length === 0) {
        return [
          first,
          { pageNo: left, index: left - 1, type: 'page' },
          { pageNo: activePage, index: activePage - 1, type: 'page' },
          { pageNo: right, index: right - 1, type: 'page' },
          ...rest
        ];
      }

      if (hiddenPagesLeft.length === 0) {
        return [
          first,
          { pageNo: left, index: left - 1, type: 'page' },
          { pageNo: activePage, index: activePage - 1, type: 'page' },
          { pageNo: right, index: right - 1, type: 'page' },
          { pageNo: null, index: null, type: 'more', hiddenPages: hiddenPagesRight },
          ...rest
        ];
      }

      if (hiddenPagesRight.length === 0) {
        return [
          first,
          { pageNo: null, index: null, type: 'more', hiddenPages: hiddenPagesLeft },
          { pageNo: left, index: left - 1, type: 'page' },
          { pageNo: activePage, index: activePage - 1, type: 'page' },
          { pageNo: right, index: right - 1, type: 'page' },
          ...rest
        ];
      }

      return [
        first,
        { pageNo: null, index: null, type: 'more', hiddenPages: hiddenPagesLeft },
        { pageNo: left, index: left - 1, type: 'page' },
        { pageNo: activePage, index: activePage - 1, type: 'page' },
        { pageNo: right, index: right - 1, type: 'page' },
        { pageNo: null, index: null, type: 'more', hiddenPages: hiddenPagesRight },
        ...rest
      ];
    }
    else {
      let [first, ...rest] = adaptiveList
      return [
        first,
        { pageNo: 2, index: 0, type: 'page' },
        ...rest
      ];
    }
  }

  getHiddenPages(start: number, length: number): any[] | string {
    let hiddenList: any[] = [];

    if (start === 0) { return 'underflow' };
    if (start > this.pagesList.length) { return 'overflow' }

    if (length < start) {
      for (let i = start; i > length; i--) {
        hiddenList.push({
          pageNo: i, index: i - 1, type: 'page'
        });
      }
    } else {
      for (let i = start; i < length; i++) {
        hiddenList.push({
          pageNo: i, index: i - 1, type: 'page'
        });
      }
    }
    return hiddenList.sort((a, b) => a.pageNo - b.pageNo);
  }
}
