import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from '../form/base-form/base-form.component';
import { applyMixins } from '../mixin/mixin';
import { FormControl } from '@angular/forms';
const Croppie = require('croppie');

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent extends BaseClass implements OnInit, DoCheck {

  cropperPlaceHolder!: HTMLElement | null;
  preview!: string;
  resize: any;
  file: any;
  validTypes: Array<string> = ['jpeg', 'png', 'webp'];

  constructor() {
    super();
  }

  @Input() fieldId: string | null = null;
  @Input() control!: FormControl;
  @Input() canvasHeight = 300;
  @Input() cropperWidth = 200;
  @Input() cropperHeight = 200;

  ngDoCheck(): void {
    super.ngDoCheck();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // initializing cropper settings
    this.cropperPlaceHolder = document.getElementById('resizer-demo');
    this.resize = new Croppie(this.cropperPlaceHolder, {
      viewport: { width: this.cropperWidth, height: this.cropperHeight },
      boundary: { height: this.canvasHeight },
      showZoomer: true,
      enableResize: false,
      enableOrientation: true,
      enableZoom: true,
      mouseWheelZoom: 'ctrl'
    });
  }

  onFileSelect($event: any): void {
    this.file = $event.target.files[0];
    const originalType = this.file.type.split('/')[1];

    // validating selected image file
    if (!this.checkForFileType(originalType)) {
      this.control.markAsTouched();
      this.control.setErrors({ 'incorrect-file-image-type': true });
    } else {
      this.preview = URL.createObjectURL($event.target.files[0]);
      this.resize.bind({
        url: this.preview,
      });

      if (this.cropperPlaceHolder) {
        // listing to zoom and reszie cropper eventss
        this.cropperPlaceHolder.addEventListener('update', (ev) => {
          this.resize.result({ type: 'base64', format: originalType }).then((base64: string) => {
            this.control.setValue({
              name: this.file.name,
              fileType: originalType,
              data: base64.split(',')[1]
            });
          });
        });
      }
    }
  }

  checkForFileType(type: string): boolean {
    return this.validTypes.find(val => val === type) ? true : false;
  }
}
