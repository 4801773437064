import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {Title} from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert/alert.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { ReportTemplateService } from '../../report-template/report-template.service';
import { StudiesService } from '../../studies/studies.service';
import * as jspdf from 'jspdf';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';

@Component({
  selector: 'app-study-report',
  templateUrl: './study-report.component.html',
  styleUrls: ['./study-report.component.scss'],
})
export class StudyReportComponent implements OnInit, OnDestroy {
  @ViewChild('pdfReport') pdfPrintable!: ElementRef;

  templateList!: any[];
  studyId!: number;
  studyDetails!: any;
  templateVariables!: any[];

  downloading = false;
  savingDraft = false;
  savingFinal = false;
  getting = false;
  studyReportForm!: FormControl;
  studyReviewForm!: FormControl;
  selectTemplateForm!: FormControl;

  studyReport!: any;
  savedStatus!: any;
  printFunc: any;

  constructor(
    public route: ActivatedRoute,
    public alertService: AlertService,
    public studyService: StudiesService,
    public reportService: ReportTemplateService,
    public settingService: SettingsService,
    public sessionStorageService: SessionStorageService,
    private titleService:Title
  ) {
    this.templateList = [];
    this.templateVariables = [];
    this.studyReportForm = new FormControl();
    this.studyReviewForm = new FormControl();
    this.selectTemplateForm = new FormControl(null, Validators.required);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((val) => {
      if (val) {
        this.studyId = val.studyId;
        this.getReport(this.studyId);
      }
    });
  }

  getReport(id: any): void {
    this.getting = true;
    this.studyService.getStudyReport(id, (res, status) => {
      if (status) {
        this.studyReport = res?.report;
        this.studyDetails = res?.study;
        this.getting = false;

        if (this.studyReport === null) {
          this.savedStatus = null;
          this.getReportTemplate();
        } else {
          // saved as draft
          if (this.studyReport.status === 'DRAFT') {
            this.savedStatus = 'DRAFT';
            this.studyReportForm.setValue(this.studyReport?.description);
            this.getReportTemplate();
          }

          // saved as final
          if (this.studyReport.status === 'FINAL') {
            this.savedStatus = 'FINAL';
            this.showReport(this.studyReport?.description);
            this.studyReportForm.setValue(this.studyReport?.description);
          }
        }
      }
    });
  }

  onRenderEdit(print: any): void {
    this.titleService.setTitle(`${this.studyDetails.patientName}-${this.studyDetails.studyDate}`)
    this.printFunc = print;
  }

  ngOnDestroy(): void {
    this.titleService.setTitle('Access Teleradiology App')
  }

  isAssignedOrReviewer(reviewerList: any[], assigned: any): string {
    if (assigned?.user?.id == this.sessionStorageService.getUserId()) {
      return 'assignee';
    } else if (
      reviewerList
        .map((t: any) => t?.user?.id)
        .includes(this.sessionStorageService.getUserId())
    ) {
      return 'reviewer';
    } else {
      return '';
    }
  }

  getTemplateVariables(): void {
    this.reportService.getReportTemplateVariables((res, status) => {
      if (status) {
        this.templateVariables = res;
        if (this.savedStatus === null) {
          const tempReport = this.templateList.find(
            (x: any) =>
              x?.medicalCenter?.id === this.studyDetails?.medicalCenter?.id
          );

          this.selectTemplateForm.setValue(tempReport || this.templateList[0]);
          this.onSelectTemplate();
        }
      }
    });
  }

  getReportTemplate(): void {
    this.reportService.getReportTemplate((res, status) => {
      if (status) {
        this.templateList = res;
        this.getTemplateVariables();
      }
    });
  }

  showReport(report: string): void {
    setTimeout(() => {
      const renderTemlplate: HTMLElement | null = document.getElementById(
        'render-signed-report'
      );
      if (renderTemlplate) {
        renderTemlplate.innerHTML = report;
      }
    });
  }

  makeReviewRequired(reviewerList: any[], assigned: any): boolean {
    if (this.isAssignedOrReviewer(reviewerList, assigned) === 'reviewer') {
      return !this.studyReviewForm.value;
    } else {
      return false;
    }
  }

  saveReport(status: string): void {
    if (status === 'DRAFT') {
      this.savingDraft = true;
    } else {
      this.savingFinal = true;
      this.studyReportForm.setValue(
        this.insertSignature(
          this.studyReportForm.value,
          this.studyDetails?.assigned_radioligist
        )
      );
    }

    this.studyService.saveStudyReport(
      {
        studyId: this.studyId,
        status: status,
        description: this.studyReportForm.value,
        reviewComment: this.studyReviewForm.value,
      },
      (res, status) => {
        this.savingDraft = false;
        this.savingFinal = false;
        this.getReport(this.studyId);
      }
    );
  }

  onSelectTemplate(): void {
    this.studyReportForm.setValue(
      this.replaceVariables(
        this.selectTemplateForm?.value?.report,
        this.studyDetails
      )
    );
  }

  confirmSelect(): void {
    this.alertService.openAlertModal(
      {
        type: 'warning',
        message:
          'Are you sure you want to proceed with this action, you will lose all changes made in your current report in the editor.',
      },
      'ok',
      (close: () => void) => {
        this.onSelectTemplate();
        close();
      }
    );
  }

  replaceVariables(report: string, studyDetails: any): string {
    let finalString = report;
    finalString = finalString.replace(
      '{{PatientName}}',
      studyDetails?.patientName || 'N/A'
    );
    finalString = finalString.replace(
      '{{PatientAge}}',
      studyDetails?.patientAge || this.getAge(studyDetails?.patientDob) || 'N/A'
    ); // get age
    finalString = finalString.replace(
      '{{PatientSex}}',
      studyDetails?.patientSex || 'N/A'
    );
    finalString = finalString.replace(
      '{{PatientID}}',
      studyDetails?.patientHospitalID || 'N/A'
    );
    finalString = finalString.replace(
      '{{Examination}}',
      studyDetails?.studyDecription || 'N/A'
    );
    finalString = finalString.replace(
      '{{PatientPhone}}',
      studyDetails?.patientPhone || 'N/A'
    );
    finalString = finalString.replace(
      '{{PatientLocation}}',
      studyDetails?.location || 'N/A'
    );
    finalString = finalString.replace(
      '{{ReferringPhysicain}}',
      studyDetails?.referringPhysician || 'N/A'
    );
    finalString = finalString.replace(
      '{{StudyDecription}}',
      studyDetails?.studyDecription || 'N/A'
    );
    finalString = finalString.replace(
      '{{MedicalFacility}}',
      studyDetails?.medicalCenter?.name || 'N/A'
    );
    finalString = finalString.replace(
      '{{RadiologistName}}',
      [
        studyDetails?.assigned_radioligist?.user?.firstName,
        studyDetails?.assigned_radioligist?.user?.lastName,
      ].join(' ')
    );
    return finalString;
  }

  insertSignature(report: string, radiologist: any): string {
    var finalString = report;
    if (radiologist?.signature === null) {
      let sign =
        radiologist?.user?.firstName[0] + '.' + radiologist?.user?.lastName[0];
      finalString = report.replace('{{RadiologistSign}}', sign);
    } else {
      let sign = `<p><img src="data:image/${radiologist?.signature?.type};base64, ${radiologist?.signature?.data}" alt="signature" /></p>`;
      finalString = report.replace('{{RadiologistSign}}', sign);
    }
    return finalString;
  }

  getAge(date: number): number {
    if (!date) return NaN;
    return Math.round(
      (new Date().getTime() - date) / (365 * 24 * 60 * 60 * 1000)
    );
  }

  exportPDF(): void {
    // create potrait A4 jspdf from html
    this.downloading = true;
    let doc = new jspdf.jsPDF();
    doc.html(this.pdfPrintable.nativeElement, {
      callback: (doc) => {
        this.downloading = false;
        doc.save(
          `${this.studyDetails.patientName}-${this.studyDetails.studyDate}.pdf`
        );
      },
      x: 15,
      y: 15,
      width: 170,
      windowWidth: 650,
    });
  }
}
