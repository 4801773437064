<!-- select without search -->
<div *ngIf="single" class="form-group">
  <label [for]="fieldId" class="label text-muted">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>
  <select [formControl]="control" [id]="fieldId" class="custom-select">
    <option [ngValue]="'select'">select</option>
    <option *ngFor="let opt of iteratableOptions" [ngValue]="opt.ngValue">{{ opt.display }}</option>
  </select>
  <app-form-validation [validationErrors]="validationErrors"></app-form-validation>
</div>

<!-- select with search -->

<!-- multi select -->
<div *ngIf="!single" class="form-group">
  <label [for]="fieldId" class="label text-muted">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>
  <input [formControl]="control" [id]="fieldId" type="text" hidden>
  <div class="multi-selector">
    <div class="selected bg-white" style="border-top-left-radius: 5px; border-top-right-radius: 5px">
      <span *ngFor="let it of multiSelected" class="bg-primary multi-item">
        {{ _findDisplayName(it) === '' ? null : _findDisplayName(it) }}
        <i (click)="_removeItem(it)" class="fas fa-times ml-2"></i>
      </span>
    </div>
    <select [formControl]="selectionList" class="custom-select">
      <option [ngValue]="'select'">select</option>
      <option style="color: green;" *ngFor="let opt of iteratableOptions" [ngValue]="opt.ngValue">{{ opt.display }}
      </option>
    </select>
  </div>
  <app-form-validation [validationErrors]="validationErrors"></app-form-validation>
</div>
<!-- multi select -->