import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';

@Injectable({
  providedIn: 'root',
})
export class StudiesService {
  constructor(
    public apiConnect: ApiConnectionService,
    public alertService: AlertService
  ) {}

  getStudies(query: any, onGet: (res: any, status: boolean) => void): void {
    this.apiConnect
      .callAPI('GET', 'RadiologistStudies', undefined, query, undefined)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  getStudyReviews(id: number, onGet: (res: any, status: boolean) => void): void {
    this.apiConnect
      .callAPI('GET', 'StudyReviews', undefined, undefined, id)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  getStudyDetails(
    id: number,
    onGet: (res: any, status: boolean) => void
  ): void {
    this.apiConnect
      .callAPI('GET', 'RadiologistStudies', undefined, undefined, id)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  getStudyReport(id: number, onGet: (res: any, status: boolean) => void): void {
    this.apiConnect
      .callAPI('GET', 'StudyReport', undefined, undefined, id)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  saveStudyReport(form: any, onGet: (res: any, status: boolean) => void): void {
    this.apiConnect
      .callAPI('POST', 'StudyReport', form, undefined, form.studyId)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
          this.alertService.setAlert({
            type: 'success',
            title: 'Success',
            message: res.message,
          });
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  updateStudyReport(
    form: any,
    id: number,
    onGet: (res: any, status: boolean) => void
  ): void {
    this.apiConnect
      .callAPI('PUT', 'StudyReport', form, undefined, id)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
          this.alertService.setAlert({
            type: 'success',
            title: 'Success',
            message: res.message,
          });
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  flagStudy(form: any, onGet: (res: any, status: boolean) => void): void {
    this.apiConnect
      .callAPI('PUT', 'FlagStudy', form, undefined, form.id)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
          this.alertService.setAlert({
            type: 'success',
            title: 'Success',
            message: res.message,
          });
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  getStudyReviewers(
    id: number,
    onGet: (res: any, status: boolean) => void
  ): void {
    this.apiConnect
      .callAPI('GET', 'GetReviewer', undefined, undefined, id)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  addReviewer(form: any, onGet: (res: any, status: boolean) => void): void {
    this.apiConnect
      .callAPI('PUT', 'AddReviewer', undefined, form, form.id)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
          this.alertService.setAlert({
            type: 'success',
            title: 'Success',
            message: res.message,
          });
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  removeReviewer(form: any, onGet: (res: any, status: boolean) => void): void {
    this.apiConnect
      .callAPI('PUT', 'RemoveReviewer', undefined, form, form.id)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
          this.alertService.setAlert({
            type: 'success',
            title: 'Success',
            message: res.message,
          });
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  unflagStudy(id: number, onGet: (res: any, status: boolean) => void): void {
    this.apiConnect
      .callAPI('PUT', 'UnFlagStudy', undefined, undefined, id)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
          this.alertService.setAlert({
            type: 'success',
            title: 'Success',
            message: res.message,
          });
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  editStudyReport(
    id: number,
    onGet: (res: any, status: boolean) => void
  ): void {
    this.apiConnect
      .callAPI('PUT', 'EditStudyReport', undefined, undefined, id)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
          this.alertService.setAlert({
            type: 'success',
            title: 'Success',
            message: res.message,
          });
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }
}
