import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { ProgressAlertService } from 'src/app/services/alert/progress-alert.service';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { Location } from '@angular/common';
import { ClientService } from './client.service';

class BaseClass { }
interface BaseClass
  extends ModalComponent,
  PaginationComponent,
  FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginationComponent, FilterComponent]);

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent extends BaseClass implements OnInit {
  elementsPerPage = 10;
  activePageList!: any[];

  showEntries!: FormControl;
  searchFilter!: string;

  clients: Array<any> = [];
  clientAETList: any[] = [];

  newClient!: FormGroup;
  editClientForm!: FormGroup;
  addAETForm!: FormGroup;

  saving = false;
  updating = false;
  addingaet = false;
  gettingClients = false;

  saveClientBtn: object = {
    method: () => {
      this.saveClient();
    },
    text: 'save',
  };

  updateClientBtn: object = {
    method: () => {
      this.updateClient();
    },
    text: 'update',
  };

  constructor(
    public modalService: BsModalService,
    public apiConnect: ApiConnectionService,
    public progress: ProgressAlertService,
    public location: Location,
    public clientService: ClientService
  ) {
    super();

    this.showEntries = new FormControl(10);
    this.filterList = ['name'];

    this.newClient = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      location: new FormControl(),
      phoneNumber: new FormControl(),
    });

    this.editClientForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      location: new FormControl(),
      phoneNumber: new FormControl(),
    });

    this.addAETForm = new FormGroup({
      id: new FormControl(),
      name: new FormControl(null, Validators.required),
      description: new FormControl()
    });
  }

  ngOnInit(): void {
    this.getClients();
  }

  getClients(): void {
    this.gettingClients = true;
    this.clientService.getMedicalCenters((res, status) => {
      this.gettingClients = false;
      if (status) {
        this.clients = res;
      }
    })
  }

  saveClient(): void {
    this.saving = true;
    this.clientService.saveMedicalCenter(this.newClient.value, (res, status) => {
      this.saving = false;
      if (status) {
        this.closeModal();
        this.getClients();
      }
    })
  }

  editClient(data: any, template: TemplateRef<any>): void {
    this.editClientForm = new FormGroup({
      id: new FormControl(data.id),
      name: new FormControl(data.name, Validators.required),
      email: new FormControl(data.email, Validators.required),
      location: new FormControl(data.location),
      phoneNumber: new FormControl(data.phoneNumber),
    });

    this.modalService.show(template);
  }

  clientAETs(data: any, template: TemplateRef<any>): void {
    this.clientAETList = data.aet;
    this.addAETForm = new FormGroup({
      id: new FormControl(data.id),
      name: new FormControl(null, Validators.required),
      description: new FormControl()
    });
    this.modalService.show(template);
  }

  getAET(id: number): void {
    this.clientService.getAETs(id, (res, status) => {
      if (status) {
        this.clientAETList = res;
      }
    });
  }

  addAET(): void {
    this.addingaet = true;
    this.clientService.addAET(this.addAETForm.value, (res, status) => {
      this.addingaet = false;
      if (status) {
        this.getAET(this.addAETForm.value.id);
      }
    });
  }

  removeAET(id: number): void {
    this.clientService.removeAET(id, (res, status) => {
      if (status) {
        this.getAET(this.addAETForm.value.id)
      }
    });
  }

  updateClient(): void {
    this.updating = true;
    this.clientService.updateMedicalCenter(
      this.editClientForm.value.id, this.editClientForm.value,
      (res, status) => {
        this.updating = false;
        if (status) {
          this.closeModal();
          this.getClients();
        }
      })
  }

  activateClient(id: number): void {
    this.clientService.activateMedicalCenter(id, (res, status) => {
      if (status) {
        this.getClients();
      }
    });
  }

  deActivateClient(id: number): void {
    this.clientService.deActivateMedicalCenter(id, (res, status) => {
      if (status) {
        this.getClients();
      }
    });
  }

  confirmDelete(): void {

  }

  deleteClient(id: number): void {
    this.clientService.deleteMedicalCenter(id, (res, status) => {
      if (status) {
        this.getClients();
      }
    });
  }

  paginatorEvent(change: any): void {
    setTimeout(() => {
      this.activePageList = change.currentChunk;
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['name'];
    }
  }

  back(): void {
    this.location.back();
  }

  customFormControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }
}
