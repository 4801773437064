import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { ChatNotificationState, ChatNotificationStore } from "./chat-notification.store";


@Injectable({ providedIn: 'root' })
export class ChatNotificationQuery extends QueryEntity<ChatNotificationState>{
    constructor(
        protected store: ChatNotificationStore
    ) {
        super(store);
    }
}