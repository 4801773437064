import { Injectable } from '@angular/core';
import { TOKEN } from './constants';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  setToken(token: string): void {
    sessionStorage.setItem(TOKEN, token);
  }

  getToken(): string | null {
    return sessionStorage.getItem(TOKEN);
  }

  getUserApplicationType(): string {
    const data = this.decodeToken(this.getToken());
    return data ? data.applicationType : null;
  }

  getRegistrationToken(): string {
    const data = this.decodeToken(this.getToken());
    return data ? data.registrationToken : null;
  }

  getUserPermissions(): Array<any> {
    const data = this.decodeToken(this.getToken());
    return data ? data.permissions : null;
  }

  getUserId(): number {
    const data = this.decodeToken(this.getToken());
    return data ? data.userId : null;
  }

  getUserRole(): string {
    const data = this.decodeToken(this.getToken());
    return data ? data.userRole : null;
  }

  decodeToken(token: string | null): any {
    return token ? JSON.parse(atob(token.split('.')[1])) : null;
  }
}
