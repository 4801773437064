import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConnectionQuery } from 'src/app/store/connection/connection.query';
import { ConnectionStore } from 'src/app/store/connection/connection.store';
import { AlertService } from '../alert/alert.service';
import { ApiConnectionService } from '../api/apiconnection.service';
import { SessionStorageService } from '../session/session-storage.service';
import { IConnection } from './iconnection.interface';

interface IEventSubject {
  topic: string;
  payload: any;
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  conn!: WebSocket;
  retryTimes = 0;
  eventListener: BehaviorSubject<IEventSubject | null> =
    new BehaviorSubject<IEventSubject | null>(null);

  constructor(
    public alertService: AlertService,
    public apiConnect: ApiConnectionService,
    public connectionStore: ConnectionStore,
    public connectionQuery: ConnectionQuery,
    public sessionService: SessionStorageService,
    @Inject('ConnectionConfig') private connectionConfig: IConnection
  ) {
    this.createWebSocketConnection();
  }

  subcribeToTopic(topic: string, prefix?: string): Subscription {
    return this.connectionQuery
      .getConnectionState()
      .pipe(
        tap(
          (isConnected) =>
            isConnected && this.subcribeToTopicConn(topic, prefix)
        )
      )
      .subscribe();
  }

  unSubcribeToTopic(topic: string): Subscription {
    return this.connectionQuery
      .getConnectionState()
      .pipe(
        tap((isConnected) => isConnected && this.unSubcribeToTopicConn(topic))
      )
      .subscribe();
  }

  sendMessage<T>(topic: string, data: T): Subscription {
    return this.connectionQuery
      .getConnectionState()
      .pipe(
        tap((isConnected) => isConnected && this.sendMessageConn(topic, data))
      )
      .subscribe();
  }

  onMessageSub<T>(topic: string, callback: (data?: T) => void): Subscription {
    return this.eventListener
      .pipe(
        tap((data: IEventSubject | null) => {
          if (data?.topic === topic) callback(data.payload);
        })
      )
      .subscribe();
  }

  closeConnection(): Subscription {
    return this.connectionQuery
      .getConnectionState()
      .pipe(tap((isConnected) => isConnected && this.closeConnectionConn()))
      .subscribe();
  }

  updateRetryTimes(): void {
    this.retryTimes++;
  }

  tranfromTopic(topic: string, prefix?: string): string {
    let temp = prefix ? prefix : this.sessionService.getRegistrationToken();
    return temp + '/' + topic;
  }

  private createWebSocketConnection(): void {
    this.apiConnect
      .callAPI('GET', 'WebSocketKey', undefined, undefined, undefined)
      .subscribe(
        (res: any) => {
          setTimeout(() => {
            // create connection
            this.startConn(res);

            // on open connection
            this.onOpen();

            // on message
            this.onMessage();

            // on close connection
            this.onClose();
          }, 5000);
        },
        (err: any) => {
          this.onError(err);
        }
      );
  }

  private startConn(res: any): void {
    this.conn = new WebSocket(
      `${this.connectionConfig.url}?api_token=${res.key}`
    );
  }

  private onOpen(): void {
    this.conn.onopen = (e) => {
      this.connectionStore.update({ isConnected: true });
      console.info('WS Connection established!');
    };
  }

  private onClose(): void {
    this.conn.onclose = (e) => {
      this.connectionStore.update({ isConnected: false });
      console.info('WS Connection closed.');
    };
  }

  private onMessage(): void {
    this.conn.onmessage = (e) => {
      const data = JSON.parse(e.data);
      this.eventListener.next(data);
    };
  }

  private onError(err: any): void {
    this.alertService.setAlert({
      type: 'danger',
      title: 'Error',
      message: err.message,
    });
  }

  private subcribeToTopicConn(topic: string, prefix?: string): void {
    const temp = prefix ? prefix : this.sessionService.getRegistrationToken();
    this.conn?.send(
      JSON.stringify({
        channel: 'subscribe',
        data: {
          topic: temp + '/' + topic,
          payload: null,
        },
      })
    );
  }

  private unSubcribeToTopicConn(topic: string): void {
    this.conn?.send(
      JSON.stringify({ channel: 'unsubscribe', data: { topic } })
    );
  }

  private sendMessageConn<T>(topic: string, data: T): void {
    this.conn?.send(
      JSON.stringify({ channel: 'publish', data: { topic, data } })
    );
  }

  private closeConnectionConn(): void {
    this.conn?.close();
  }
}
