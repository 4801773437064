import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NotificationState, NotificationStore } from './notification.store';

@Injectable({ providedIn: 'root' })
export class NotificationQuery extends QueryEntity<NotificationState> {
  constructor(protected notificationStore: NotificationStore) {
    super(notificationStore);
  }

  updatingStatus(): Observable<boolean> {
    return this.select((state) => state.updating);
  }

  unreadCount(): Observable<number> {
    return this.selectAll()
      .pipe(
        map((notifications) =>
          notifications.filter(
            (notification) => notification.status === 'unread'
          )
        )
      )
      .pipe(map((notifications) => notifications.length));
  }

  getUnreadNotifications(): Observable<any[]> {
    return this.selectAll().pipe(
      map((notifications) =>
        notifications.filter((notification) => notification.status === 'unread')
      )
    );
  }

  getAllNotifications(): Observable<any[]> {
    return this.selectAll();
  }
}
