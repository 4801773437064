import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressAlertService {
  public progressObservable = new BehaviorSubject<any>({});
  public progressMessage = this.progressObservable.asObservable();

  setAlert(alert: any): void {
    this.progressObservable.next(alert);
  }
}
