import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OffcanvasService } from 'src/app/services/offcanvas/offcanvas.service';
import { NotificationQuery } from 'src/app/store/notification/notification.query';
import { DocNotificationService } from '../../doc/doc-notification/doc-notification.service';

@Component({
  selector: 'app-hos-notification',
  templateUrl: './hos-notification.component.html',
  styleUrls: ['./hos-notification.component.scss'],
})
export class HosNotificationComponent implements OnInit, OnDestroy {
  notifications$!: Observable<any[]>;
  unReadNotificationsSub!: Subscription;

  constructor(
    public router: Router,
    public offcanvas: OffcanvasService,
    public notificationQuery: NotificationQuery,
    public docNotification: DocNotificationService
  ) {}

  ngOnInit(): void {
    this.markAsRead();

    this.notifications$ = this.notificationQuery.getAllNotifications();
  }

  markAsRead(): void {
    this.unReadNotificationsSub = this.notificationQuery
      .getUnreadNotifications()
      .pipe(
        tap((notifications) => {
          // unread notification ids
          const ids = notifications.map((notification) => notification.id);

          // mark as read if list not empty
          if (ids.length > 0) {
            this.docNotification.markAsRead(ids, () => {
              this.unReadNotificationsSub?.unsubscribe();
            });
          }
        })
      )
      .subscribe();
  }

  formatTime(date: number): string {
    const time: Moment = moment(new Date(date));
    return time.calendar();
  }

  deleteNotification(id: number): void {
    this.docNotification.deleteNotification(id);
  }

  openReport(id: number): void {
    this.router.navigate(['/app/hos/study-details'], {
      queryParams: { page: 'study-details', studyId: id, type: 'report' },
    });
    this.offcanvas.close();
  }

  ngOnDestroy(): void {
    this.unReadNotificationsSub?.unsubscribe();
  }
}
