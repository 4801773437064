<!-- header -->
<div class="app-header-inner">
  <div class="container-fluid py-2">
    <div class="app-header-content">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <a href="javascript:void(0);" id="sidepanel-toggler"
            class="sidepanel-toggler d-inline-block d-xl-none text-primary">
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
              role="img">
              <title>Menu</title>
              <path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"
                d="M4 7h22M4 15h22M4 23h22"></path>
            </svg>
          </a>
        </div>

        <div class="app-utilities col-auto">
          <!-- fullscreen -->
          <div class="app-utility-item">
            <a href="javascript:void(0);" *ngIf="!fullScreen" (click)="maximizeWindow()" title="full-screen">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="bi bi-gear icon" fill="currentColor"
                class="bi bi-fullscreen" viewBox="0 0 16 16">
                <path
                  d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
              </svg>
            </a>

            <a href="javascript:void(0);" *ngIf="fullScreen" (click)="minimizeWindow()" title="full-screen">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="bi bi-gear icon" fill="currentColor"
                class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
                <path
                  d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z" />
              </svg>
            </a>
          </div>

          <!-- notifications -->
          <div class="app-utility-item app-notifications-dropdown notification dropdown">
            <app-system-notification></app-system-notification>
          </div>

          <!-- account -->
          <div class="app-utility-item app-user-dropdown dropdown">
            <a href="javascript:void(0);" class="dropdown-toggle" id="user-drop-toggle" data-toggle="dropdown"
              role="button" aria-expanded="false">
              <img
                [src]="userImage ? assetsRoot + userImage.url : 'assets/images/default.png'"
                alt="user profile">
            </a>

            <div class="dropdown-menu dropdown-menu-right" style="margin-top: 10px; right: 14px;">
              <div class="dropdown-header">
                <h6 class="text-overflow m-0" style="font-size: 0.76rem; color: #343a40;">Welcome !</h6>
              </div>

              <a class="dropdown-item" href="JavaScript:void(0);" [routerLink]="['/app/account']">
                <i class="bi bi-person"></i>
                <span class="ml-2">My Account</span>
              </a>

              <div>
                <hr class="dropdown-divider">
              </div>

              <a class="dropdown-item" href="JavaScript:void(0);" (click)="logout()">
                <i class="bi bi-box-arrow-right"></i>
                <span class="ml-2">Logout</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- header -->