import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ChatNotificationService } from './store/chat-notification.service';
import { ChatNotificationQuery } from './store/chat-notification.query';
import { ChatNotification } from './store/notification.interface';
import * as Moment from 'moment';
import { Router } from '@angular/router';

@Component({
    selector: 'app-chat-notification',
    templateUrl: './chat-notification.component.html',
    styleUrls: ['./chat-notification.component.scss']
})
export class ChatNotificationComponent implements OnInit {

    notificationList!: ChatNotification[]
    assetsURL = environment.imageUrl

    constructor(
        private router: Router,
        private chatNotificationQuery: ChatNotificationQuery,
        private chatNotificationService: ChatNotificationService
    ) { }

    ngOnInit(): void {
        this.chatNotificationQuery.selectAll()
            .subscribe((res: ChatNotification[]) => {
                this.notificationList = res.filter(m => m.isRead === false);
            });
    }

    getNotifications(): void {
        this.chatNotificationService.getUserNotifications();
    }

    duration(time: string): string {
        return Moment(new Date(+time)).fromNow();
    }

    openStudyChat(id: number, notificationId: number): void {
        this.router.navigate(['/app/doc/studies/details'], { queryParams: { page: 'study-details', studyId: id } });
        this.chatNotificationService.markAsRead(notificationId);
    }
}
