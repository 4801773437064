export const HospitalEndpoints = {
  // study
  SearchStudy: 'study/raw-studies',
  MedicalCenterStudy: 'medical-center-studies',
  MedicalStudyAttachments: 'medical-center-studies/add-attachment',
  MedicalRemoveAttachment: 'medical-center-studies/remove-attachment',

  // client
  Client: 'client',
  ActivateClient: 'client/activate',
  DeActivateClient: 'client/de-activate',

  // dashboard
  DashboardHosCardSummary: 'dashboard/medical-center/summary-cards',
  DashboardHosPriorityDistribution: 'dashboard/medical-center/study-level-distribution',
  DashboardHosWeeklyDistribution: 'dashboard/medical-center/study-distribution-by-day',
  DashboardHosInProgress: 'dashboard/medical-center/studies-in-progress'
};
