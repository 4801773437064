import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisableLinkDirective } from '../directives/disable-link.directive';

@NgModule({
    declarations: [
        DisableLinkDirective
    ],
    imports: [CommonModule],
    exports: [
        DisableLinkDirective
    ],
    providers: [],
})
export class DirectiveModule { }