<div class="row">
    <div class="col-lg-12">
        <span class="text-primary font-weight-bold">Studies In Progress</span>
        <div class="table-card shadow-sm">
            <div class="table-card-body">
                <div class="table-responsive mt-3">
                    <table class="table themed-table table-sticky-column themed-table-plain">
                        <thead>
                            <tr>
                                <th class="cell stick">
                                    <div class="cell-border">
                                        <span>Patient Name</span>
                                    </div>
                                </th>
                                <th class="cell">
                                    <div class="cell-border justify-content-center">
                                        <span>Assiged Radiologist</span>
                                    </div>
                                </th>
                                <th class="cell">
                                    <div class="cell-border justify-content-center">
                                        <span>Opened At</span>
                                    </div>
                                </th>
                                <th class="cell text-center">
                                    <div class="cell-border text-center">
                                        <span>Action</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let study of inProgressList">
                                <td>{{ study.patientName }}</td>
                                <td class="text-center text-primary">{{ [study?.assigned_radioligist?.user?.firstName,
                                    study?.assigned_radioligist?.user?.lastName].join(' ') }}</td>
                                <td class="text-center">{{ study?.openedAt | customdateformat:'slashDate' }}</td>
                                <td class="text-center">
                                    <a href="javascript:void(0)" (click)="openStudy(study.id)" class="table-action-btn arrow-none btn btn-light btn-xs">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>