import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ConnectionStore, ConnectionState } from './connection.store';

@Injectable({ providedIn: 'root' })
export class ConnectionQuery extends Query<ConnectionState> {
  constructor(protected store: ConnectionStore) {
    super(store);
  }

  getConnectionState(): Observable<boolean> {
    return this.select((state) => state.isConnected);
  }
}
