<div class="row g-3 mb-4 align-items-center justify-content-between">
  <div class="col-auto">
    <h1 class="app-page-title mb-0 text-muted">Edit Template</h1>
  </div>

  <div class="col-auto">
    <div class="page-utilities">
      <div
        class="row g-2 justify-content-start justify-content-md-end align-items-center"
      >
        <div class="col-auto">
          <div class="table-search-form row gx-1 align-items-center">
            <div class="col-auto">
              <button (click)="back()" type="submit" class="btn btn-primary">
                <i class="bi bi-arrow-left"></i>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mx-0">
  <div class="d-flex w-100">
    <div class="app-card app-card-orders-table shadow-sm mb-5 w-100">
      <div class="app-card-body">
        <form
          action=""
          class="my-4"
          [ngClass]="{ 'd-none': showPreview }"
          [formGroup]="editReportTemplateForm"
        >
          <div class="row mb-3">
            <div class="col-lg-12">
              <label for="">Template Variables</label>
              <ul class="variable-list w-100">
                <ng-container
                  *ngFor="let varl of templateVariables; let i = index"
                >
                  <li
                    [attr.id]="'var-trigger' + i"
                    class="variable-item d-flex align-items-center"
                  >
                    <span style="font-size: 13px; color: #5c5a5a">{{
                      varl.name
                    }}</span>
                    <span
                      [attr.id]="'var' + i"
                      class="copy position-relative ml-auto"
                      (click)="copyVariable(varl.tempString, i)"
                    >
                      <em [attr.id]="'vari' + i" class="bi bi-clipboard"></em>
                      <small class="position-absolute text-warning"
                        >copied</small
                      >
                    </span>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-12">
              <app-input-editor
                [editorHeight]="500"
                [control]="customFormControl('report', editReportTemplateForm)"
                [fieldId]="'normal'"
              >
              </app-input-editor>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <app-input
                [control]="
                  customFormControl('description', editReportTemplateForm)
                "
                [fieldId]="'description'"
              >
              </app-input>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="" class="label text-muted">Medical Center </label>
                <select
                  [formControl]="
                    customFormControl('medicalCenterId', editReportTemplateForm)
                  "
                  class="form-control custom-select custom-select-sm"
                  name="medicalCenterId"
                >
                  <option [ngValue]="null">Select</option>
                  <option *ngFor="let client of clients" [ngValue]="client.id">
                    {{ client?.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <button
                (click)="updateTemplate()"
                [disabled]="editReportTemplateForm.invalid"
                class="btn btn-primary float-right"
              >
                <i class="bi bi-save"></i>
                update
              </button>
              <button
                (click)="previewTemplate()"
                class="btn btn-secondary float-right mr-2"
              >
                <i class="bi bi-eye"></i>
                preview
              </button>
            </div>
          </div>
        </form>

        <div class="row mb-3" [ngClass]="{ 'd-none': !showPreview }">
          <div class="col-12 d-flex align-items-center">
            <h6 class="mb-0">Template Preview</h6>
            <button (click)="closePreview()" class="ml-auto btn btn-light">
              <em class="bi bi-x-lg text-danger mr-2"></em>
              close
            </button>
          </div>
        </div>

        <div class="row" [ngClass]="{ 'd-none': !showPreview }">
          <div class="col-12">
            <div id="render-template"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
