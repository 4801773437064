<div class="permissions">
  <h6 class="text-muted font-weight-bold">Permissions</h6>
  <p style="font-size: 14px;">
    Define the permissions faster by selecting a group or go through the list below
  </p>

  <div class="d-flex align-items-center" *ngIf="!userDetails?.isAdmin">
    <button (click)="openModal(jsonEditPermission)" class="btn btn-sm btn-white mr-1 text-primary font-weight-normal">
      <i class="bi bi-braces mr-1"></i>
      JSON Edit
    </button>

    <button *ngIf="update" (click)="updatePermissions()" class="btn btn-sm btn-primary ml-auto">
      <i class="bi bi-arrow-up-circle mr-1"></i>Update
    </button>

    <button *ngIf="update" (click)="cancelUpdate()" class="btn btn-sm btn-light ml-2">
      <i class="bi bi-x-lg text-danger"></i>
    </button>
  </div>

  <div class="mt-3" *ngIf="userPermissionsForm">
    <div *ngIf="loadingPermissions">
      <div class="spinner-border text-primary spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div *ngIf="!loadingPermissions" class="form-check">
      <input [formControl]="selectAllFormControl" class="form-check-input" type="checkbox" value="" id="All" />
      <label class="form-check-label ml-3" for="All">All
        <i class="bi bi-exclamation-circle text-primary tooltip-wrapper">
          <span class="tooltip" style="width: 150px;">
            Allow all permission in all groups
          </span>
        </i>
      </label>
    </div>

    <div *ngIf="!loadingPermissions" class="permission-groups mt-3">
      <div *ngFor="let group of userPermissionsForm.controls; let i = index;"
        class="permissions border-bottom pb-3 mb-3">
        <!-- permission group -->
        <div class="row mx-0">
          <div class="form-check col-4">
            <input [formControl]="getGroupControl(i)" class="form-check-input" type="checkbox" value="" id="Payments" />
            <label class="form-check-label ml-3" for="Payments">
              {{ group.value.name }}
            </label>
          </div>
          <div class="col-8">{{ group.value.description }}</div>
        </div>
        <!-- permission group -->

        <!-- access list -->
        <div *ngFor="let item of getNestForm(i).controls; let y = index" class="row mx-0 mt-3">
          <div class="form-check col-4" style="padding-left: 50px;">
            <input [formControl]="getItemAccessControl(i,y, 'access')" class="form-check-input" type="checkbox" value=""
              id="Payements" />
            <label class="form-check-label ml-3" for="Payments">
              {{ getItemAccessControl(i,y, 'name').value }}
            </label>
          </div>
          <div class="col-8">{{ getItemAccessControl(i,y, 'description').value }}</div>
        </div>
        <!-- access list -->
      </div>
    </div>
  </div>
</div>

<!-- json edit permissions -->
<ng-template #jsonEditPermission>
  <app-modal [size]="'long'" [modalActionButton]="savePermissionBtn" [modalTitle]="'Raw Edit Permission'"
    [loading]="updating" [condition]="borderValidationClass !== 'border-success' || updateJsonPermissions === null">
    <app-json-editor [text]="stringfiyJson(userPermissionsForm.value)" [borderValidationClass]="borderValidationClass"
      (textChange)="onRawTextChange($event)"></app-json-editor>
  </app-modal>
</ng-template>
<!-- json edit permissions -->