import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UserService } from 'src/app/pages/settings/user-permission/user.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent implements OnInit {
  userImage!: any;
  fullScreen = false;
  assetsRoot = environment.imageUrl;

  constructor(
    public authService: AuthenticationService,
    public userService: UserService,
    public sessionService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.getUserImage();
    this.userService.userImageSubject.subscribe((val) => {
      if (val === 'updated') {
        this.getUserImage();
      }
    });
  }

  getUserImage(): void {
    this.userService.getUserImage(
      this.sessionService.getUserId(),
      (res, status) => {
        if (status) {
          this.userImage = res;
        }
      }
    );
  }

  maximizeWindow(): void {
    this.fullScreen = true;
    const window = document.getElementById('main-window');

    if (window) {
      window.requestFullscreen();
    }
  }

  minimizeWindow(): void {
    this.fullScreen = false;
    document.exitFullscreen();
  }

  logout(): void {
    this.authService.logout();
  }
}
