<div class="row">
    <div class="col-auto">
        <h6 class="text-muted font-weight-bold">Client Settings</h6>
    </div>
</div>

<div class="row">
    <div class="col-md-6" *ngIf="clientDetails">
        <div class="item border-bottom py-3">
            <div class="row justify-content-between align-items-center">
                <div class="col-auto">
                    <div class="item-label"><strong>Name</strong></div>
                    <div class="item-data">
                        {{ clientDetails?.name }}
                    </div>
                </div>
                <div class="col text-right">
                    <a (click)="editDetails(updateClientsDetailsModal)" class="btn-sm app-btn-secondary"
                        href="javascript:void(0);">
                        Change
                    </a>
                </div>
            </div>
        </div>

        <div class="item border-bottom py-3">
            <div class="row justify-content-between align-items-center">
                <div class="col-auto">
                    <div class="item-label"><strong>Email</strong></div>
                    <div class="item-data">
                        {{ clientDetails?.email }}
                    </div>
                </div>
                <div class="col text-right">
                    <a (click)="editDetails(updateClientsDetailsModal)" class="btn-sm app-btn-secondary"
                        href="javascript:void(0);">
                        Change
                    </a>
                </div>
            </div>
        </div>

        <div class="item border-bottom py-3">
            <div class="row justify-content-between align-items-center">
                <div class="col-auto">
                    <div class="item-label"><strong>Phone</strong></div>
                    <div class="item-data">
                        {{ clientDetails?.phoneNumber }}
                    </div>
                </div>
                <div class="col text-right">
                    <a (click)="editDetails(updateClientsDetailsModal)" class="btn-sm app-btn-secondary"
                        href="javascript:void(0);">
                        Change
                    </a>
                </div>
            </div>
        </div>

        <div class="item py-3">
            <div class="row justify-content-between align-items-center">
                <div class="col-auto">
                    <div class="item-label"><strong>Address</strong></div>
                    <div class="item-data">
                        {{ clientDetails?.location }}
                    </div>
                </div>
                <div class="col text-right">
                    <a (click)="editDetails(updateClientsDetailsModal)" class="btn-sm app-btn-secondary"
                        href="javascript:void(0);">
                        Change
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- update client details -->
<ng-template #updateClientsDetailsModal>
    <app-modal [condition]="clientDetailsForm.invalid" [loading]="updatingDetails"
        [modalTitle]="'Update Client Details'" [modalActionButton]="updateDetailsBtn">
        <form action="" [formGroup]="clientDetailsForm">
            <app-input [fieldId]="'name'" [control]="customFormControl('name')"></app-input>
            <app-input [fieldId]="'email'" [control]="customFormControl('email')"></app-input>
            <app-input [fieldId]="'phone'" [control]="customFormControl('phoneNumber')"></app-input>
            <app-input [fieldId]="'address'" [control]="customFormControl('location')"></app-input>
        </form>
    </app-modal>
</ng-template>
<!-- update client details -->