import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NewStudyService } from './new-study.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ScanTypeService } from '../../settings/scan-types/scan-types.service';

@Component({
  selector: 'app-new-study',
  templateUrl: './new-study.component.html',
  styleUrls: ['./new-study.component.scss'],
})
export class NewStudyComponent implements OnInit {
  studyRequestForm!: FormGroup;
  selectStudyDetails: any;
  scanTypeList!: Array<any>;

  levels: Array<string> = ['Normal', 'Priority', 'Stat'];

  gender: Array<string> = ['Male', 'Female'];

  saving = false;
  formDisabled = true;

  constructor(
    public location: Location,
    public newStudyService: NewStudyService,
    public scanTypeService: ScanTypeService
  ) {
    this.scanTypeList = [];

    this.studyRequestForm = new FormGroup({
      location: new FormControl(null),
      patientName: new FormControl(null, Validators.required),
      patientGender: new FormControl(null, Validators.required),
      studyDate: new FormControl(null, Validators.required),
      level: new FormControl(null, Validators.required),
      patientDob: new FormControl(null),
      patientId: new FormControl(null),
      referringPhysician: new FormControl(null),
      description: new FormControl(null),
      modality: new FormControl(null),
      scanType: new FormControl(null),
      clinicalHistory: new FormControl(null),
      age: new FormControl(null),
      patientPhone: new FormControl(null),
      attachedStudy: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.getScanTypes();
  }

  getScanTypes(): void {
    this.scanTypeService.getScanType((res, status) => {
      if (status) {
        this.scanTypeList = res;
      }
    });
  }

  getDicomStudy($event: any): void {
    this.selectStudyDetails = $event;
    this.openStudyForm($event);
  }

  openStudyForm(studyDetails: any): void {
    this.formDisabled = false;
    this.customFormControl('patientName').setValue(studyDetails.patientName);
    this.customFormControl('patientGender').setValue(studyDetails.patientSex);
    this.customFormControl('patientDob').setValue(+studyDetails.patientDob);
    this.customFormControl('patientId').setValue(studyDetails.patientID);
    this.customFormControl('studyDate').setValue(+studyDetails.studyDate);
    this.customFormControl('description').setValue(
      studyDetails.studyDescription
    );
    this.customFormControl('attachedStudy').setValue(studyDetails);
  }

  saveStudy(): void {
    this.saving = true;
    this.newStudyService.saveStudy(
      this.studyRequestForm.value,
      (res, status) => {
        this.saving = false;
        if (status) {
          this.studyRequestForm = new FormGroup({
            location: new FormControl(null),
            patientName: new FormControl(null, Validators.required),
            patientGender: new FormControl(null, Validators.required),
            patientDob: new FormControl(null),
            patientId: new FormControl(null),
            studyDate: new FormControl(null, Validators.required),
            referringPhysician: new FormControl(null),
            level: new FormControl(null, Validators.required),
            description: new FormControl(null),
            modality: new FormControl(null),
            clinicalHistory: new FormControl(null),
            age: new FormControl(null),
            scanType: new FormControl(null),
            patientPhone: new FormControl(null),
            attachedStudy: new FormControl(null),
          });
          this.formDisabled = true;
        }
      }
    );
  }

  customFormControl(controlName: string): FormControl {
    return this.studyRequestForm.get(controlName) as FormControl;
  }

  back(): void {
    this.location.back();
  }
}
