<div *ngIf="!getting" [hidden]="savedStatus === 'FINAL'" class="col-lg-12 px-0">
  <div class="row mx-0 mb-3 align-items-center">
    <div class="mr-2" style="min-width: 250px">
      <div class="form-group mb-0">
        <select class="form-control custom-select" [formControl]="selectTemplateForm">
          <option [ngValue]="null"></option>
          <option *ngFor="let temp of templateList" [ngValue]="temp">
            {{ temp.description }}
          </option>
        </select>
      </div>
    </div>
    <div>
      <button (click)="confirmSelect()" [disabled]="selectTemplateForm.invalid" class="btn btn-primary">
        use template
      </button>
    </div>

    <div class="ml-auto">
      <button (click)="saveReport('DRAFT')" [disabled]="studyReportForm.invalid" class="btn btn-secondary mr-2">
        <i *ngIf="!savingDraft" class="bi bi-file-earmark-arrow-down mr-2"></i>
        <span *ngIf="savingDraft" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ savingDraft ? "saving..." : "save as draft" }}
      </button>

      <button (click)="saveReport('FINAL')"
        [disabled]="studyReportForm.invalid || makeReviewRequired(studyDetails?.studyReviewers, studyDetails?.assigned_radioligist)"
        class="btn btn-primary">
        <i *ngIf="!savingFinal" class="bi bi-check mr-1"></i>
        <span *ngIf="savingFinal" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ savingFinal ? "saving..." : "submit" }}
      </button>
    </div>
  </div>

  <div
    *ngIf="studyReport !== null && isAssignedOrReviewer(studyDetails?.studyReviewers, studyDetails?.assigned_radioligist) === 'reviewer'"
    class="mt-3 w-100 mb-3">
    <app-input-textarea [required]="true" [fieldId]="'reviewComment'" [control]="studyReviewForm" [rows]="4"
      [cols]="2"></app-input-textarea>
  </div>

  <app-input-editor (printEvent)="onRenderEdit($event)" [editorHeight]="800" [control]="studyReportForm"
    [fieldId]="'report'">
  </app-input-editor>

</div>

<div class="col-12" *ngIf="getting">
  <app-loading-placeholder [bgColor]="'text-primary'"></app-loading-placeholder>
</div>

<div [hidden]="savedStatus !== 'FINAL'" class="col-lg-12 px-0">
  <div class="h-100 group">
    <label for="" class="group-label">Signed Report</label>
    <button (click)="printFunc()" class="print-btn">
      <em *ngIf="!downloading" class="bi bi-printer"></em>
      <div *ngIf="downloading" class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </button>
    <div class="w-100 d-block" id="render-signed-report" #pdfReport=""></div>
  </div>
</div>