export const DoctorEndpoints = {
  // medical center
  MedicalCenter: 'medical-center',
  MedicalCenterActivate: 'medical-center/activate',
  MedicalCenterDeactivate: 'medical-center/deactivate',
  MedicalCenterAddAET: 'medical-center/add-aet',
  MedicalCenterRemoveAET: 'medical-center/remove-aet',
  MedicalCenterGetAET: 'medical-center/get-aet',
  MedicalCenterByUser: 'medical-center/user',

  // radiologist
  Radiologist: 'radiologist',
  ActivateRadiologist: 'radiologist/activate',
  DeActivateRadiologist: 'radiologist/deactivate',
  RadiologistStudies: 'radiologist-studies',
  StudyReport: 'study-report',
  EditStudyReport: 'study-report/edit',
  FlagStudy: 'medical-center-studies/flag-study',
  UnFlagStudy: 'medical-center-studies/unflag-study',

  // manage pool
  ManagePool: 'pool-studies',
  AssignStudy: 'pool-studies/assign-to-radiologist',
  ReturnStudy: 'pool-studies/return-to-pool',
  GetReviewer: 'get-study-reviewer',
  AddReviewer: 'add-study-reviewer',
  RemoveReviewer: 'remove-study-reviewer',
  StudyReviews: 'get-study-reviews',

  // dashboard
  DashboardCardSummary: 'dashboard/radiologist/summary-cards',
  DashboardPriorityDistribution:
    'dashboard/radiologist/study-level-distribution',
  DashboardWeeklyDistribution:
    'dashboard/radiologist/study-distribution-by-day',
  DashboardMedicalCenterStudies:
    'dashboard/radiologist/medical-center-study-distribution',

  // report template
  ReportTemplate: 'report-template',
  ReportTemplateVariables: 'report-template/variables',
  ReportTemplateMakeDefault: 'report-template/make-default',

  // archive studies
  ArchiveStudies: 'study/archive',
};
