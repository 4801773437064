<div class="row g-3 mb-4 align-items-center justify-content-between">
  <div class="col-auto">
    <h1 class="app-page-title mb-0 text-muted">Archive Studies</h1>
  </div>
  <div class="col-auto">
    <div class="page-utilities">
      <div class="row g-2 justify-content-start justify-content-md-end align-items-center">
        <div class="col-auto">
          <form class="table-search-form row gx-1 align-items-center">
            <div class="col-auto">
              <button (click)="back()" type="submit" class="btn btn-primary">
                <i class="bi bi-arrow-left"></i>
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mx-0">
  <div class="d-flex w-100">
    <div class="app-card app-card-orders-table shadow-sm mb-3 w-100">
      <div class="app-card-body">
        <div class="d-flex justify-content-between align-items-center">
          <!-- search -->
          <form action="" class="search-bar">
            <div class="position-relative">
              <input name="search" type="text" class="form-control form form-control-light" [(ngModel)]="searchFilter"
                placeholder="Search ..." />
              <span class="bi bi-search"></span>
            </div>
          </form>
          <span class="ml-1">
            <select [formControl]="showEntries" class="form-control custom-select custom-select-sm"
              name="number-select">
              <option [ngValue]="5">5</option>
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
            </select>
          </span>
          <!-- search -->

          <!-- loader -->
          <div *ngIf="gettingStudies" class="ml-2 text-primary">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </div>
          <!-- loader -->

          <!-- assign -->
          <div class="ml-auto">
            <div *ngIf="assignmentList.length > 0">
              <button (click)="archiveStudies()" [disabled]="archiving" class="btn btn-secondary btn-sm" type="button">
                <span *ngIf="!archiving">Archive ({{ assignmentList.length }})</span>

                <div *ngIf="archiving" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>

          <!-- actions -->
          <div class="ml-2">
            <button *ngIf="openFilter" (click)="clearFilters()" type="submit"
              class="btn btn-sm btn-white text-muted mr-2">
              <i class="bi bi-x text-danger mr-1"></i>
              clear
            </button>

            <button *ngIf="!openFilter" (click)="openBackendFilter()" type="submit" class="btn btn-sm btn-primary">
              <i class="bi bi-sliders"></i>
            </button>

            <button *ngIf="openFilter" (click)="clearBackendFilter()" type="submit" class="btn btn-sm btn-primary">
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
          <!-- actions -->
        </div>

        <div id="backend-filter" class="row align-items-end d-none mt-3">
          <div class="col-3">
            <app-input-date [borderClass]="'border-primary'" [singleDate]="false" [fieldId]="'studyDate'"
              [control]="customFormControl('studyDate')"></app-input-date>
          </div>

          <div class="col-3">
            <app-input-select-search *ngIf="clients.length > 0" [borderClass]="'border-primary'"
              [uniqueClass]="'client'" [options]="clients" [predicate]="clientsPredicate" [fieldId]="'facility'"
              [control]="customFormControl('facility')">
            </app-input-select-search>
          </div>

          <div class="col-3 ml-auto">
            <div class="input-group mb-3">
              <input [formControl]="searchByStudyIdForm" type="text" class="form-control form-control-sm border-primary"
                placeholder="Study ID ..." />
              <div class="input-group-append">
                <button [disabled]="searchByStudyIdForm.invalid" (click)="searchStudyById()"
                  class="btn btn-primary btn-sm" type="button">
                  search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div *ngIf="studies.length > 0" class="table-card shadow-sm">
      <div class="table-card-body">
        <div class="table-responsive mt-3">
          <table class="table themed-table table-sticky-column themed-table-plain">
            <thead class="table-light">
              <tr>
                <th class=""></th>
                <th class="cell stick">
                  <div class="cell-border">
                    <span>Patient Name</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Archive</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Status</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Medical Center</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Date</span>
                  </div>
                </th>
                <th class="cell text-center">
                  <div class="cell-border justify-content-center">
                    <span>Series/Images</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="study-entry stat" *ngFor="let study of activePageList" [attr.id]="'tr' + study.id" [ngClass]="{
                  stat: study.level === 'Stat',
                  proirity: study.level === 'Priority'
                }">
                <td class="cell">
                  <div class="form-check">
                    <input (change)="checkSingle($event, study.id)" [attr.study-id]="study.id" class="form-check-input"
                      type="checkbox" value="" [attr.id]="'study' + study.id" />
                    <label class="form-check-label" [attr.for]="'study' + study.id"></label>
                  </div>
                </td>
                <td class="cell stick">
                  <span class="truncate text-uppercase font-weight-bold">
                    {{ study.patientName }}
                  </span>
                </td>
                <td class="cell text-center">
                  <span class="badge text-uppercase" [ngClass]="{
                    'badge-secondary': study.isArchived,
                    'badge-info': !study.isArchived
                  }">
                    {{ study.isArchived ? 'Archived' : 'Not Archived' }}
                  </span>
                </td>
                <td class="cell text-center">
                  <span class="badge" [ngClass]="{
                      'badge-danger': study.status === 'NEW',
                      'badge-warning': study.status === 'OPENED',
                      'badge-info':
                        study.status === 'UNASSIGNED' ||
                        study.status === 'FLAGGED',
                      'badge-primary': study.status === 'ASSIGNED',
                      'badge-secondary': study.status === 'DELAYED',
                      'badge-success': study.status === 'REPORTED'
                    }">
                    {{ study.status }}
                  </span>
                </td>
                <td class="cell">
                  <span class="studyId">{{ study.medicalCenter.name }}</span>
                </td>
                <td class="cell">
                  {{ study.createdAt | customdateformat : "localDateTime" }}
                </td>
                <td class="cell text-center">
                  {{ study.series || "-" }}/{{ study.images || "-" }}
                </td>
              </tr>
              <tr *ngIf="studies.length === 0">
                <td colspan="8">
                  <div class="d-block text-center" style="margin: 4rem">
                    <img src="assets/images/background/empty.svg" alt="" width="250px" />
                  </div>
                  <span class="d-block text-center">Empty List</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12 mt-3">
    <app-pagination [pagesList]="
        studies
          | filter : filterList : searchFilter : false
          | paginator : elementsPerPage
      " (onPageChange)="paginatorEvent($event)">
    </app-pagination>
  </div>
</div>