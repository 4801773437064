import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OffcanvasService } from 'src/app/services/offcanvas/offcanvas.service';

@Component({
  selector: 'app-offcanvas',
  templateUrl: './offcanvas.component.html',
  styleUrls: ['./offcanvas.component.scss'],
})
export class OffcanvasComponent implements OnInit {
  constructor(private offCanvasService: OffcanvasService) {}

  ngOnInit(): void {}

  isOpen(): Observable<boolean> {
    return this.offCanvasService.state
      .asObservable()
      .pipe(map((state) => state.isOpen));
  }

  getType(): Observable<string> {
    return this.offCanvasService.state
      .asObservable()
      .pipe(map((state) => state.type));
  }

  close(): void {
    this.offCanvasService.close();
  }
}
