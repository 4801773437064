import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { SessionStorageService } from '../session/session-storage.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  token!: string | null;
  loginStatus!: boolean;

  constructor(
    private router: Router,
    private sessionService: SessionStorageService
  ) { 
    this.token = this.sessionService.getToken();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLoginStatus(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkLoginStatus(url: string): boolean {
    if (this.token) {
      return true;
    } else {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: url } });
      return false;
    }
  }
}
