import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {
  type!: string;
  message!: string;
  actionBtn!: (close: () => void) => void;
  actionTitle!: string;

  alertBorder: any = {
    success: 'border-success',
    danger: 'border-danger',
    info: 'border-info',
    warning: 'border-warning',
    primary: 'border-primary',
    announce: 'border-warning'
  };

  alertIcons: any = {
    success: 'bi bi-check2',
    danger: 'bi bi-x-lg',
    info: 'bi bi-info-lg',
    primary: 'bi bi-info-lg',
    warning: 'bi bi-exclamation-lg',
    announce: 'bi bi-megaphone'
  };

  alertText: any = {
    success: 'text-success',
    danger: 'text-danger',
    info: 'text-info',
    warning: 'text-warning',
    primary: 'text-primary',
    announce: 'text-warning'
  };

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}

  close(): void {
    this.bsModalRef.hide();
  }
}
