import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { ValidateUserComponent } from './validate-user.component';

@Injectable({
  providedIn: 'root',
})
export class ValidateUserService {
  bsModalRef!: BsModalRef;
  constructor(private modalService: BsModalService) {}

  openModalWithComponent(callback: (status: boolean) => void) {
    const initialState = {
      title: 'Validate User',
    };

    this.bsModalRef = this.modalService.show(ValidateUserComponent, {
      initialState,
    });
    this.bsModalRef.content.onValidate = callback;
    this.bsModalRef.content.bsModalRef = this.bsModalRef;
  }

  validateUser(callback: (status: boolean) => void) {
    this.openModalWithComponent(callback);
  }
}
