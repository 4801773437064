<li class="nav-item" (click)="openModal(generateBillableReport)">
  <a class="nav-link" href="javascript:void(0);">
    <span class="nav-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">
        <path
          d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z" />
      </svg>
    </span>
    <span class="nav-link-text">Billable Report</span>
  </a>
</li>

<!-- modal -->
<ng-template #generateBillableReport>
  <app-modal [size]="'long'" [modalTitle]="'Generate Billable Report'" [isModalBtn]="false">
    <div class="container px-0">
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <div style="margin-right: 5px;">
            <em class="bi bi-calendar-week text-primary" style="font-size: 30px;"></em>
          </div>

          <app-input-date [marginClass]="'mb-0'" [borderClass]="'border-primary'" [singleDate]="false"
            [control]="customFormControl('dateRange')"></app-input-date>
        </div>

        <div class="col-4 d-flex align-items-center pl-0">
          <div style="margin-right: 5px;">
            <em class="bi bi-card-list text-primary" style="font-size: 30px;"></em>
          </div>

          <app-input-select-search *ngIf="clientList.length > 0" style="width: 100%" [marginClass]="'mb-0'"
            [borderClass]="'border-primary'" [uniqueClass]="'client'" [options]="clientList"
            [predicate]="clientsPredicate" [uniqueClass]="'generate-billable'"
            [control]="customFormControl('facility')">
          </app-input-select-search>
        </div>
        <div class="col-4 d-flex">
          <button [disabled]="reportForm.invalid || downloading" (click)="getBillableReport()"
            class="btn btn-primary ml-auto">
            <div class="d-flex align-items-center">
              <span *ngIf="!downloading" class="mr-1">
                <em class="bi bi-cloud-download" style="font-size: 15px;"></em>
              </span>
              <div *ngIf="downloading" class="spinner-border spinner-border-sm mr-1" role=" status">
                <span class="sr-only"></span>
              </div>
              <span>Download</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </app-modal>
</ng-template>
<!-- save scan type -->
<!-- modal -->