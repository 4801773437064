import { Component, OnInit } from '@angular/core';
import { MedicalCenterService } from './medical-center.service';

@Component({
    selector: 'app-medical-center-studies',
    templateUrl: './medical-center.component.html',
    styleUrls: ['./medical-center.component.scss']
})
export class MedicalCenterComponent implements OnInit {

    medicalCenterList?: any[];

    constructor(
        public medCenterService: MedicalCenterService
    ) { 
        this.medicalCenterList = [];
    }

    ngOnInit(): void {
        this.getMedicalCenterStudies();
    }

    getMedicalCenterStudies(): void {
        this.medCenterService.getMedicalCenterStudies((res, status) => {
            this.medicalCenterList = res;
        });
    }
}
