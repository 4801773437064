import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { NotificationStore } from 'src/app/store/notification/notification.store';

@Injectable({
  providedIn: 'root',
})
export class DocNotificationService {
  constructor(
    private alertService: AlertService,
    private apiConnect: ApiConnectionService,
    private notificationStore: NotificationStore
  ) {}

  markAsRead(ids: string[], callback: () => void): void {
    this.apiConnect
      .callAPI(
        'POST',
        'NotificationMarkAsReadBulk',
        { ids },
        undefined,
        undefined
      )
      .subscribe(
        (res: any) => {
          callback();
          (res.data as any[]).forEach((notification) => {
            this.notificationStore.update(notification.id, (entity) => {
              return {
                ...entity,
                ...notification,
              };
            });
          });
        },
        (err: any) => {
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  deleteNotification(id: number): void {
    this.apiConnect
      .callAPI('DELETE', 'Notification', undefined, undefined, id)
      .subscribe(
        (res: any) => {
          this.notificationStore.remove(res?.data?.id);
        },
        (err: any) => {
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }
}
