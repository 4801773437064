import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    userImageSubject!: BehaviorSubject<any>;

    constructor(
        public apiConnect: ApiConnectionService,
        public alertService: AlertService
    ) { 
        this.userImageSubject = new BehaviorSubject(null);
    }

    getUsers(onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('GET', 'User', undefined, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    saveUser(form: any, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('POST', 'User', form, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    getUserDetails(id: number, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('GET', 'User', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    updateUser(id: number, form: any, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('PUT', 'User', form, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    updateUserPermission(id: number, form: any, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('PUT', 'UpdatePermissions', form, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    resetUserPassword(id: number, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('PUT', 'ResetPassword', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    deleteUser(id: number, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('DELETE', 'User', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    updateUserImage(id: number, form: any, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('PUT', 'UserImage', form, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.userImageSubject.next('updated');
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    getUserImage(id: number, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('GET', 'UserImage', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    changeUserPassword(id: number, form: any, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('PUT', 'UserPassword', form, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    updateUserSign(form: any, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('POST', 'UserSignature', form, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    getUserSign(onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('GET', 'UserSignature', undefined, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                })
    }

}