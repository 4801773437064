<div class="page-wrapper org-theme bootstrap-color toggled">
  <!-- show sidebar btn -->
  <a id="show-sidebar" class="btn btn-sm">
    <i class="fas fa-angle-double-right"></i>
  </a>
  <!-- show sidebar btn -->

  <!-- sidebar wrapper -->
  <nav id="sidebar" class="sidebar-wrapper">
    <div class="sidebar-content">
      <!-- brand -->
      <div class="sidebar-brand">
        <a href="">BondYOO Docs</a>
        <div id="close-sidebar" style="padding: 2px 10px;">
          <i class="fas fa-times"></i>
        </div>
      </div>

      <!-- sidebar menu -->
      <div class="sidebar-menu">
        <ul>
          <li class="sidebar-dropdown">
            <a>
              <span>Getting Started</span>
              <i class="fas fa-angle-right"></i>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li>
                  <a href="">
                    <i class="fas fa-minus"></i>
                    Introduction
                  </a>
                </li>
                <li>
                  <a href="">
                    <i class="fas fa-minus"></i>
                    Content
                  </a>
                </li>
                <li>
                  <a href="">
                    <i class="fas fa-minus"></i>
                    Article
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- sidebar wrapper -->

  <!-- main content -->
  <main class="page-content">
    <!-- header -->
    <div class="doc-head-section">
      <div class="header bootstrap-head bootstrap-head-min">
        <nav class="navbar navbar-expand-lg navbar-light clearfix with-sidebar">
          <a href="" class="navbar-brand brand-name">BondYOO Docs</a>
          <button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation"> <span class="ti-menu"></span>
          </button>

          <div class="navbar-collapse bootstrap-nav collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <a class="nav-link" href="index.html">Home</a>
              </li>
              <li class="nav-item active">
                <a class="nav-link" href="index.html">Layout</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="index.html">Pages</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="index.html">Docs</a>
              </li>
            </ul>

            <form class="form-inline my-2 my-lg-0">
              <div class="search-wrapper">
                <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
              </div>
            </form>
          </div>
        </nav>
      </div>

      <div class="upper-head">
        <h2>Bootstrap</h2>
        <p>Build responsive, mobile-first projects on the web with the world’s most popular front-end component library.
        </p>
        <a href="#" class="btn start-btn">Getting Started</a>
        <a href="#" class="btn download-btn">Downloads</a>
      </div>
    </div>
    <!-- header -->

    <!-- content -->
    <div class="clearfix main-content">
      <div class="content-section">
        <section id="install" class="section border-no-sec">
          <h3>Installation</h3>
          <p>Include Bootstrap’s source Sass and JavaScript files via npm, Composer or Meteor. Package managed installs
            don’t include documentation, but do include our build system and readme.</p>
          <pre>
            <code>$ npm install bootstrap</code>
            <code>$ gem install bootstrap -v 4.3.1</code>
          </pre>
          <p>Download Bootstrap to get the compiled CSS and JavaScript, source code, or include it with your favorite
            package managers like npm, RubyGems, and more.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
        </section>
        <section id="compile" class="section">
          <h3>Compiled CSS and JS</h3>
          <p>Download ready-to-use compiled code for <code>Bootstrap v4.3.1</code> to easily drop into your project,
            which includes:</p>
          <ul class="pro-ul">
            <li>
              <p>Compiled and minified CSS bundles</p>
            </li>
            <li>
              <p>Compiled and minified JavaScript plugins</p>
            </li>
          </ul>
          <p>This doesn’t include documentation, source files, or any optional JavaScript dependencies (jQuery and
            Popper.js).</p>
          <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum.</p>
          <h4>Source files</h4>
          <p>Compile Bootstrap with your own asset pipeline by downloading our source Sass, JavaScript, and
            documentation files. This option requires some additional tooling:</p>
          <ul class="pro-ul">
            <li>
              <p>Sass compiler (Libsass or Ruby Sass is supported) for compiling your CSS.</p>
            </li>
            <li>
              <p>Autoprefixer for CSS vendor prefixing</p>
            </li>
          </ul>
          <p>Should you require build tools, they are included for developing Bootstrap and its docs, but they’re likely
            unsuitable for your own purposes.</p>
        </section>
        <section id="package" class="section">
          <h3>Package managers</h3>
          <p>Pull in Bootstrap’s source files into nearly any project with some of the most popular package managers. No
            matter the package manager, Bootstrap will require a Sass compiler and Autoprefixer for a setup that matches
            our official compiled versions.</p>
          <h4>npm</h4>
          <p>Install Bootstrap in your Node.js powered apps with the npm package:</p>
          <pre><code>$ npm install bootstrap</code>  </pre>
          <p><code>require('bootstrap')</code> will load all of Bootstrap’s jQuery plugins onto the jQuery object. The
            <code>bootstrap</code> module itself does not export anything. You can manually load Bootstrap’s jQuery
            plugins individually by loading the <code>/js/*.js</code> files under the package’s top-level directory.</p>
          <p>Bootstrap’s <code>package.json</code> contains some additional metadata under the following keys:</p>
          <ul class="pro-ul">
            <li>
              <p><code>sass</code> - path to Bootstrap’s main Sass source file</p>
            </li>
            <li>
              <p><code>style</code> - path to Bootstrap’s non-minified CSS that’s been precompiled using the default
                settings (no customization)</p>
            </li>
          </ul>
        </section>
      </div>
      <div class="right-nav nav">
        <nav> <a href="#install" class="">Installation</a>
          <a href="#compile" class="active">Compiled CSS and JS</a>
          <a href="#package" class="">Package managers</a>
        </nav>
      </div>
      <!-- end of right-nav -->
    </div>
    <!-- content -->
  </main>
  <!-- main content -->
</div>


