<div class="row mb-4">
    <div class="col-lg-5 col-md-6 col-sm-12 d-flex">
        <div class="card flex-fill">
            <div class="card-body">
                <div class="d-block">
                    <h6 class="mb-0">Study Levels</h6>
                </div>
                <app-pie-chart [strokeColor]="['#16a2b8', '#6c757d', '#f17f8a']" [chartData]="[]" [xaxisCategories]="[]"
                    [dataSubject]="priorityData" [chartId]="'priority-distribution'"></app-pie-chart>
            </div>
        </div>
    </div>

    <div class="col-lg-7 col-md-6 col-sm-12 d-flex">
        <div class="card flex-fill">
            <div class="card-body">
                <div class="d-block">
                    <h6 class="mb-0">Weekly Cases</h6>
                </div>
                <app-column-chart [xaxisCategories]="[]" [strokeColor]="['#16a2b8']" [chartData]="[]"
                    [chartId]="'weekly-studies'" [seriesDataSubject]="weeklyData" [xaxisSubject]="weeklyLabels">
                </app-column-chart>
            </div>
        </div>
    </div>
</div>