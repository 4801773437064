import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';

@Injectable({
    providedIn: 'root'
})
export class ScanTypeService {

    constructor(
        public apiConnect: ApiConnectionService,
        public alertService: AlertService
    ) { }

    getScanType(onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('GET', 'ScanTypes', undefined, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    saveScanType(form: any, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('POST', 'ScanTypes', form, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    updateScanType(form: any, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('PUT', 'ScanTypes', form, undefined, form.id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    deleteScanType(id: number, onGet: (res: any, status: boolean) => void): void {
        this.apiConnect.callAPI('DELETE', 'ScanTypes', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }
}