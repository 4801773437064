import { Routes } from "@angular/router";
import { EditStudyComponent } from "./study-details/study-details.component";
import { ClientDashboardComponent } from "./home/client-dashboard.component";
import { NewStudyComponent } from "./new-study/new-study.component";
import { StudyArchiveComponent } from "./studies/studies.component";
import { StudyEditComponent } from './study-details/study-edit/study-edit.component';
import { StudyAttachmentComponent } from './study-details/study-attachment/study-attachment.component';
import { StudyReportComponent } from './study-details/study-report/study-report.component';

export const routes: Routes = [
    {
        path: 'dashboard',
        component: ClientDashboardComponent
    },
    {
        path: 'create-study',
        component: NewStudyComponent
    },
    {
        path: 'studies',
        component: StudyArchiveComponent
    },
    {
        path: 'study-details',
        component: EditStudyComponent,
        children: [
            {
                path: 'edit',
                component: StudyEditComponent,
            },
            {
                path: 'report',
                component: StudyReportComponent,
            },
            {
                path: 'attachment',
                component: StudyAttachmentComponent,
            },
        ]
    }
];