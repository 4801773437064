import { Injectable } from '@angular/core';
import { ApiLinkService } from 'src/app/services/api/apilink.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
import { Observable, Subscription } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiConnectionService {
  constructor(
    public apiLink: ApiLinkService,
    public alertService: AlertService,
    public sessionStorageService: SessionStorageService
  ) { }

  secureConnect(
    httpMethod: string,
    link: string,
    data?: any,
    params?: any,
    id?: number,
    callback?: any,
    dataTranform?: any
  ): void {
    let subscription: Subscription = this.apiLink.dataUplink(httpMethod, link, data, params, id, dataTranform).subscribe(
      (res: any) => {
        callback(res, 'success', this.alertMessage.bind(this), subscription);
      },
      (err: any) => {
        callback(err, 'error', this.alertMessage.bind(this), subscription);
      }
    );
  }

  alertMessage(type: string, title: string, message: string): void {
    this.alertService.setAlert({ type, title, message });
  }

  callAPI(
    httpMethod: string,
    link: string,
    data?: any,
    params?: any,
    id?: any
  ): Observable<any> {
    return this.apiLink.dataUplink(httpMethod, link, data, params, id);
  }
}
