<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="text-center">
                <h3 class="mb-0 text-capitalize">About <span class="font-weight-bold">Us</span></h3>
                <div class="main-title-border mx-auto"></div>
                <p class="text-muted sec_subtitle mx-auto mt-2"></p>
            </div>
        </div>
    </div>

    <div class="row mt-4 pt-4">
        <div class="col-lg-4">
            <div class="text-center work_box mt-3 p-3">
                <div class="z-index position-relative">
                    <div class="work_content mt-4">
                        <h5 class="font-weight-bold text-primary">Our Vision</h5>
                        <p class="mt-3 text-muted mb-0">
                            To be the leading provider of teleradiology 
                            services in Africa.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="text-center work_box work_border_left mt-3 p-3">
                <div class="z-index position-relative">
                    <div class="work_content mt-4">
                        <h5 class="font-weight-bold text-primary">Our Mission</h5>
                        <p class="mt-3 text-muted mb-0">
                            To improve access to timely radiology reporting
                            in Africa for better clinical outcomes.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="text-center work_box work_border_left mt-3 p-3">
                <div class="z-index position-relative">
                    <div class="work_content mt-4">
                        <h5 class="font-weight-bold text-primary">Our Core Values</h5>
                        <p class="mt-3 text-muted mb-0">
                            Timeliness, Professionalism, Excellence, 
                            Confidentiality as well as Reliability.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>