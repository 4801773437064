import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
import { ClientService } from '../../doc/clients/client.service';
import { ClientSettingsService } from './client-settings.service';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends ModalComponent { }
applyMixins(BaseClass, [ModalComponent]);

@Component({
    selector: 'app-client-settings',
    templateUrl: './client-settings.component.html',
    styleUrls: ['./client-settings.component.scss']
})
export class ClientSettingsComponent extends BaseClass implements OnInit {

    userId!: number;
    clientDetails!: any;
    updatingDetails = false;
    updateDetailsBtn!: object;
    clientDetailsForm!: FormGroup;

    constructor(
        public modalService: BsModalService,
        public clientService: ClientService,
        private sessionService: SessionStorageService,
        public clientSettingService: ClientSettingsService,
    ) {
        super();
        this.clientDetailsForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            phoneNumber: new FormControl(null, Validators.required),
            location: new FormControl(null, Validators.required)
        });

        this.updateDetailsBtn = {
            method: () => {
                this.updateClientDetails();
            },
            text: 'update'
        }

        this.userId = this.sessionService.getUserId();
    }

    ngOnInit(): void {
        this.getClientDetails(this.userId);
    }

    customFormControl(name: string): FormControl {
        return this.clientDetailsForm.get(name) as FormControl;
    }

    getClientDetails(userId: number): void {
        this.clientSettingService.getMedicalCenterDetails(userId, (res, status) => {
            if (status) {
                this.clientDetails = res;
            }
        });
    }

    editDetails(template: TemplateRef<any>): void {
        this.clientDetailsForm = new FormGroup({
            id: new FormControl(this.clientDetails.id),
            name: new FormControl(this.clientDetails.name, Validators.required),
            email: new FormControl(this.clientDetails.email, Validators.required),
            phoneNumber: new FormControl(this.clientDetails.phoneNumber, Validators.required),
            location: new FormControl(this.clientDetails.location, Validators.required)
        });
        this.modalService.show(template);
    }

    updateClientDetails(): void {
        this.updatingDetails = true;
        this.clientService.updateMedicalCenter(
            this.clientDetails.id,
            this.clientDetailsForm.value,
            (res, status) => {
                this.updatingDetails = false;
                this.getClientDetails(this.userId);
                this.closeModal();
            }
        )
    }
}
