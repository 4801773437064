import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/guards/auth.guard';
import { CanLoadGuard } from 'src/app/services/guards/route.guard';

import { LoginComponent } from '../pages/auth/login/login.component';
import { AccountComponent } from '../pages/account/account.component';
import { AdminLayoutComponent } from './../layout/admin-layout/admin-layout.component';
import { RecoverPasswordComponent } from '../pages/auth/recover-password/recover-password.component';

export const routes: Routes = [
  {
    path: '', loadChildren: () => import('../pages/website/website.module').then(m => m.WebsiteModule)
  },
  {
    path: 'app', component: AdminLayoutComponent, canActivate: [AuthGuard], children: [
      {
        path: 'doc', loadChildren: () => import('../pages/doc/doc.module').then(m => m.DocModule),
        data: {routeType: 'DOCTOR'},
        canLoad:[CanLoadGuard]
      },
      {
        path: 'hos', loadChildren: () => import('../pages/hos/hos.module').then(m => m.HosModule),
        data: {routeType: 'MEDICAL_CENTER'},
        canLoad:[CanLoadGuard]
      },
      {
        path: 'settings', loadChildren: () => import('../pages/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'account',
        component: AccountComponent
      }
    ]
  },
  {
    path: 'auth', children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'recover', component: RecoverPasswordComponent }
    ]
  }
];
