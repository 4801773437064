<div class="row g-3 mb-4 align-items-center justify-content-between">
  <div class="col-auto">
    <h1 class="app-page-title mb-0 text-muted">Studies</h1>
  </div>
  <div class="col-auto">
    <div class="page-utilities">
      <div
        class="row g-2 justify-content-start justify-content-md-end align-items-center"
      >
        <div class="col-auto">
          <form class="table-search-form row gx-1 align-items-center">
            <div class="col-auto">
              <button
                (click)="redictToNewStudy()"
                type="submit"
                class="btn btn-secondary mr-2"
              >
                <i class="bi bi-plus-lg"></i>
                New Study
              </button>
              <button (click)="back()" type="submit" class="btn btn-primary">
                <i class="bi bi-arrow-left"></i>
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mx-0">
  <div class="d-flex w-100">
    <div class="app-card app-card-orders-table shadow-sm mb-3 w-100">
      <div class="app-card-body">
        <div class="d-flex align-items-center">
          <!-- search -->
          <form action="" class="search-bar">
            <div class="position-relative">
              <input
                name="search"
                type="text"
                class="form-control form form-control-light"
                [(ngModel)]="searchFilter"
                placeholder="Search ..."
              />
              <span class="bi bi-search"></span>
            </div>
          </form>
          <span class="ml-1">
            <select
              [formControl]="showEntries"
              class="form-control custom-select custom-select-sm"
              name="number-select"
            >
              <option [ngValue]="5">5</option>
              <option [ngValue]="10">10</option>
              <option [ngValue]="20">20</option>
              <option [ngValue]="40">40</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
            </select>
          </span>
          <!-- search -->

          <!-- loader -->
          <div *ngIf="gettingStudies" class="ml-2 text-primary">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </div>
          <!-- loader -->

          <!-- actions -->
          <div class="ml-auto">
            <button
              *ngIf="openFilter"
              (click)="clearFilters()"
              type="submit"
              class="btn btn-sm btn-white text-muted mr-2"
            >
              <i class="bi bi-x text-danger mr-1"></i>
              clear
            </button>
            <button
              *ngIf="!openFilter"
              (click)="openBackendFilter()"
              type="submit"
              class="btn btn-sm btn-primary"
            >
              <i class="bi bi-sliders"></i>
            </button>
            <button
              *ngIf="openFilter"
              (click)="clearBackendFilter()"
              type="submit"
              class="btn btn-sm btn-primary"
            >
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
          <!-- actions -->
        </div>

        <div id="backend-filter" class="row align-items-end d-none mt-3">
          <div class="col-3">
            <div class="form-group">
              <label for="level">Level</label>
              <select
                class="form-control custom-select-sm border-primary"
                [formControl]="customFormControl('level')"
              >
                <option [ngValue]="null">Select</option>
                <option [ngValue]="'Normal'">Normal</option>
                <option [ngValue]="'Priority'">Priority</option>
                <option [ngValue]="'Stat'">Stat</option>
              </select>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label for="level">Status</label>
              <select
                class="form-control custom-select-sm border-primary"
                [formControl]="customFormControl('status')"
              >
                <option [ngValue]="null">Select</option>
                <option [ngValue]="'all'">All</option>
                <option [ngValue]="'NEW'">Pending</option>
                <option [ngValue]="'OPENED'">Open</option>
                <option [ngValue]="'FLAGGED'">Flagged</option>
                <option [ngValue]="'REPORTED'">Reported</option>
              </select>
            </div>
          </div>
          <div class="col-3">
            <app-input-date
              [borderClass]="'border-primary'"
              [singleDate]="false"
              [fieldId]="'studyDate'"
              [control]="customFormControl('studyDate')"
            ></app-input-date>
          </div>
          <div class="col-3">
            <app-input-select-search
              *ngIf="radiologists.length >= 0"
              [borderClass]="'border-primary'"
              [uniqueClass]="'radio'"
              [options]="radiologists"
              [predicate]="radioPredicate"
              [fieldId]="'radiologist'"
              [control]="customFormControl('radiologist')"
            >
            </app-input-select-search>
          </div>

          <div class="col-3 ml-auto">
            <div class="input-group mb-3">
              <input
                [formControl]="searchByStudyIdForm"
                type="text"
                class="form-control form-control-sm border-primary"
                placeholder="Study ID ..."
              />
              <div class="input-group-append">
                <button
                  [disabled]="searchByStudyIdForm.invalid"
                  (click)="searchStudyById()"
                  class="btn btn-primary btn-sm"
                  type="button"
                >
                  search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div *ngIf="studies.length > 0" class="table-card shadow-sm">
      <div class="table-card-body">
        <div class="table-responsive mt-3">
          <table
            class="table themed-table table-sticky-column themed-table-plain"
          >
            <thead>
              <tr>
                <th class="cell stick">
                  <div class="cell-border">
                    <span>Patient Name</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Study Type</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Status</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Study Date</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Series/Images</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Study ID</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Reported At</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border">
                    <span>Assigned</span>
                  </div>
                </th>
                <th class="cell text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let study of activePageList"
                [ngClass]="{
                  stat: study.level === 'Stat',
                  proirity: study.level === 'Priority'
                }"
              >
                <td class="cell stick">
                  <span
                    (click)="openStudy(study.id)"
                    class="truncate font-weight-bold"
                    style="cursor: pointer"
                  >
                    {{ study.patientName }}
                  </span>
                </td>
                <td class="cell">
                  <span class="studyId">{{ study.studyDecription }}</span>
                </td>
                <td class="cell text-center">
                  <span
                    class="badge badge-danger"
                    [ngClass]="{
                      'badge-danger': study.status === 'NEW',
                      'badge-warning': study.status === 'OPENED',
                      'badge-info':
                        study.status === 'UNASSIGNED' ||
                        study.status === 'FLAGGED',
                      'badge-primary':
                        study.status === 'ASSIGNED' ||
                        study.status === 'UNFLAGGED',
                      'badge-secondary': study.status === 'DELAYED',
                      'badge-success': study.status === 'REPORTED',
                      'badge-flagged': study.status === 'FLAGGED'
                    }"
                  >
                    {{ study.status }}
                  </span>
                </td>
                <td class="cell">
                  {{ study.studyDate | customdateformat : "localDateTime" }}
                </td>
                <td class="cell text-center">
                  {{ study.series || "-" }}/{{ study.images || "-" }}
                </td>
                <td class="cell">
                  <span class="studyId">{{ study.studyUID }}</span>
                </td>
                <td class="cell">
                  <span *ngIf="study.signedAt">
                    {{ study.signedAt | customdateformat : "localDateTime" }}
                  </span>
                </td>
                <td class="cell">
                  <div *ngIf="study.isAssigned">
                    <a
                      *ngIf="study.assigned_radioligist.user.userImage !== null"
                      href="javascript:void(0);"
                      class="avatar"
                      style="text-transform: upperCase"
                    >
                      <img
                        [src]="
                          assetsRoot +
                          study.assigned_radioligist.user.userImage.url
                        "
                        height="38"
                        alt=""
                      />
                    </a>
                    <a
                      *ngIf="study.assigned_radioligist.user.userImage === null"
                      href="javascript:void(0);"
                      class="avatar"
                      style="text-transform: upperCase"
                    >
                      {{ study.assigned_radioligist.user.firstName[0] }}
                    </a>
                    <h2>
                      <a href="javascript:void(0);">
                        {{
                          [
                            study.assigned_radioligist.user.firstName,
                            study.assigned_radioligist.user.lastName
                          ].join(" ")
                        }}
                      </a>
                    </h2>
                  </div>
                </td>
                <td class="cell text-center">
                  <div class="btn-group dropdown">
                    <a
                      href="javascript:void(0)"
                      class="table-action-btn dropdown-toggle arrow-none btn btn-light btn-xs"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-three-dots"></i>
                    </a>
                    <div
                      class="dropdown-menu"
                      data-popper-placement="bottom-end"
                    >
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                        (click)="openStudy(study.id)"
                      >
                        <i class="bi bi-pencil mr-2 text-muted"></i> Open Study
                      </a>
                      <a
                        *ngIf="study.status !== 'REPORTED'"
                        (click)="deleteStudy(study.id)"
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >
                        <i class="bi bi-trash mr-2 text-muted"></i> Delete
                      </a>
                      <a
                        *ngIf="study.status === 'FLAGGED'"
                        (click)="openFlagMessage(showFlagMessageModal, study)"
                        class="dropdown-item"
                        href="javascript:void(0);"
                      >
                        <i
                          class="bi bi-chat-left-quote-fill mr-2 text-primary"
                        ></i>
                        Open Flag Message
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12 mt-3">
    <app-pagination
      [pagesList]="
        studies
          | filter : filterList : searchFilter : false
          | paginator : elementsPerPage
      "
      (onPageChange)="paginatorEvent($event)"
    >
    </app-pagination>
  </div>
</div>

<ng-template #showFlagMessageModal>
  <app-modal
    [loading]="unFlagging"
    [modalTitle]="'Flag Details'"
    [modalActionButton]="unFlagBtn"
  >
    <p>{{ flagReason }}</p>
  </app-modal>
</ng-template>
