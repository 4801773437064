import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-study-details',
  templateUrl: './study-details.component.html',
  styleUrls: ['./study-details.component.scss'],
})
export class EditStudyComponent implements OnInit {
  studyId!: number;

  constructor(
    public location: Location,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.forEach((params: any) => {
      this.studyId = +params.studyId;

      if (params?.type === 'report') {
        this.router.navigate(['/app/hos/study-details/report'], {
          queryParams: { studyId: this.studyId, type: 'report' },
        });
      } else {
        this.router.navigate(['/app/hos/study-details/edit'], {
          queryParams: { studyId: this.studyId },
        });
      }
    });
  }

  back(): void {
    this.location.back();
  }
}
