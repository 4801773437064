import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { sideMenu } from 'src/app/constants/menu/menu';
import { settingsMenu } from 'src/app/constants/menu/settings';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
})
export class AdminSidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  userApplicationType!: string;
  newStudyCount!: number;
  countSubscription!: Subscription;

  menuOptions: Array<any> = sideMenu;
  settingsMenu: Array<any> = settingsMenu;

  constructor(
    private apiConnect: ApiConnectionService,
    private sessionService: SessionStorageService
  ) {
    this.userApplicationType = this.sessionService.getUserApplicationType();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const submenuToggle: NodeListOf<HTMLElement> =
      document.querySelectorAll('.submenu-toggle');
    submenuToggle?.forEach((subMenuLink: HTMLElement) => {
      subMenuLink.addEventListener('click', function (event) {
        subMenuLink.classList.toggle('collapsed');
        const subMenu = document.getElementById(
          `${subMenuLink.getAttribute('data-toggle')}`
        );
        subMenu?.classList.toggle('show');
      });
    });
  }

  hideByRole(role: string): boolean {
    if (!role) return false;
    return this.sessionService.getUserRole() !== role;
  }

  getNewStudyCount(): void {
    this.apiConnect.secureConnect(
      'GET',
      'StudyCount',
      undefined,
      undefined,
      undefined,
      this.onGetNewStudyCount.bind(this)
    );
  }

  onGetNewStudyCount(res: any, status: string, callback: any): void {
    if (status === 'success') {
      this.newStudyCount = res.newStudyCount;
    } else {
      callback('danger', 'Error', res.message);
    }
  }

  ngOnDestroy(): void {
    this.countSubscription?.unsubscribe();
  }
}
