export const sideMenu = [
  {
    text: 'Home',
    icon: 'bi bi-house-door',
    link: '/app/doc/dashboard',
    portal: 'DOCTOR',
  },
  {
    text: 'Studies',
    icon: 'bi bi-folder',
    link: '/app/doc/studies',
    portal: 'DOCTOR',
    role: 'Study|View',
    userRole: 'Admin'
  },
  {
    text: 'Manage Pool',
    icon: 'bi bi-basket',
    link: '/app/doc/manage-pool',
    portal: 'DOCTOR',
    role: 'Pool|View'
  },
  {
    text: 'Report Templates',
    icon: 'bi bi-card-list',
    link: '/app/doc/report-template',
    portal: 'DOCTOR',
    role: 'Normals|View'
  },
  {
    text: 'Manage Clients',
    icon: 'bi bi-people',
    link: '/app/doc/manage-clients',
    portal: 'DOCTOR',
    role: 'Clients|View'
  },
  {
    text: 'Radiologists',
    icon: 'bi bi-clipboard-plus',
    link: '/app/doc/radiologists',
    portal: 'DOCTOR',
    role: 'Radiologists|View'
  },
  {
    text: 'Archive Studies',
    icon: 'bi bi-file-zip',
    link: '/app/doc/archive-studies',
    portal: 'DOCTOR',
    role: 'Archive|View'
  },
  {
    text: 'Home',
    icon: 'bi bi-house-door',
    link: '/app/hos/dashboard',
    portal: 'MEDICAL_CENTER',
    role: 'Study|View'
  },
  {
    text: 'Studies',
    icon: 'bi bi-folder',
    link: '/app/hos/studies',
    portal: 'MEDICAL_CENTER',
    role: 'Study|View'
  }
];
