<div class="form-group">
  <label [for]="fieldId" class="label text-muted font-weight-bolder">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>
  <div class="custom-file">
    <input (change)="onFileSelect($event)" type="file" class="custom-file-input" id="upload-file">
    <label class="custom-file-label" for="upload-file">{{ fileName }}</label>
  </div>
  <input [formControl]="control" [id]="fieldId" autocomplete="off" class="form-control form-control-sm d-none" />
  <app-form-validation [validationErrors]="validationErrors"></app-form-validation>
</div>
