<div class="row g-3 mb-4 align-items-center justify-content-between">
  <div class="col-auto">
    <h1 class="app-page-title mb-0 text-muted">My Account</h1>
  </div>
</div>

<div class="row gy-4">
  <div class="col-lg-6 col-md-6 col-sm-12">
    <div class="app-card app-card-account shadow-sm d-flex flex-column align-items-start">
      <div class="app-card-header p-3 border-bottom-0">
        <div class="row align-items-center gx-3">
          <div class="col-auto">
            <div class="app-icon-holder">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
              </svg>
            </div>
          </div>
          <div class="col-auto">
            <h4 class="app-card-title">Profile</h4>
          </div>
        </div>
      </div>

      <div class="app-card-body px-4 w-100">
        <div class="item border-bottom py-3">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto">
              <div class="item-label mb-2"><strong>Photo</strong></div>
              <div class="item-data"><img class="profile-image"
                  [src]="user?.userImage ? assetsRoot + user?.userImage.url : 'assets/images/default.png'" alt=""></div>
            </div>
            <div class="col text-right">
              <a (click)="openModal(updateImageModal)" class="btn-sm app-btn-secondary"
                href="javascript:void(0);">Change</a>
            </div>
          </div>
        </div>

        <div class="item border-bottom py-3">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto">
              <div class="item-label"><strong>User Name</strong></div>
              <div class="item-data">{{ user?.username }}</div>
            </div>
            <div class="col text-right">
              <a class="btn-sm app-btn-secondary" href="javascript:void(0);"
                (click)="editUserDetails(updateUserDetailsModal)">Change</a>
            </div>
          </div>
        </div>

        <div class="item py-3">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto">
              <div class="item-label"><strong>Email</strong></div>
              <div class="item-data">{{ user?.email }}</div>
            </div>
            <div class="col text-right">
              <a class="btn-sm app-btn-secondary" href="javascript:void(0);"
                (click)="editUserDetails(updateUserDetailsModal)">Change</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12">

    <div class="row">
      <div class="col-12 col-lg-12 col-md-12 col-sm-12">
        <div class="app-card app-card-account shadow-sm d-flex flex-column align-items-start">
          <div class="app-card-header p-3 border-bottom-0">
            <div class="row align-items-center gx-3">
              <div class="col-auto">
                <div class="app-icon-holder">
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M5.443 1.991a60.17 60.17 0 0 0-2.725.802.454.454 0 0 0-.315.366C1.87 7.056 3.1 9.9 4.567 11.773c.736.94 1.533 1.636 2.197 2.093.333.228.626.394.857.5.116.053.21.089.282.11A.73.73 0 0 0 8 14.5c.007-.001.038-.005.097-.023.072-.022.166-.058.282-.111.23-.106.525-.272.857-.5a10.197 10.197 0 0 0 2.197-2.093C12.9 9.9 14.13 7.056 13.597 3.159a.454.454 0 0 0-.315-.366c-.626-.2-1.682-.526-2.725-.802C9.491 1.71 8.51 1.5 8 1.5c-.51 0-1.49.21-2.557.491zm-.256-.966C6.23.749 7.337.5 8 .5c.662 0 1.77.249 2.813.525a61.09 61.09 0 0 1 2.772.815c.528.168.926.623 1.003 1.184.573 4.197-.756 7.307-2.367 9.365a11.191 11.191 0 0 1-2.418 2.3 6.942 6.942 0 0 1-1.007.586c-.27.124-.558.225-.796.225s-.526-.101-.796-.225a6.908 6.908 0 0 1-1.007-.586 11.192 11.192 0 0 1-2.417-2.3C2.167 10.331.839 7.221 1.412 3.024A1.454 1.454 0 0 1 2.415 1.84a61.11 61.11 0 0 1 2.772-.815z" />
                    <path fill-rule="evenodd"
                      d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                  </svg>
                </div>
              </div>

              <div class="col-auto">
                <h4 class="app-card-title">Security</h4>
              </div>
            </div>
          </div>

          <div class="app-card-body px-4 w-100">
            <div class="item border-bottom py-3">
              <div class="row justify-content-between align-items-center">
                <div class="col-auto">
                  <div class="item-label"><strong>Password</strong></div>
                  <div class="item-data">••••••••</div>
                </div>
                <div class="col text-right">
                  <a class="btn-sm app-btn-secondary" href="javascript:void(0);"
                    (click)="editUserPassword(updateUserPasswordModal)">
                    Change
                  </a>
                </div>
              </div>
            </div>

            <div class="item py-3">
              <div class="row justify-content-between align-items-center">
                <div class="col-auto">
                  <div class="item-label"><strong>Two-Factor Authentication</strong></div>
                  <div class="item-data">You haven't set up two-factor authentication. </div>
                </div>
                <div class="col text-right">
                  <a class="btn-sm app-btn-secondary" href="javascript:void(0);">Set Up</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-12 col-md-12 col-sm-12 mt-4" *ngIf="user?.applicationType === 'DOCTOR'">
        <div class="app-card app-card-account shadow-sm d-flex flex-column align-items-start">
          <div class="app-card-header p-3 border-bottom-0">
            <div class="row align-items-center gx-3">
              <div class="col-auto">
                <div class="app-icon-holder">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-vector-pen" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M10.646.646a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1 0 .708l-1.902 1.902-.829 3.313a1.5 1.5 0 0 1-1.024 1.073L1.254 14.746 4.358 4.4A1.5 1.5 0 0 1 5.43 3.377l3.313-.828L10.646.646zm-1.8 2.908-3.173.793a.5.5 0 0 0-.358.342l-2.57 8.565 8.567-2.57a.5.5 0 0 0 .34-.357l.794-3.174-3.6-3.6z" />
                    <path fill-rule="evenodd" d="M2.832 13.228 8 9a1 1 0 1 0-1-1l-4.228 5.168-.026.086.086-.026z" />
                  </svg>
                </div>
              </div>

              <div class="col-auto">
                <h4 class="app-card-title">Signature</h4>
              </div>
            </div>
          </div>

          <div class="app-card-body px-4 w-100">
            <div class="item py-3">
              <div class="row justify-content-between align-items-center">
                <div class="col-auto">
                  <p *ngIf="userSignatureData === null" class="">Add your signature here ...</p>
                  <div *ngIf="userSignatureData !== null" class="image-fluid">
                    <img [src]="signatureSource" alt="Signature" />
                  </div>
                </div>

                <div class="col text-right">
                  <a class="btn-sm app-btn-secondary" href="javascript:void(0);"
                    (click)="openModal(updateSignatureModal)">
                    Change
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- update image -->
<ng-template #updateImageModal>
  <app-modal [loading]="uploading" [modalTitle]="'Update Profile Image'" [modalActionButton]="updateImageBtn">
    <form action="">
      <app-image-cropper [fieldId]="'profileImage'" [control]="userImageForm"></app-image-cropper>
    </form>
  </app-modal>
</ng-template>
<!-- update image -->

<!-- update signature -->
<ng-template #updateSignatureModal>
  <app-modal [loading]="uploadingSignature" [modalTitle]="'Upload Signature'" [modalActionButton]="saveSignature">
    <form action="">
      <app-image-cropper [cropperHeight]="100" [canvasHeight]="200" [fieldId]="'signature'" [control]="userSignature">
      </app-image-cropper>
    </form>
  </app-modal>
</ng-template>
<!-- update signature -->

<!-- update details -->
<ng-template #updateUserDetailsModal>
  <app-modal [loading]="updatingDetails" [modalTitle]="'Update User Details'" [modalActionButton]="updateDetailsBtn">
    <form action="" [formGroup]="userDetailsForm">
      <app-input [fieldId]="'userName'" [control]="customFormControl('userName',userDetailsForm)"></app-input>
      <app-input [fieldId]="'email'" [control]="customFormControl('email',userDetailsForm)"></app-input>
    </form>
  </app-modal>
</ng-template>
<!-- update details -->

<!-- update password -->
<ng-template #updateUserPasswordModal>
  <app-modal [loading]="updatingPassword" [modalTitle]="'Update User Password'" [modalActionButton]="updatePasswordBtn">
    <form action="" [formGroup]="userPasswordForm">
      <app-input [fieldId]="'oldPassword'" [fieldType]="'password'"
        [control]="customFormControl('oldPassword', userPasswordForm)"></app-input>
      <app-input [fieldId]="'newPassword'" [fieldType]="'password'"
        [control]="customFormControl('newPassword', userPasswordForm)"></app-input>
    </form>
  </app-modal>
</ng-template>
<!-- update password -->