<div class="row g-3 mb-4 align-items-center justify-content-between">
  <div class="col-auto">
    <h1 class="app-page-title mb-0 text-muted">Settings</h1>
  </div>
</div>

<div class="row mx-0">
  <div class="d-flex w-100">
    <div class="app-card app-card-orders-table shadow-sm mb-5 w-100">
      <div class="app-card-body">
          <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>