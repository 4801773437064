<a href="javascript:void(0);" class="dropdown-toggle no-toggle-arrow text-muted" id="notifications-dropdown-toggle"
    data-toggle="dropdown" role="button" aria-expanded="false" title="Notifications">
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="currentColor" class="bi bi-chat icon"
        viewBox="0 0 16 16">
        <path
            d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
    </svg>
    <span *ngIf="notificationList.length > 0" class="icon-badge text-white">{{ notificationList.length }}</span>
</a>

<div class="dropdown-menu dropdown-lg dropdown-menu-right" style="margin-top: 10px; right: 100px;">
    <div class="dropdown-item noti-title">
        <h6 class="m-0">
            <span class="float-end">
                <a href="" class="text-dark">
                    <small>Clear All</small>
                </a>
            </span>Chat Notifications
        </h6>
    </div>

    <div class="noti-scroll" data-simplebar="init">
        <div class="simplebar-wrapper" style="margin: 0px;">
            <div class="simplebar-mask">
                <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                    <div class="simplebar-content-wrapper">
                        <div class="simplebar-content" style="padding: 0px;">

                            <!-- item-->
                            <a *ngFor="let notification of notificationList" href="javascript:void(0);"
                                (click)="openStudyChat(notification.message?.conversation.conversationTypeId, notification.id)"
                                class="dropdown-item notify-item">
                                <div class="notify-icon">
                                    <img [attr.src]="notification.message.user.userImage ? assetsURL + notification.message.user.userImage.url : 'assets/images/default.png'"
                                        class="img-fluid rounded-circle" alt="">
                                </div>
                                <p class="notify-details">
                                    {{ [notification.message.user.firstName,notification.message.user.lastName].join('
                                    ') }}
                                    <small class="text-muted float-right">
                                        {{ duration(notification.createdAt) }}
                                    </small>
                                </p>
                                <p class="text-muted mb-0 user-msg">
                                    <small>{{ notification.message.content }}</small>
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>