<div class="website">
    <!-- header -->
    <app-web-header></app-web-header>
    <!-- header -->

    <!-- content -->
    <router-outlet></router-outlet>
    <!-- content -->

    <!-- footer -->
    <app-web-footer></app-web-footer>
    <!-- footer -->
</div>