<div class="notification-container">
  <div class="notification-header">
    <h6 class="mb-0 font-weight-bold">Notifications</h6>
  </div>

  <div
    *ngIf="!(notifications$ | async)?.length"
    class="empty-notification d-flex flex-column justify-content-center align-items-center"
  >
    <p class="mb-0">No new Notifications</p>
  </div>

  <div *ngIf="(notifications$ | async)?.length" class="notification-body">
    <ul class="notification-list">
      <li
        *ngFor="let notification of notifications$ | async"
        class="notification-card"
      >
        <div class="notification-card-header">
          <span
            class="notification-type"
            [ngClass]="{
              'bg-danger': notification?.metaData?.type === 'New Study'
            }"
          ></span>
          <span class="ml-2">{{ notification?.metaData?.type }}</span>
          <small class="ml-auto date">
            {{ formatTime(notification.createdAt) }}
          </small>
          <small
            class="ml-auto close bg-secondary text-white"
            (click)="deleteNotification(notification?.id)"
          >
            <em class="bi bi-x"></em>
          </small>
        </div>

        <div class="notification-content">
          <h6 class="mb-0">{{ notification?.metaData?.body?.patientName }}</h6>
          <div class="mb-0 study-uid">
            {{ notification?.metaData?.body?.studyUID }}
          </div>
          <div class="mb-0 facility text-primary">
            {{ notification?.metaData?.body?.medicalCenter?.name }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
