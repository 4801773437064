import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit, AfterViewInit, OnDestroy {

    imageList!: any[];
    imageSliderIntervalId: any;

    constructor() { 
        this.imageList = [
            {
                alignment: 'text-left',
                image: 'assets/images/website/slider/zero.webp'
            },
            {
                alignment: 'text-left',
                image: 'assets/images/website/slider/one.webp'
            },
            {
                alignment: 'text-right',
                image: 'assets/images/website/slider/two.webp'
            },
            {
                alignment: 'text-center',
                image: 'assets/images/website/slider/three.webp'
            },
            {
                alignment: 'text-left',
                image: 'assets/images/website/slider/four.webp'
            },
            {
                alignment: 'text-right',
                image: 'assets/images/website/slider/five.webp'
            },
            {
                alignment: 'text-center',
                image: 'assets/images/website/slider/three.webp'
            },
            {
                alignment: 'text-center',
                image: 'assets/images/website/slider/six.webp'
            }
        ];
    }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        this.playSlider();
    }

    playSlider(): void {
        const rightControlBtn = document.getElementById('right-control');
        this.imageSliderIntervalId = setInterval(() => {
            rightControlBtn?.click();
        }, 5000)
    }

    ngOnDestroy(): void{
        clearInterval(this.imageSliderIntervalId);
    }
}
