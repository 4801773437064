import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { Alert } from '../../entities/global/alert.interface';
declare var $: any;

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  constructor(public alertService: AlertService) { }

  alertArray: Array<any> = [];

  alertIcons: any = {
    success: 'bi bi-check-lg',
    danger: 'bi bi-x-lg',
    info: 'bi bi-info-lg',
    warning: 'bi bi-exclamation-triangle'
  };

  alertBackground: any = {
    success: 'alertbg-success',
    danger: 'alertbg-danger',
    info: 'alertbg-info',
    warning: 'alertbg-warning'
  };

  ngOnInit(): void {
    // tslint:disable-next-line: deprecation
    this.alertService.alertObservable.subscribe((alert: Alert) => {
      if (!$.isEmptyObject(alert)) {
        this.alertArray.push(alert);
        setTimeout(() => {
          $('.toast').toast({ animation: true, autohide: true, delay: 5000 });
          $('.toast').toast('show').on('hidden.bs.toast', () => {
            this.alertArray = [];
          });
        });
      }
    });
  }
}
