export const LABEL_LIST: any = {
  confirmPassword: 'Confirm Password',
  newPassword: 'New Password',
  oldPassword: 'Old Password',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  otherName: 'Other Name',
  role: 'Role',
  image: 'Photo',
  cost: 'Cost',
  startTime: 'Start Time',
  endTime: 'EndTime',
  name: 'Name',
  timezone: 'Time Zone',
  company: 'Company',
  location: 'Location',
  phoneNo: 'Tel:',
  description: 'Description',
  code: 'Code',
  normal: 'Normal',
  scanType: 'Scan Type',
  phone: 'Phone',
  reason: 'Reason',
  address: 'Address',
  userName: 'User Name',
  dob: 'Date of Birth',
  gender: 'Gender',
  studyDate: 'Study Date',
  studyType: 'Study Type',
  level: 'Level',
  readType: 'Read Type',
  referringPhysician: 'Referring Physician',
  entityType: 'Entity Type',
  contrast: 'Contrast',
  contrastType: 'Contrast Type',
  modality: 'Modality',
  accession: 'Accession',
  studyFile: 'Upload Study Dicom File',
  updateFile: 'Upload New Study File',
  studyAttachments: 'Study Attachments',
  reportAttachments: 'Report Attachments',
  radiologist: 'Radiologist',
  facility: 'Facility',
  clinicalHistory: 'Clinical History',
  report: 'Report',
  addendum: 'Addendum',
  patientName: 'Patient Name',
  signature: 'Signature',
  age: 'Patient Age',
  patientPhone: 'Patient Phone',
  descriptionType: 'Description/Examination/StudyType',
  patientId: 'Patient ID',
  medicalCenterId: 'Medical Center',
  reviewComment: 'Review Comment'
};
