<div class="row">
  <div class="col-lg-12">
    <span class="text-primary font-weight-bold"
      >Studies By Medical Centers</span
    >
    <div class="table-card shadow-sm">
      <div class="table-card-body">
        <div class="table-responsive mt-3">
          <table
            class="table themed-table table-sticky-column themed-table-plain"
          >
            <thead>
              <tr>
                <th class="cell stick">
                  <div class="cell-border">
                    <span>Medical Center</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Total Studies</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Report Studies</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border justify-content-center">
                    <span>Pending Studies</span>
                  </div>
                </th>
                <th class="cell">
                  <div class="cell-border d-flex justify-content-center">
                    <span>Flagged Studies</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let center of medicalCenterList">
                <td>{{ center.medicalCenter }}</td>
                <td class="text-center">{{ center.totalStudies }}</td>
                <td class="text-center">{{ center.submittedStudies }}</td>
                <td class="text-center">{{ center.pendingStudies }}</td>
                <td class="text-center">{{ center.flaggedStudies }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
