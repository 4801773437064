import { Component, OnInit } from '@angular/core';
import { StateManagerService } from 'src/app/services/state/state-manager.service';
import { ConnectionService } from 'src/app/services/websocket/connection.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  constructor(
    private connectionService: ConnectionService,
    private stateManagerService: StateManagerService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.sideMenuResponsive();
      this.searchResponsive();
    });
  }

  sideMenuResponsive(): void {
    const sidePanelToggler = document.getElementById('sidepanel-toggler');
    const sidePanel = document.getElementById('app-sidepanel');
    const sidePanelDrop = document.getElementById('sidepanel-drop');
    const sidePanelClose = document.getElementById('sidepanel-close');

    window.addEventListener('load', () => {
      responsiveSidePanel();
    });

    window.addEventListener('resize', () => {
      responsiveSidePanel();
    });

    const responsiveSidePanel = () => {
      if (sidePanel) {
        const w = window.innerWidth;
        if (w >= 1200) {
          sidePanel.classList.remove('sidepanel-hidden');
          sidePanel.classList.add('sidepanel-visible');
        } else {
          sidePanel.classList.remove('sidepanel-visible');
          sidePanel.classList.add('sidepanel-hidden');
        }
      }
    };

    if (sidePanel && sidePanelToggler && sidePanelClose && sidePanelDrop) {
      sidePanelToggler.addEventListener('click', () => {
        if (sidePanel.classList.contains('sidepanel-visible')) {
          sidePanel.classList.remove('sidepanel-visible');
          sidePanel.classList.add('sidepanel-hidden');
        } else {
          sidePanel.classList.remove('sidepanel-hidden');
          sidePanel.classList.add('sidepanel-visible');
        }
      });

      sidePanelClose.addEventListener('click', (e) => {
        e.preventDefault();
        sidePanelToggler.click();
      });

      sidePanelDrop.addEventListener('click', (e) => {
        sidePanelToggler.click();
      });
    }
  }

  searchResponsive(): void {
    const searchMobileTrigger = document.querySelector(
      '.search-mobile-trigger'
    );
    const searchBox = document.querySelector('.app-search-box');

    if (searchMobileTrigger) {
      searchMobileTrigger.addEventListener('click', () => {
        if (searchBox) {
          searchBox.classList.toggle('is-visible');
        }

        const searchMobileTriggerIcon = document.querySelector(
          '.search-mobile-trigger-icon'
        );
        if (searchMobileTriggerIcon) {
          if (searchMobileTriggerIcon.classList.contains('fa-search')) {
            searchMobileTriggerIcon.classList.remove('fa-search');
            searchMobileTriggerIcon.classList.add('fa-times');
          } else {
            searchMobileTriggerIcon.classList.remove('fa-times');
            searchMobileTriggerIcon.classList.add('fa-search');
          }
        }
      });
    }
  }
}
