import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationQuery } from 'src/app/store/notification/notification.query';
import { DocNotificationService } from './doc-notification.service';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-doc-notification',
  templateUrl: './doc-notification.component.html',
  styleUrls: ['./doc-notification.component.scss'],
})
export class DocNotificationComponent implements OnInit, OnDestroy {
  notifications$!: Observable<any[]>;
  unReadNotificationsSub!: Subscription;

  constructor(
    public notificationQuery: NotificationQuery,
    public docNotification: DocNotificationService
  ) {}

  ngOnInit(): void {
    this.markAsRead();
    this.notifications$ = this.notificationQuery.getAllNotifications();
  }

  markAsRead(): void {
    this.unReadNotificationsSub = this.notificationQuery
      .getUnreadNotifications()
      .pipe(
        tap((notifications) => {
          // unread notification ids
          const ids = notifications.map((notification) => notification.id);

          // mark as read if list not empty
          if (ids.length > 0) {
            this.docNotification.markAsRead(ids, () => {
              this.unReadNotificationsSub?.unsubscribe();
            });
          }
        })
      )
      .subscribe();
  }

  formatTime(date: number): string {
    const time: Moment = moment(new Date(date));
    return time.calendar();
  }

  deleteNotification(id: number): void {
    this.docNotification.deleteNotification(id);
  }

  ngOnDestroy(): void {
    this.unReadNotificationsSub?.unsubscribe();
  }
}
