<div class="page-loader-wrapper d-flex h-100 align-items-center justify-content-center">
  <div class="">
    <div class="preloader">
      <div class="spinner-layer pl-green">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
    <p>Please wait ...</p>
  </div>
</div>