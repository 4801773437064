import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
import { ConnectionService } from 'src/app/services/websocket/connection.service';
import { NotificationStore } from './notification.store';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationTopicPrefix!: string;
  notificationTopicSub!: Subscription;
  notificationMessageSub!: Subscription;

  constructor(
    private alertService: AlertService,
    private apiConnect: ApiConnectionService,
    private notificationStore: NotificationStore,
    private connectionService: ConnectionService,
    private sessionService: SessionStorageService
  ) {
    this.notificationTopicPrefix =
      this.sessionService.getUserApplicationType() === 'DOCTOR'
        ? 'doctor'
        : this.sessionService.getRegistrationToken();

    this.subscribeToNotifications();
    this.onNewNotificationMessage();
    this.initializeNotificationList();
  }

  initializeNotificationList(): void {
    this.apiConnect
      .callAPI('GET', 'Notification', undefined, undefined, undefined)
      .subscribe(
        (res: any) => {
          this.notificationStore.set(res.data);
        },
        (err: any) => {
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  subscribeToNotifications(): void {
    this.notificationTopicSub = this.connectionService.subcribeToTopic(
      'notification',
      this.notificationTopicPrefix
    );
  }

  onNewNotificationMessage(): void {
    this.notificationMessageSub = this.connectionService.onMessageSub(
      this.connectionService.tranfromTopic(
        'notification',
        this.notificationTopicPrefix
      ),
      this.notificationMessageHandler.bind(this)
    );
  }

  notificationMessageHandler(data: any): void {
    this.notificationStore.add(data, { prepend: true });
  }

  unSubscribeFromNotifications(): void {
    this.connectionService.unSubcribeToTopic(
      this.connectionService.tranfromTopic(
        'notification',
        this.notificationTopicPrefix
      )
    );
  }

  destroySubscriptions(): void {
    this.notificationTopicSub?.unsubscribe();
    this.notificationMessageSub?.unsubscribe();
    this.unSubscribeFromNotifications();
  }
}
