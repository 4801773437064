import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiException } from '../api/apilink.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    public router: Router,
    private alert: AlertService,
    public alertService: AlertService,
    public apiCall: ApiConnectionService,
    public sessionStorageService: SessionStorageService
  ) { }

  login(form: any, onLogin: (res: any, status: boolean) => void): void {
    this.apiCall.callAPI('POST', 'Login', form)
      .subscribe(
        (res: any) => {
          onLogin(res, true);
        },
        (err: ApiException) => {
          onLogin(err, false);
        }
      );
  }

  updateUserPassword(form: any, userId: number): void {
    this.apiCall.callAPI('PUT', 'UserPassword', form, undefined, userId)
      .subscribe(
        (res: any) => {
          this.logout();
        },
        (err: ApiException) => {
          this.alert.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message
          });
        }
      );
  }

  submitRecoveryEmail(form: any, result: (res: any, status: boolean) => void): void {
    this.apiCall.callAPI('POST', 'Recover', form, undefined, undefined)
      .subscribe(
        (res: any) => {
          result(res, true);
        },
        (err: any) => {
          result(err, false);
        });
  }

  validateLink(link: string, result: (res: any, status: boolean) => void): void {
    this.apiCall.callAPI('POST', 'RecoverValidate', { link }, undefined, undefined)
      .subscribe(
        (res: any) => {
          result(res, true);
        },
        (err: any) => {
          result(err, false);
        });
  }

  changePassword(form: any, result: (res: any, status: boolean) => void) {
    this.apiCall.callAPI('POST', 'RecoverUpdatePassword', form, undefined, undefined)
      .subscribe(
        (res: any) => {
          result(res, true);
        },
        (err: any) => {
          result(err, false);
        });
  }

  logout(): void {
    this.alertService.setAlert(null)
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}

