<div
  id="app-notification-panel"
  class="app-notification-panel"
  [ngClass]="{
    'notification-panel-hidden': !(isOpen() | async),
    'notification-panel-visible': (isOpen() | async)
  }"
>
  <div
    (click)="close()"
    id="notification-panel-drop"
    class="notification-panel-drop"
  ></div>
  <div class="notification-panel-inner">
    <a
      href="javascript:void(0);"
      id="notification-panel-close"
      class="notification-panel-close"
      (click)="close()"
      >×</a
    >
    <!-- content -->
    <app-doc-notification
      *ngIf="(getType() | async) === 'DocNotification'"
    ></app-doc-notification>

    <app-hos-notification
      *ngIf="(getType() | async) === 'HosNotification'"
    ></app-hos-notification>
    <!-- content -->
  </div>
</div>
