import { ConstantPool } from '@angular/compiler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  businessName: string = 'Access Teleradiology';

  constructor() {}

  formatDate(date: Date): number {
    return date.getTime();
  }

  formatDateToSlashes(date: Date): string {
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('/');
  }

  formatToLocalTime(hours: number, minutes: any): string {
    let h = hours;
    let m = minutes;
    if (hours > 12) {
      h = hours - 12;
    }

    const h1 = h < 10 ? '0' + h : h;

    if (typeof minutes === 'number') {
      m = minutes < 10 ? '0' + minutes : minutes;
    }
    const ampm = hours < 12 ? ' AM' : ' PM';
    const time = h1 + ':' + m + ampm;
    return time;
  }

  formatToLocalTime24(hours: number, minutes: any): string {
    const h = hours;
    let m = minutes;
    const h1 = h < 10 ? '0' + h : h;

    if (typeof minutes === 'number') {
      m = minutes < 10 ? '0' + minutes : minutes;
    }

    const time = h1 + ':' + m;
    return time;
  }

  getCurrentDayDate(): number {
    return this.formatDate(new Date());
  }

  daysInMonth(anyDateInMonth: Date): number {
    return new Date(
      anyDateInMonth.getFullYear(),
      anyDateInMonth.getMonth() + 1,
      0
    ).getDate();
  }

  getCurrentWeekDates(): { first: any; last: any } {
    const currentDate: Date = Object.assign(new Date());

    const firstDay = currentDate.getDate() - currentDate.getDay();
    const firstTemp: Date = Object.assign(currentDate);
    const first = this.formatDate(new Date(firstTemp.setDate(firstDay)));

    if (firstDay < 0) {
      const lastTemp: Date = Object.assign(currentDate);
      const lastDay = firstDay + 6 + this.daysInMonth(lastTemp);
      var last = this.formatDate(new Date(lastTemp.setDate(lastDay)));
    } else {
      const lastDay = firstDay + 6;
      const lastTemp: Date = Object.assign(currentDate);
      var last = this.formatDate(new Date(lastTemp.setDate(lastDay)));
    }

    return Object.assign({
      first,
      last,
    });
  }

  getCurrentMonthDates(): { first: any; last: any } {
    const currentDate = new Date();
    const firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    return { first: this.formatDate(firstDay), last: this.formatDate(lastDay) };
  }

  getCurrentYearDates(): { first: any; last: any } {
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), 0, 1);
    const lastDay = new Date(currentDate.getFullYear(), 12, 0);
    return { first: this.formatDate(firstDay), last: this.formatDate(lastDay) };
  }

  getDayOfWeek(date: Date): any {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return days[date.getDay()];
  }

  dateIsValid(dateString: { toString: () => string }): boolean {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (dateString.toString().match(regEx)) {
      return true;
    }
    return false;
  }

  getHour(value: number): number {
    const hours = Math.floor(value / 60);
    return hours;
  }

  getMinute(value: number): number {
    const minutes = Math.floor(value % 60);
    return minutes;
  }

  transformMinutesToHours(value: number): string {
    const hours = Math.floor(value / 60);
    const minutes = Math.floor(value % 60);
    return `${hours} hrs ${minutes} min`;
  }

  getBusinessName(): string {
    return this.businessName;
  }
}
