import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';

@Component({
  selector: 'app-validate-user',
  templateUrl: './validate-user.component.html',
  styleUrls: ['./validate-user.component.scss'],
})
export class ValidateUserComponent implements OnInit {
  passwordControl: FormControl;
  title!: string;
  loading = false;
  bsModalRef!: BsModalRef;

  onValidate!: (status: boolean) => void;

  constructor(
    private apiConnectService: ApiConnectionService,
    public alertService: AlertService
  ) {
    this.passwordControl = new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]);
  }

  ngOnInit(): void {}

  submit() {
    this.loading = true;
    this.apiConnectService
      .callAPI('POST', 'ValidateUser', {
        password: this.passwordControl.value,
      })
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.bsModalRef.hide();
          this.onValidate(true);
        },
        (err: any) => {
          this.loading = false;
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }
}
