<div class="form-group" [ngClass]="mergeClassString(fgmb, marginClass)">
  <label *ngIf="label !== ''" [for]="fieldId" class="label text-muted">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>
  <div class="elroy-select-wrapper">
    <div class="elroy-select" [ngClass]="uniqueClass">
      <div class="elroy-select__trigger" [ngClass]="borderClass">
        <span>{{ currentDisplayValue }}</span>
        <div class="arrow"></div>
      </div>
    </div>

    <div class="elroy-options" [ngClass]="uniqueClass">
      <div class="d-block p-2">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="searchFilter" placeholder="search...">
      </div>
      <span *ngFor="let opt of iteratableOptions| filter :filterList :searchFilter :false; let i = index"
        class="elroy-option" [attr.id]="opt.display + i" (click)="onSelectOption(opt.ngValue, opt.display)"
        data-value="tesla">{{ opt.display }}
      </span>
    </div>
  </div>
</div>
