import { Component, OnInit, TemplateRef } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { environment } from 'src/environments/environment.prod';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';
import { AlertService } from 'src/app/services/alert/alert.service';


class BaseClass { }
interface BaseClass extends ModalComponent { }
applyMixins(BaseClass, [ModalComponent]);
@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseClass implements OnInit {

    assetsRoot = environment.imageUrl;
    users: Array<any> = [];
    userDetails!: any;
    userActiveId!: number | undefined;
    userEditForm!: FormGroup;
    userNewForm!: FormGroup;
    user: BehaviorSubject<any> = new BehaviorSubject(null);
    loadingUsers = false;
    loadingDetails = false;

    updateUserBtn: object = {
        method: () => {
            this.updateUser();
        },
        text: 'update'
    };

    saveUserBtn: object = {
        method: () => {
            this.saveUser();
        },
        text: 'save'
    };

    saving = false;
    updating = false;

    constructor(
        public apiConnect: ApiConnectionService,
        public modalService: BsModalService,
        public userService: UserService,
        private alertService: AlertService
    ) {
        super();

        this.userNewForm = new FormGroup({
            firstName: new FormControl(),
            lastName: new FormControl(),
            email: new FormControl(),
            phoneNumber: new FormControl(),
            role: new FormControl()
        });

        this.userEditForm = new FormGroup({
            firstName: new FormControl(),
            lastName: new FormControl(),
            email: new FormControl(),
            phoneNumber: new FormControl(),
            role: new FormControl()
        });
    }

    ngOnInit(): void {
        this.getUsers();
    }

    getUsers(): void {
        this.loadingUsers = true;
        this.userService.getUsers((res, status) => {
            this.loadingUsers = false;
            if (status) {
                this.users = res;
                if (this.userActiveId) {
                    this.selectUser(this.userActiveId, `user${this.userActiveId}`);
                } else {
                    this.selectUser(this.users[0].id, `user${this.users[0].id}`);
                }
            }
        })
    }

    getUserDetails(id: number): void {
        this.loadingDetails = true;
        this.userService.getUserDetails(id, (res, status) => {
            this.loadingDetails = false;
            if (status) {
                this.userDetails = res;
            }
        });
    }

    selectUser(id: number, idString: string): void {
        // clear active users
        const usersTr: NodeListOf<Element> = document.querySelectorAll('.select-user');
        usersTr.forEach((element: Element) => {
            element.classList.remove('active');
        });

        // set active user
        setTimeout(() => {
            const user: HTMLElement | null = document.getElementById(idString);
            user ? user.classList.add('active') : null;
        })

        // get user details
        this.getUserDetails(id);
        this.userActiveId = id;
        this.user.next(this.userActiveId);
    }

    saveUser(): void {
        this.saving = true;
        this.userService.saveUser(this.userNewForm.value, (res, status) => {
            this.saving = false;
            if (status) {
                this.userActiveId = res.id;
                this.closeModal();
                this.getUsers();
            }
        });
    }

    editUser(temp: TemplateRef<any>): void {
        this.userEditForm = new FormGroup({
            id: new FormControl(this.userDetails.id),
            firstName: new FormControl(this.userDetails.firstName, Validators.required),
            lastName: new FormControl(this.userDetails.lastName, Validators.required),
            email: new FormControl(this.userDetails.email, Validators.required),
            phoneNumber: new FormControl(this.userDetails.phoneNumber),
            role: new FormControl(this.userDetails.role)
        });

        this.modalService.show(temp);
    }

    updateUser(): void {
        this.updating = true;
        this.userService.updateUser(
            this.userEditForm.value.id, this.userEditForm.value,
            (res, status) => {
                this.updating = false;
                if (status) {
                    this.closeModal();
                    this.getUsers();
                }
            });
    }

    confirmReset(): void {
        this.alertService.openAlertModal(
            {
                type: 'warning',
                message:
                    'Are you sure you want to reset this user\'s password, click ok to proceed',
            },
            'ok',
            (close: () => void) => {
                this.resetPassword();
                close();
            }
        );
    }

    resetPassword(): void {
        if (this.userActiveId) {
            this.userService.resetUserPassword(this.userActiveId, (res, status) => { });
        }
    }

    onResetPassword(res: any, status: string, callback: any) {
        if (status === 'success') {
            callback('success', 'Success', res.message);
        } else {
            callback('danger', 'Error', res.message);
        }
    }

    confirmDelete(): void {
        this.alertService.openAlertModal(
            {
                type: 'danger',
                message:
                    'Are you sure you want to delete this user, click ok to proceed',
            },
            'ok',
            (close: () => void) => {
                this.deleteUser();
                close();
            }
        );
    }

    deleteUser(): void {
        if (this.userActiveId)
            this.userService.deleteUser(this.userActiveId, (res, status) => {
                if (status) {
                    this.getUsers();
                }
            });
    }

    customFormControl(name: string, group: FormGroup): FormControl {
        return group.get(name) as FormControl;
    }
}
