import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';

@Injectable({
  providedIn: 'root',
})
export class ArchiveStudiesService {
  constructor(
    public apiConnect: ApiConnectionService,
    public alertService: AlertService
  ) {}

  getPoolStudies(query: any, onGet: (res: any, status: boolean) => void): void {
    this.apiConnect
      .callAPI('GET', 'ManagePool', undefined, query, undefined)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }

  archiveStudy(
    studies: any,
    onGet: (res: any, status: boolean) => void
  ): void {
    this.apiConnect
      .callAPI('POST', 'ArchiveStudies', studies, undefined, undefined)
      .subscribe(
        (res: any) => {
          onGet(res.data, true);
        },
        (err: any) => {
          onGet(err, false);
          this.alertService.setAlert({
            type: 'danger',
            title: 'Error',
            message: err.message,
          });
        }
      );
  }
}
