import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { StudiesService } from './studies.service';
import { ClientService } from '../clients/client.service';
import { RadiologistService } from '../radiologist/radiologist.service';
import { PoolService } from '../pool/pool.service';
import { ValidateUserService } from 'src/app/components/validate-user/validate-user.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';

class BaseClass {}
interface BaseClass
  extends ModalComponent,
    PaginationComponent,
    FilterComponent {}
applyMixins(BaseClass, [ModalComponent, PaginationComponent, FilterComponent]);

@Component({
  selector: 'app-doc-studies',
  templateUrl: './studies.component.html',
  styleUrls: ['./studies.component.scss'],
})
export class StudiesComponent
  extends BaseClass
  implements OnInit, AfterViewInit, OnDestroy
{
  elementsPerPage = 10;
  activePageList!: any[];

  showEntries!: FormControl;
  searchFilter!: string;
  assetsRoot = environment.imageUrl;

  studies: Array<any> = [];
  clients: Array<any> = [];
  radiologists: Array<any> = [];
  openFilter = false;
  queryForm!: FormGroup;
  subscription!: Subscription;
  currentParams: any;
  searchByStudyIdForm!: FormControl;
  gettingStudies = false;

  flagStudyForm!: FormGroup;
  flagStudyBtn = {
    method: () => this.flagStudy(),
    text: 'flag',
  };
  flagging = false;

  radioPredicate: object = {
    method1: (obj: any) => obj.id,
    method2: (obj: any) => [obj.user.firstName, obj.user.lastName].join(' '),
  };

  clientsPredicate: object = {
    method1: (obj: any) => obj.id,
    method2: (obj: any) => [obj.name].join(' '),
  };

  constructor(
    public router: Router,
    public dom: DomSanitizer,
    public location: Location,
    public route: ActivatedRoute,
    public poolService: PoolService,
    public clientService: ClientService,
    public modalService: BsModalService,
    public studiesService: StudiesService,
    public radiologistService: RadiologistService,
    public validateUserService: ValidateUserService,
    public settingService: SettingsService,
    public sessionStorageService: SessionStorageService
  ) {
    super();
    const dates = this.settingService.getCurrentWeekDates();
    this.queryForm = new FormGroup({
      status: new FormControl('all'),
      level: new FormControl(null),
      studyDate: new FormControl([dates.first, dates.last]),
      radiologist: new FormControl(null),
      facility: new FormControl(null),
    });

    this.flagStudyForm = new FormGroup({
      id: new FormControl(),
      reason: new FormControl(),
    });

    this.showEntries = new FormControl(10);
    this.filterList = ['patientName', 'studyUID', 'studyType|name', 'status'];

    this.searchByStudyIdForm = new FormControl(null, Validators.required);
  }

  ngOnInit(): void {
    this.route.queryParams.forEach((params: any) => {
      if (this.isEmpty(params)) {
        this.router.navigate(['/app/doc/studies'], {
          queryParams: { status: 'all' },
        });
      } else {
        this.prefillForm(params);
        this.getStudies(params);
      }
    });

    this.queryBuilder();
    this.getRadiologist();
    this.getClients();

    this.showEntries.valueChanges.subscribe((val) => {
      this.elementsPerPage = val;
    });
  }

  ngAfterViewInit(): void {
    const statusBtn: NodeListOf<Element> =
      document.querySelectorAll('.status-btn');
    statusBtn.forEach((btn: Element, index: number) => {
      if (
        btn.getAttribute('status-data') ===
        this.customFormControl('status').value
      ) {
        btn.classList.add('active');
      }

      btn.addEventListener('click', (ev) => {
        statusBtn.forEach((ele: Element) => {
          ele.classList.remove('active');
        });

        btn.classList.add('active');
        if (btn.classList.contains('active')) {
          this.queryForm
            .get('status')
            ?.setValue(btn.getAttribute('status-data'));
        }
      });
    });
  }

  isAssignedOrReviewer(reviewerList: any[], assigned: any): string {
    if (assigned?.user?.id == this.sessionStorageService.getUserId()) {
      return 'assignee';
    } else if (
      reviewerList
        .map((t: any) => t?.user?.id)
        .includes(this.sessionStorageService.getUserId())
    ) {
      return 'reviewer';
    } else {
      return '';
    }
  }

  customFormControl(name: string): FormControl {
    return this.queryForm.get(name) as FormControl;
  }

  prefillForm(param: any): void {
    for (const key in param) {
      if (Object.prototype.hasOwnProperty.call(param, key)) {
        if (this.queryForm.contains(key)) {
          if (key === 'studyDate') {
            this.queryForm
              .get(key)
              ?.setValue(
                [
                  new Date(+param['startDate']).getTime(),
                  new Date(+param['endDate']).getTime(),
                ],
                { emitEvent: false }
              );
          } else {
            this.queryForm.get(key)?.setValue(param[key], { emitEvent: false });
          }
        }
      }
    }
  }

  queryBuilder(): void {
    this.queryForm.valueChanges.forEach((form: any) => {
      let param: any = {};
      for (const key in form) {
        if (Object.prototype.hasOwnProperty.call(form, key)) {
          if (form[key] !== null) {
            if (key === 'studyDate') {
              if (form[key].length > 0) {
                param['startDate'] = form[key][0];
                param['endDate'] = form[key][1];
                param[key] = form[key];
              }
            } else {
              param[key] = form[key];
            }
          }
        }
      }

      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.router.navigate(['/app/doc/studies'], { queryParams: param });
    });
  }

  clearFilters(): void {
    this.queryForm.reset({
      status: 'all',
      studyDate: [],
      radiologist: null,
      level: null,
      facility: null,
    });
  }

  isEmpty(obj: any): boolean {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  paginatorEvent(change: any): void {
    setTimeout(() => {
      this.activePageList = change.currentChunk;
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['patientName'];
    }
  }

  back(): void {
    this.location.back();
  }

  openBackendFilter() {
    const filter: HTMLElement | null =
      document.getElementById('backend-filter');
    if (filter) {
      filter.classList.remove('d-none');
    }
    this.openFilter = true;
  }

  clearBackendFilter() {
    const filter: HTMLElement | null =
      document.getElementById('backend-filter');
    if (filter) {
      filter.classList.add('d-none');
    }
    this.openFilter = false;
  }

  getStudies(params: any): void {
    this.gettingStudies = true;
    this.studiesService.getStudies(params, (res, status) => {
      this.gettingStudies = false;
      if (status) {
        this.studies = res;
      }
    });
  }

  getRadiologist(): void {
    this.radiologistService.getRadiologist((res, status) => {
      if (status) {
        this.radiologists = res;
      }
    });
  }

  getClients(): void {
    this.clientService.getMedicalCenters((res, status) => {
      if (status) {
        this.clients = res;
      }
    });
  }

  returnToPool(studyId: number): void {
    this.poolService.returnStudy({ studies: [studyId] }, (res, status) => {
      if (status) {
        this.getStudies(this.currentParams);
      }
    });
  }

  searchStudyById(): void {
    this.router.navigate(['/app/doc/manage-pool'], {
      queryParams: { status: 'all', studyId: this.searchByStudyIdForm.value },
    });
  }

  viewStudy(id: number, uid: any): void {
    this.router.navigate(['/app/doc/studies/single'], {
      queryParams: { studyId: id, studyUID: uid },
    });
  }

  openInDicomViewer(studyInstanceUID: any) {
    window.open(
      `${environment.ohifWebUrl}?StudyInstanceUIDs=${studyInstanceUID}`
    );
  }

  openWithWeasisViewer(studyUID: string, parentID: string) {
    if (studyUID) {
      window.location.href =
        'weasis://' +
        encodeURIComponent(
          `$dicom:rs --url "${environment.weasisWebUrl}" -r ` +
            'studyUID=' +
            studyUID
        );
      return;
    }

    if (parentID) {
      window.location.href =
        'weasis://' +
        encodeURIComponent(
          `$dicom:rs --url "${environment.weasisWebUrl}" -r ` +
            'patientID=' +
            parentID
        );
      return;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  openFlagStudy(template: TemplateRef<any>, id: number): void {
    this.flagStudyForm = new FormGroup({
      id: new FormControl(id),
      reason: new FormControl(),
    });
    this.modalService.show(template);
  }

  flagStudy(): void {
    this.flagging = true;
    this.studiesService.flagStudy(this.flagStudyForm.value, (res, status) => {
      this.flagging = false;
      if (status) {
        this.getStudies(this.currentParams);
        this.closeModal();
      }
    });
  }

  editStudy(id: number): void {
    this.validateUserService.validateUser(
      this.onConfirmEditStudy.bind(this, id)
    );
  }

  onConfirmEditStudy(id: number): void {
    this.studiesService.editStudyReport(id, (res, status) => {
      if (status) {
        this.getStudies(this.currentParams);
      }
    });
  }

  customForm(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }
}
