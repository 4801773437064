import { Component, Input, OnInit } from '@angular/core';
import * as ApexCharts from 'apexcharts';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-pie-chart',
    templateUrl: './pie.component.html',
    styleUrls: ['./pie.component.scss']
})
export class PieChartComponent implements OnInit {

    @Input() strokeColor?: any[];
    @Input() chartId?: string;
    @Input() chartType: string = 'donut';
    @Input() chartWidth: number = 60;
    @Input() chartGroup?: string;
    @Input() chartData: any[] = [];
    @Input() xaxisCategories?: any[];
    @Input() dataSubject: BehaviorSubject<any> = new BehaviorSubject(null);

    chartOptions: any;
    chartPlaceHolder: any;

    constructor() { }

    ngOnInit(): void {
        this.setChartOptions();
        setTimeout(() => {
            this.chartPlaceHolder = new ApexCharts(document.querySelector('#' + this.chartId), this.chartOptions);
            this.chartPlaceHolder.render();

            // update data series
            this.dataSubject?.subscribe(val => {
                if (val !== null) {
                    this.chartPlaceHolder.updateOptions({
                        ...this.chartOptions,
                        labels: val.labels,
                        series: val.series,
                    });
                }
            });
        });
    }

    setChartOptions(): void {
        this.chartOptions = {
            series: this.chartData,
            labels: this.xaxisCategories,
            chart: {
                width: 350,
                type: 'donut',
            },
            colors: [...this.strokeColor || ''],
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 500,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: {
                position: 'bottom',
                horizontalAlign: 'center'
            }
        };
    }
}
