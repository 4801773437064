import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { NewStudyService } from '../new-study.service';

class BaseClass {}
interface BaseClass extends PaginationComponent, FilterComponent {}
applyMixins(BaseClass, [PaginationComponent, FilterComponent]);

@Component({
  selector: 'app-search-patient',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.scss'],
})
export class SearchPatientComponent extends BaseClass implements OnInit {
  searching = false;
  invalidForm = false;
  studiesList!: any[];
  queryForm!: FormGroup;
  queryPatients: Array<any> = [];

  showEntries!: FormControl;
  searchFilter!: string;
  elementsPerPage = 10;
  activePageList!: any[];

  @Output() studyDetails: EventEmitter<any> = new EventEmitter();

  constructor(public newStudyService: NewStudyService) {
    super();
    this.queryForm = new FormGroup({
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      studyId: new FormControl(null),
    });
    this.studiesList = [];

    this.showEntries = new FormControl(10);
    this.filterList = ['patientName', 'patientSex', 'patientID'];
  }

  customQueryGroup(name: string): FormControl {
    return this.queryForm.get(name) as FormControl;
  }

  ngOnInit(): void {
    this.showEntries.valueChanges.subscribe((val) => {
      this.elementsPerPage = val;
    });
  }

  searchStudy(): void {
    this.searching = true;
    this.newStudyService.searchStudy(
      removeNullEntry(this.queryForm.value),
      (res, status) => {
        this.searching = false;
        if (status) {
          this.convertObjectToArray(res);
        }
      }
    );
  }

  convertObjectToArray(data: any): void {
    this.studiesList = [];
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.studiesList.push(data[key]);
      }
    }
  }

  onSelectStudy(study: any): void {
    this.studyDetails.emit(study);
  }

  paginatorEvent(change: any): void {
    setTimeout(() => {
      this.activePageList = change.currentChunk;
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['patientName'];
    }
  }
}

export function removeNullEntry(data: any): any {
  const temp: any = {};
  for (const m of Object.keys(data)) {
    if (data[m] !== null && data[m] !== '') {
      temp[m] = data[m];
    }
  }
  return temp;
}
