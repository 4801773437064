import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { ClientService } from '../../clients/client.service';
import { ReportTemplateService } from '../report-template.service';
@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss'],
})
export class EditTemplateComponent implements OnInit {
  editReportTemplateForm!: FormGroup;
  templateVariables!: any[];
  updating = false;
  loading = false;
  showPreview = false;
  templateId!: number;
  templateDetails!: any;
  clients: any[] = [];

  constructor(
    public location: Location,
    public router: ActivatedRoute,
    public clientService: ClientService,
    public reportService: ReportTemplateService
  ) {
    this.templateVariables = [];

    this.editReportTemplateForm = new FormGroup({
      description: new FormControl(),
      report: new FormControl(),
      medicalCenterId: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.router.queryParams.subscribe((val: any) => {
      if (val) {
        this.templateId = val.id;
        this.getTemplateDetails(this.templateId);
      }
    });
    this.getTemplateVariables();
    this.getClients();
  }

  getClients(): void {
    this.clientService.getMedicalCenters((res, status) => {
      if (status) {
        this.clients = res;
      }
    });
  }

  getTemplateVariables(): void {
    this.loading = true;
    this.reportService.getReportTemplateVariables((res, status) => {
      this.loading = false;
      if (status) {
        this.templateVariables = res;
      }
    });
  }

  processPreview(report: string): void {
    const renderTemlplate: HTMLElement | null =
      document.getElementById('render-template');
    if (renderTemlplate) {
      renderTemlplate.innerHTML = this.replaceVariables(report);
    }
  }

  replaceVariables(report: string): string {
    let finalString = report;
    for (let i = 0; i < this.templateVariables.length; i++) {
      let searchRegExp = new RegExp(this.templateVariables[i].tempString, 'g');
      let replaceExp = this.templateVariables[i].sample;
      finalString = finalString.replace(searchRegExp, replaceExp);
    }
    return finalString;
  }

  previewTemplate(): void {
    this.showPreview = true;
  }

  closePreview(): void {
    this.showPreview = false;
  }

  getTemplateDetails(id: number): void {
    this.reportService.getReportTemplateDetails(id, (res, status) => {
      if (status) {
        this.templateDetails = res;
        this.prefillForm(res);
      }
    });
  }

  prefillForm(data: any): void {
    this.editReportTemplateForm = new FormGroup({
      id: new FormControl(data.id),
      description: new FormControl(data.description),
      report: new FormControl(data.report),
      medicalCenterId: new FormControl(data?.medicalCenter?.id),
    });
    this.processPreview(data.report);
    this.customFormControl(
      'report',
      this.editReportTemplateForm
    ).valueChanges.subscribe((val) => {
      if (val) {
        this.processPreview(val);
      }
    });
  }

  copyVariable(symbol: string, id: number): void {
    let input = document.createElement('input');
    input.value = symbol;
    input.select();
    document.execCommand('copy');
    navigator.clipboard.writeText(symbol);

    let icon = document.getElementById('vari' + id);
    icon?.classList.remove('bi-clipboard');
    icon?.classList.add('bi-clipboard-check');

    let btn = document.getElementById('var' + id);
    btn?.classList.remove('copy');
    btn?.classList.add('copied');

    setTimeout(() => {
      icon?.classList.remove('bi-clipboard-check');
      icon?.classList.add('bi-clipboard');

      btn?.classList.remove('copied');
      btn?.classList.add('copy');
    }, 3000);
  }

  updateTemplate(): void {
    this.updating = true;
    this.reportService.updateReportTemplate(
      this.editReportTemplateForm.value,
      (res, status) => {
        this.updating = false;
        this.getTemplateDetails(this.templateId);
      }
    );
  }

  customFormControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }

  back(): void {
    this.location.back();
  }
}
