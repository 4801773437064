import { Routes } from "@angular/router";
import { WebsiteLayoutComponent } from "src/app/layout/website-layout/website-layout.component";
import { LandingComponent } from "./landing/landing.component";

export const routes: Routes = [
    {
        path: '', component: WebsiteLayoutComponent, children: [
            {
                path: '', component: LandingComponent
            }
        ]
    }
];