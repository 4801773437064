import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { ChatNotificationStore } from './chat-notification.store';
import { ChatNotification } from './notification.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatNotificationService {
  constructor(
    private apiConnect: ApiConnectionService,
    private alertService: AlertService,
    private store: ChatNotificationStore
  ) {}

  getUserNotifications(): void {
    this.apiConnect
      .callAPI('GET', 'ChatNotification', undefined, undefined, undefined)
      .pipe(map(mapNotification))
      .subscribe(
        (res: ChatNotification[]) => {
          this.store.set(res);
        },
        (err: any) => {
          this.alertService.setAlert({
            title: 'Error',
            type: 'danger',
            message: err.message,
          });
        }
      );
  }

  markAsRead(id: number): void {
    this.apiConnect
      .callAPI('PUT', 'MarkAsRead', undefined, undefined, id)
      .subscribe(
        (res: any) => {},
        (err: any) => {}
      );
  }

  subsribeToPushNotifications(userId: number): void {}

  subsribeToMarkAsRead(userId: number): void {}
}

export const mapNotification = (res: any): ChatNotification[] => {
  return res.notifications.map((not: any): ChatNotification => {
    return {
      id: not.id,
      isRead: not.isRead,
      createdAt: not.notification_id.createdAt,
      message: not.notification_id.chatMessage,
    };
  });
};

export const mapMarkAsRead = (res: any): ChatNotification[] => {
  let notifications = JSON.parse(res.data);
  return notifications.notifications.map((not: any): ChatNotification => {
    return {
      id: not.id,
      isRead: not.isRead,
      createdAt: not.notification_id.createdAt,
      message: not.notification_id.chatMessage,
    };
  });
};
