<!-- sidebar -->
<div id="app-sidepanel" class="app-sidepanel sidepanel-visible">
  <div id="sidepanel-drop" class="sidepanel-drop"></div>
  <div class="sidepanel-inner d-flex flex-column">
    <a href="javascript:void(0);" id="sidepanel-close" class="sidepanel-close d-xl-none">×</a>
    <!-- logo -->
    <div class="app-branding">
      <a class="app-logo" href="javascript:void(0);">
        <img class="logo-icon" src="assets/images/access.png" alt="logo" />
      </a>
    </div>
    <!-- logo -->

    <nav *ngIf="userApplicationType" id="app-nav-main" class="app-nav app-nav-main flex-grow-1">
      <ul class="app-menu list-unstyled accordion" id="menu-accordion">
        <li class="nav-item" *ngFor="let menuItem of menuOptions" [ngClass]="{
            'd-none':
              menuItem.portal !== 'OPEN' &&
              menuItem.portal !== userApplicationType
          }">
          <a class="nav-link d-flex align-items-center" app-disable-link [roleQuery]="menuItem.role"
            [addClass]="'link-disabled'" [routerLink]="[menuItem.link]" [routerLinkActive]="'active'"
            href="javascript:void(0);">
            <span class="nav-icon">
              <i [ngClass]="menuItem.icon" style="font-size: large"></i>
            </span>
            <span class="nav-link-text">{{ menuItem.text }}</span>
            <span *ngIf="newStudyCount !== undefined && newStudyCount !== 0" [ngClass]="{
                'd-flex': menuItem.text === 'Manage Pool',
                'd-none': menuItem.text !== 'Manage Pool'
              }" class="ml-auto noti-dot align-items-center justify-content-center">{{ newStudyCount }}</span>
          </a>
        </li>
      </ul>
    </nav>

    <div class="app-sidepanel-footer">
      <nav class="app-nav app-nav-footer">
        <ul class="app-menu footer-menu list-unstyled">
          <!-- billable report -->
          <app-billable-report *ngIf="userApplicationType === 'DOCTOR'"></app-billable-report>

          <!-- settings -->
          <li class="nav-item" *ngFor="let menuItem of settingsMenu" [ngClass]="{
              'd-none':
                menuItem.portal !== 'OPEN' &&
                menuItem.portal !== userApplicationType
            }">
            <a class="nav-link d-flex align-items-center" app-disable-link [roleQuery]="menuItem.role"
              [addClass]="'link-disabled'" [routerLink]="[menuItem.link]" [routerLinkActive]="'active'"
              href="javascript:void(0);">
              <span class="nav-icon">
                <i [ngClass]="menuItem.icon" style="font-size: large"></i>
              </span>
              <span class="nav-link-text">{{ menuItem.text }}</span>
            </a>
          </li>

          <!-- support -->
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);">
              <span class="nav-icon">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z">
                  </path>
                  <path
                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z">
                  </path>
                </svg>
              </span>
              <span class="nav-link-text">Help & Support</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
<!-- sidebar -->
