import { map, retry } from 'rxjs/operators';
import { ChatStore } from './chat.store';
import { Injectable } from '@angular/core';
import { ApiException } from 'src/app/services/api/apilink.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ChatMessage } from 'src/app/entities/global/chat-message.interface';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';


@Injectable({
    providedIn: 'root'
})
export class ChatService {

    constructor(
        private chatStore: ChatStore,
        private alertService: AlertService,
        private apiCall: ApiConnectionService,
    ) { }

    loadMessages(conversationId: number): void {
        this.apiCall.callAPI('GET', 'Message', undefined, undefined, conversationId)
            .pipe(map(mapParams))
            .subscribe(
                (res: any) => {
                    this.chatStore.set(res.messages);
                },
                (err: ApiException) => {
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    sendMessage(form: any, conversationId: number): void {
        this.chatStore.update({ sendingMessage: true });
        this.apiCall.callAPI('POST', 'Message', form, undefined, conversationId)
            .subscribe(
                (res: any) => {
                    this.chatStore.update({ sendingMessage: false });
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: any) => {
                    this.chatStore.update({ sendingMessage: false });
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

    getMessage(messageId: number): void {
        this.apiCall.callAPI('GET', 'SingleMessage', undefined, undefined, messageId)
            .pipe(map(
                ({ message }): ChatMessage => {
                    return mapChat(message);
                }
            ))
            .subscribe(
                (res: ChatMessage) => {
                    this.chatStore.add(res);
                },
                (err: ApiException) => {
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message: err.message
                    });
                }
            )
    }

}

export const mapParams = ({ messages, ...res }: any): Array<ChatMessage> => {
    return {
        messages: messages.map((message: any): ChatMessage => {
            return mapChat(message)
        }), ...res
    };
}

export const mapChat = (message: any) => {
    return {
        id: message.id,
        content: message.content,
        mine: message.mine,
        user: {
            id: message.user.id,
            lastName: message.user.lastName,
            firstName: message.user.firstName,
            userImage: {
                url: message.user.userImage.url,
                name: message.user.userImage.name,
                fileType: message.user.userImage.fileType
            }
        },
        createdAt: message.createdAt
    }
}