import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ExportXlsxComponent } from './export-xlsx/export-xlsx.component';
import { FilterComponent } from './filter/filter.component';
import { BaseFormComponent } from './form/base-form/base-form.component';
import { FormValidationComponent } from './form/form-validation/form-validation.component';
import { InputDateComponent } from './form/input-date/input-date.component';
import { InputFileComponent } from './form/input-file/input-file.component';
import { InputImportComponent } from './form/input-import/input-import.component';
import { InputSelectComponent } from './form/input-select/input-select.component';
import { InputTextAreaComponent } from './form/input-textarea/input-textarea.component';
import { InputComponent } from './form/input/input.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { ImportXlsxComponent } from './import-xlsx/import-xlsx.component';
import { ModalComponent } from './modal/modal.component';
import { PaginationComponent } from './pagination/pagination.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { FileUploadModule } from '@iplab/ngx-file-upload';

import { DateFormatPipe } from './pipes/date.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { PaginatorPipe } from './pipes/pagination.pipe';
import { InputEditorComponent } from './form/input-editor/input-editor.component';
import { InputSelectSearchComponent } from './form/input-select-search/input-select-search.component';
import { InputUploaderComponent } from './form/input-uploader/input-uploader.component';
import { ChatComponent } from './chat/chat.component';
import { ProgressAlertComponent } from './progress-alert/progress-alert.component';
import { PopperDirective } from './popup/popup.directive';
import { EmptyPlaceholderComponent } from './placeholders/empty-placeholder/empty-placeholder.component';
import { ErrorPlaceholderComponent } from './placeholders/error-placeholder/error-placeholder.component';
import { LoadingPlaceholderComponent } from './placeholders/loading-placeholder/loading-placeholder.component';
import { PieChartComponent } from './charts/pie/pie.component';
import { ColumnChartComponent } from './charts/column/column-chart.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { ValidateUserComponent } from './validate-user/validate-user.component';
import { ValidateUserService } from './validate-user/validate-user.service';

@NgModule({
  declarations: [
    BaseFormComponent,
    InputComponent,
    FormValidationComponent,
    ModalComponent,
    InputSelectComponent,
    ImageCropperComponent,
    PaginationComponent,
    FilterComponent,
    ImportXlsxComponent,
    ExportXlsxComponent,
    InputDateComponent,
    InputImportComponent,
    InputFileComponent,
    InputTextAreaComponent,
    InputEditorComponent,
    InputSelectSearchComponent,
    InputUploaderComponent,
    DateFormatPipe,
    FilterPipe,
    PaginatorPipe,
    ChatComponent,
    ProgressAlertComponent,
    PopperDirective,
    LoadingPlaceholderComponent,
    ErrorPlaceholderComponent,
    EmptyPlaceholderComponent,
    PieChartComponent,
    ColumnChartComponent,
    AlertModalComponent,
    ValidateUserComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    EditorModule,
    BsDatepickerModule.forRoot(),
    FileUploadModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    EditorModule,
    BsDatepickerModule,
    FileUploadModule,

    // components
    BaseFormComponent,
    InputComponent,
    FormValidationComponent,
    ModalComponent,
    InputSelectComponent,
    ImageCropperComponent,
    PaginationComponent,
    FilterComponent,
    ImportXlsxComponent,
    ExportXlsxComponent,
    InputDateComponent,
    InputImportComponent,
    InputFileComponent,
    InputTextAreaComponent,
    InputEditorComponent,
    InputSelectSearchComponent,
    InputUploaderComponent,
    DateFormatPipe,
    FilterPipe,
    PaginatorPipe,
    ChatComponent,
    ProgressAlertComponent,
    PopperDirective,
    LoadingPlaceholderComponent,
    ErrorPlaceholderComponent,
    EmptyPlaceholderComponent,
    PieChartComponent,
    ColumnChartComponent,
    AlertModalComponent,
  ],
  providers: [
    BsLocaleService,
    ValidateUserService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
})
export class ComponentsModule {}
