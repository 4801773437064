<div class="app app-login p-0">
  <div class="row g-0 app-auth-wrapper">
    <div class="
        col-12 col-md-7 col-lg-6
        auth-main-col
        text-center
        p-5
        position-relative
      ">
      <a [routerLink]="['/']" href="javascript:void(0);" class="text-primary position-absolute back-home"
        style="right: 10px; top: 10px">
        <em class="bi bi-house"></em>
      </a>

      <div class="d-flex flex-column align-content-end">
        <div class="app-auth-body mx-auto">
          <div class="app-auth-branding mb-4">
            <a class="app-logo" href="index.html">
              <img class="logo-icon" src="assets/images/access-center.png" alt="logo" /></a>
          </div>
          <h2 class="auth-heading text-center mb-5 text-muted">Login</h2>
          <div class="auth-form-container text-left">
            <form class="auth-form login-form" [formGroup]="loginForm">
              <div class="email mb-3">
                <label class="sr-only" for="signin-email">Email</label>
                <input id="signin-email" [formControl]="customFormControl('email')" name="signin-email" type="email"
                  class="form-control signin-email" placeholder="Username" required="required" />
              </div>

              <div class="password mb-3">
                <label class="sr-only" for="signin-password">Password</label>
                <input id="signin-password" [formControl]="customFormControl('password')" name="signin-password"
                  type="password" class="form-control signin-password" placeholder="Password" required="required" />
                <div class="extra mt-3 row justify-content-between">
                  <div class="col-6">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="RememberPassword" />
                      <label class="form-check-label" for="RememberPassword">
                        Remember me
                      </label>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="forgot-password text-right">
                      <a href="javascript:void(0);" [routerLink]="['/auth/recover']">Forgot password?</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-center">
                <button (click)="login()" type="submit" class="btn app-btn-primary btn-block theme-btn mx-auto">
                  <div class="text-center">
                    <span *ngIf="!loading">Log In</span>
                    <div *ngIf="loading" class="spinner-border spinner-border-sm text-white" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </button>

                <div class="mt-4">
                  <span *ngIf="loginMessage" class="text-danger">
                    {{ loginMessage }}
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
      <div class="auth-background-holder"></div>
      <div class="auth-background-mask"></div>
      <div class="auth-background-overlay p-3 p-lg-5">
        <div class="d-flex flex-column align-content-end h-100">
          <div class="h-100"></div>
          <!-- <div class="overlay-content p-3 p-lg-4 rounded">
            <h5 class="mb-3 overlay-title text-danger">Development Mode</h5>
            <div>
              {{ settingService.getBusinessName() }}
              is currently under developement, coming soon ...
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
