export const GeneralEndpoints = {
  // Login
  Login: 'login',
  Recover: 'recover-password',
  RecoverValidate: 'recover-password/validate-link',
  RecoverUpdatePassword: 'recover-password/update-password',

  // Validate user
  ValidateUser: 'validate-user',

  // Roles
  Role: 'roles',

  // ScanType
  ScanTypes: 'scanTypes',

  // Websocket api_key
  WebSocketKey: 'websocket-key',

  // Normals
  Normals: 'normals',
  NormalDetails: 'normals/details',

  // user
  User: 'user',
  UpdatePermissions: 'user/user-permission',
  ResetPassword: 'user/reset-password',
  UserImage: 'user/update-profile-image',
  UserPassword: 'user/update-user-password',

  UpdateUserAccount: 'user/user-account',
  UserSignature: 'signature',

  // dicom studies
  Study: 'studies',
  SaveDicomFile: 'instances',
  StudyAttachments: 'studies/attachments',
  ReportAddendum: 'study/report/addendum',
  ReportAttachments: 'study/report/attachments',
  QueryPatients: 'find-patient',

  // Notifications
  Notification: 'notification',
  NotificationMarkAsRead: 'notification/mark-as-read',
  NotificationMarkAsReadBulk: 'notification/mark-as-read/bulk',

  // Billable Report
  BillableReport: 'billing/monthly-billable'
};
