<div class="modal-body">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <span class="alert-icon" [ngClass]="alertBorder[type]">
      <em [ngClass]="[alertIcons[type], alertText[type]]"></em>
    </span>
    <p class="mt-3">{{ message }}</p>
  </div>

  <div class="d-flex w-100 mt-4">
    <button
      (click)="close()"
      class="btn btn-danger btn-sm ml-auto"
    >
      Cancel
    </button>
    <button (click)="actionBtn(close.bind(this))" class="btn btn-primary btn-sm ml-2">
      {{ actionTitle }}
    </button>
  </div>
</div>
