<div class="row" *ngIf="!getting">
  <div class="col-lg-12 col-md-12 col-sm-12 flex-fill">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <h6 class="text-muted font-weight-bold mb-0">
        <i class="bi bi-paperclip"></i>
        Attached Files
      </h6>
      <span
        *ngIf="studyDetails?.status !== 'REPORTED'"
        (click)="openUploadAttachment(uploadAttachmentForm)"
        class="btn btn-light btn-sm text-muted"
      >
        <i class="bi bi-plus"></i>Add File
      </span>
    </div>
    <div>
      <table class="table table-centered table-nowrap mb-0 files">
        <tbody>
          <tr
            *ngIf="!(studyDetails?.studyAttachments.length > 0)"
            class="text-center"
          >
            <td colspan="3" class="text-center border-primary text-muted">
              <span class="d-block">no attached files</span>
            </td>
          </tr>
          <tr *ngFor="let file of studyDetails?.studyAttachments">
            <td>
              <i
                *ngIf="file.type === 'pdf'"
                class="bi bi-file-earmark-pdf mr-2"
                style="font-size: 20px"
              >
              </i>
              <i
                *ngIf="file.type !== 'pdf'"
                class="bi bi-image mr-2"
                style="font-size: 20px"
              >
              </i>
              <span>{{ file.name }}</span>
            </td>
            <td>
              {{ file.type }}
            </td>
            <td class="text-right">
              <div class="btn-group dropdown">
                <a
                  href="javascript:void(0)"
                  class="table-action-btn dropdown-toggle arrow-none btn btn-light btn-xs"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-three-dots"></i>
                </a>
                <div class="dropdown-menu" data-popper-placement="bottom-end">
                  <a
                    (click)="openAttachment(file, studyAttachmentModal)"
                    class="dropdown-item"
                    href="javascript:void(0);"
                  >
                    <i class="bi bi-eye mr-2 text-muted"></i>
                    Open File
                  </a>
                  <a
                    (click)="deleteAttachments(file.id)"
                    class="dropdown-item"
                    href="javascript:void(0);"
                  >
                    <i class="bi bi-trash mr-2 text-muted"></i>
                    Delete
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- attachments -->

<div class="row mx-0" *ngIf="getting">
  <div class="col-12">
    <app-loading-placeholder
      [bgColor]="'text-primary'"
    ></app-loading-placeholder>
  </div>
</div>

<!-- edit scan type -->
<ng-template #uploadAttachmentForm>
  <app-modal
    [size]="'medium'"
    [loading]="uploading"
    [modalTitle]="'Upload Attachments'"
    [modalActionButton]="updateAttachmentBtn"
  >
    <form action="" [formGroup]="attachmentForm">
      <app-input-uploader
        [multiple]="true"
        [control]="customFormControl('files')"
        [fieldId]="'studyAttachments'"
      >
      </app-input-uploader>
    </form>
  </app-modal>
</ng-template>
<!-- edit scan type -->

<!-- open Attachment Modal -->
<ng-template #studyAttachmentModal>
  <app-modal
    [size]="'xlong'"
    [isModalBtn]="false"
    [modalTitle]="'Study Attachment'"
  >
    <div class="image-file" *ngIf="viewableAttachment.type !== 'pdf'">
      <img
        class="img-fluid"
        alt="Responsive image"
        *ngIf="viewableAttachment"
        [src]="
          assetsRoot + viewableAttachment.url + '/' + viewableAttachment.mime
        "
        alt=""
      />
    </div>

    <div class="pdf-file" *ngIf="viewableAttachment.type === 'pdf'">
      <ngx-extended-pdf-viewer
        [src]="
          assetsRoot + viewableAttachment.url + '/' + viewableAttachment.mime
        "
        height="80vh"
      >
      </ngx-extended-pdf-viewer>
    </div>
  </app-modal>
</ng-template>
<!-- open Attachment Modal -->
