import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { applyMixins } from '../../mixin/mixin';
import { BaseFormComponent } from '../base-form/base-form.component';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { BehaviorSubject, Observable } from 'rxjs';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
    selector: 'app-input-uploader',
    templateUrl: './input-uploader.component.html',
    styleUrls: ['./input-uploader.component.scss']
})
export class InputUploaderComponent extends BaseClass implements OnInit, DoCheck {

    @Input() fieldId!: string | null;
    @Input() control!: FormControl;

    @Input() fieldType = '';
    @Input() required = false;
    @Input() multiple = false;

    filesControl = new FileUploadControl();
    uploadedFiles: BehaviorSubject<any> = new BehaviorSubject(null);
    fileObservable: Observable<any> = this.uploadedFiles.asObservable();
    dataArray: Array<any> = [];

    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.filesControl.valueChanges.forEach((val: Array<File>) => {
            this.dataArray = [];

            val.forEach((file: File) => {
                this.convertToBlob(file);
            });
        });

        this.fileObservable.forEach((val: any) => {
            if (val !== null) {
                this.dataArray.push(val);
                this.control?.setValue(this.dataArray);
            } else {
                this.control?.reset();
                this.dataArray = [];
            }
        });
    }

    ngDoCheck(): void {
        super.ngDoCheck();
    }

    clear(): void {
        this.filesControl.clear();
    }

    convertToBlob(file: File): void {
        if (FileReader && file) {
            const fileReader = new FileReader();
            fileReader.onload = (e: any) => this.uploadedFiles.next({
                data: e.target.result.split(',')[1],
                name: file.name,
                mime: file.type,
                fileType: file.type.split('/')[1]
            });
            fileReader.readAsDataURL(file);
        }
    }
}
