<div class="app">
  <!-- navigation -->
  <header class="app-header fixed-top">
    <app-admin-header></app-admin-header>

    <app-admin-sidebar></app-admin-sidebar>
  </header>
  <!-- navigation -->

  <!-- offcanvas -->
  <app-offcanvas></app-offcanvas>
  <!-- offcanvas -->

  <!-- main content -->
  <div class="app-wrapper">
    <div class="app-content pt-3 p-md-3 p-lg-4">
      <div class="container-xl">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <!-- main content -->
</div>

<!-- alert -->
<app-alert></app-alert>
