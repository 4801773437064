<!-- study report -->
<div class="row mx-0 mt-3">
  <div [hidden]="report === null && studyDetails?.status !== 'REPORTED'" class="col-lg-12 px-0">
    <div class="h-100 group">
      <label for="" class="group-label">Signed Report</label>
      <button class="print-btn" (click)="printFunc()">
        <em *ngIf="!downloading" class="bi bi-printer"></em>
        <div *ngIf="downloading" class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </button>
      <div class="w-100 d-block" id="render-signed-report" #pdfReport=""></div>
      <div hidden *ngIf="studyReportForm.value">
        <app-input-editor (printEvent)="onRenderEdit($event)" [editorHeight]="800" [control]="studyReportForm"
          [fieldId]="'report'">
        </app-input-editor>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="report === null && studyDetails?.status !== 'REPORTED' && !getting">
    <h4 class="text-center text-muted">There is no report Yet!</h4>
  </div>
</div>

<div class="row mx-0" *ngIf="getting">
  <div class="col-12">
    <app-loading-placeholder [bgColor]="'text-primary'"></app-loading-placeholder>
  </div>
</div>
<!-- study report -->